import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useState, useEffect } from 'react';

function PMT(){

	const [lang, setLang] = useState();	

	useEffect(()  => {
		const checkLang = function() {
			if(localStorage.getItem('lang')) {
				setLang(JSON.parse(localStorage.getItem('lang')));
			} else {
				setLang(0);
			}
		}

		window.addEventListener('storage', checkLang)

		return () => {
			window.removeEventListener('storage', checkLang)
		}
  }, []);

  useEffect(()  => {		
		if(localStorage.getItem('lang')) {
			setLang(JSON.parse(localStorage.getItem('lang')));
		} else {
			setLang(0);
		}
    window.dispatchEvent(new Event("openModal"));	
    window.dispatchEvent(new Event("newRoute"));
    window.dispatchEvent(new Event("resize"));
  }, []);

  const [curso] = useState('Professional Master in Tasting - PMT');
  const [nome, setNome] = useState('');
  const [nomeCracha, setNomeCracha] = useState('');
  const [endereco, setEndereco] = useState('');
  const [numero, setNumero] = useState('');
  const [bairro, setBairro] = useState('');
  const [cep, setCep] = useState('');
  const [cidadeEstado, setCidadeEstado] = useState('');
  const [telefone, setTelefone] = useState('');
  const [email, setEmail] = useState('');
  const [telefone2, setTelefone2] = useState('');
  const [rg, setRg] = useState('');
  const [cpf, setCpf] = useState('');
  const [dataNascimento, setDataNascimento] = useState('');
  const [escolaridade, setEscolaridade] = useState('');
  const [razaoSocial, setRazaoSocial] = useState('');
  const [enderecoCnpj, setEnderecoCnpj] = useState('');
  const [numeroCnpj, setNumeroCnpj] = useState('');
  const [bairroCnpj, setBairroCnpj] = useState('');
  const [cidadeEstadoCnpj, setCidadeEstadoCnpj] = useState('');
  const [telefoneCnpj, setTelefoneCnpj] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [inscricaoEstadual, setInscricaoEstadual] = useState('');
  const [formaPagamento, setFormaPagamento] = useState('');
  const [responsavelPagamento, setResponsavelPagamento] = useState('');

  const handleTel1Input = (v) => {
    v = maskTel(v)
    setTelefone(v)
  }

  const handleTel2Input = (v) => {
    v = maskTel(v)
    setTelefone2(v)
  }

  const handleTelCnpjInput = (v) => {
    v = maskTel(v)
    setTelefoneCnpj(v)
  }

  const maskTel = (v) => {
    v = v.replace(/\D/g, "")

    v = v.replace(/(\d{2})(\d)/,"($1) $2")
    v = v.replace(/(\d)(\d{4})$/,"$1-$2")

    return v
  }

  const maskData = (v) => {
    v = v.replace(/\D/g, "")

    v = v.replace(/(\d{2})(\d)/,"$1/$2") 
    v = v.replace(/(\d{2})(\d)/,"$1/$2") 

    setDataNascimento(v)
  }

  const maskCep = (v) => {
    v = v.replace(/\D/g, "")

    v = v.replace(/\D/g,'')
    v = v.replace(/(\d{5})(\d)/,'$1-$2')

    setCep(v)

  }

  const maskCpf = (v) => {
    v = v.replace(/\D/g, "")
  
    v = v.replace(/(\d{3})(\d)/, "$1.$2")
    v = v.replace(/(\d{3})(\d)/, "$1.$2")
    v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2")

    setCpf(v)

  }

  const maskCnpj = (v) => {
    v = v.replace(/\D/g, "")

    v = v.replace(/^(\d{2})(\d)/, "$1.$2")
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
    v = v.replace(/\.(\d{3})(\d)/, ".$1/$2")
    v = v.replace(/(\d{4})(\d)/, "$1-$2")
    
    setCnpj(v)

  }

  const sendEmail = async (e) => {
    e.preventDefault();

    if(curso === '' || nome === '' || nomeCracha === '' || endereco === '' || numero === '' || bairro === '' || 
    cep === '' || cidadeEstado === '' || telefone === '' || email === '' || rg === '' || cpf === '' || 
    dataNascimento === '' || escolaridade === '' || formaPagamento === '' || responsavelPagamento === ''){
      const alertMessage = lang ? "Please, fill in all fields with an asterisk" : "Por favor, preencha todos os campos com asterisco";
      alert(alertMessage);
      return;
    }

    var destinatario = "cursos@savassiagronegocio.com.br,comercial@savassicertificadora.com.br,fonteboag@savassicertificadora.com.br,dantasr@savassicertificadora.com.br";

    const templateParams = {
      destinatario: destinatario,
      origemMensagem: "Novo pedido de matrícula no curso Professional Master in Tasting - PMT",
      curso: curso,
      nome: nome,
      nomeCracha: nomeCracha,
      endereco: endereco,
      numero: numero,
      bairro: bairro,
      cep: cep,
      cidadeEstado: cidadeEstado,
      telefone: telefone,
      email: email,
      telefone2: telefone2,
      rg: rg,
      cpf: cpf,
      dataNascimento: dataNascimento,
      escolaridade: escolaridade,
      razaoSocial: razaoSocial,
      enderecoCnpj: enderecoCnpj,
      numeroCnpj: numeroCnpj,
      bairroCnpj: bairroCnpj,
      cidadeEstadoCnpj: cidadeEstadoCnpj,
      telefoneCnpj: telefoneCnpj,
      cnpj: cnpj,
      inscricaoEstadual: inscricaoEstadual,
      valor: 'R$2.999,00',
      formaPagamento: formaPagamento,
      responsavelPagamento: responsavelPagamento
    }

    window.dispatchEvent(new Event("closeModal"));
    setNome('');
    setNomeCracha('');
    setEndereco('');
    setNumero('');
    setBairro('');
    setCep('');
    setCidadeEstado('');
    setTelefone('');
    setEmail('');
    setTelefone2('');
    setRg('');
    setCpf('');
    setDataNascimento('');
    setEscolaridade('');
    setRazaoSocial('');
    setEnderecoCnpj('');
    setNumeroCnpj('');
    setBairroCnpj('');
    setCidadeEstadoCnpj('');
    setTelefoneCnpj('');
    setCnpj('');
    setInscricaoEstadual('');
    setFormaPagamento('');
    setResponsavelPagamento('');

    let response = await fetch("/cursos", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8"
      },
      body: JSON.stringify(templateParams)
    })

    let result = await response.json();

    if(result.status==="200 OK") {
      console.log("EMAIL ENVIADO", response.status, response.text);
      alert(lang ? 'Enrollment sent successfully!' : 'Matrícula enviada com sucesso!')
    } else {
      console.log("ERRO: ", result.status);
    }

  }

  return(
    <div style={{backgroundColor:'#F7FBF5'}}>
      <div className="capacitacaosubpages">
        <img className="capacitacaosubpagesbanner" src={require("../../../../assets/images/banner-PMT.png")} alt="banner"/><br/><br/>
        <h2>{ lang ? "The Course" : "O Curso" }</h2>
        <h3>{ lang ? "Objectives" : "Objetivos" }</h3>
        <p>
          <li>
            {
              lang ?  
              "Train professionals at the advanced level of sensory analysis of specialty coffees;" : 
              "Capacitar Profissionais no nível avançado de análise sensorial de cafés especiais;"
            }
          </li>
          <li>
            {
              lang ?  
              "Prepare professionals for international certification courses in sensory analysis;" : 
              "Preparar profissionais para cursos de certificação internacional na análise sensorial;"
            }
          </li>
          <li>
            {
              lang ?  
              "It's a course to train and improve technical experts in coffee tasting. Technicians will be qualified and trained to ensure quality control in the production, handling, trading of the bean coffee and identification of unique characteristics;" : 
              "É um curso para formar e aprimorar técnicos especialistas em degustação de café. Os técnicos serão capacitados e treinados para garantir o controle de qualidade na produção, manipulação, comercialização da matéria prima e identificação de características únicas;"
            }
          </li>
          <li>
            {
              lang ?  
              "Courses will be given on advanced coffee tasting, as well as notions of taste and smell and preparation of samples;" : 
              "Serão ministradas disciplinas sobre degustação de café avançado, bem como noções de paladar e olfato e preparo de amostras;"
            }
          </li>
          <li>
            {
              lang ?  
              "The course is essentially practical, with technical activities and daily tasting practices." : 
              "O curso é essencialmente prático, com atividades técnicas e práticas diárias de degustação."
            }
          </li>
        </p><br/>
        <h3>{ lang ? "Specific Objectives" : "Objetivos Específicos" }</h3>
        <p>
          {
            lang ?  
            "Enable a broad identification and interpretation of the essential and basic concepts and principles of advanced coffee sensory analysis." : 
            "Possibilitar uma ampla identificação e interpretação dos conceitos e princípios essenciais e básicos da análise sensorial avançada de Café."
          }
        </p><br/><br/>
        <div className="capacitacaodetalhes">
          <h2>{ lang ? "Investiment" : "Investimento" }</h2>
          <h2 id="money">{ lang ? "R$ 2.999,00" : "R$ 2.999,00" }</h2><br/>
          <p><b>
            { 
              lang ? 
              "Course price: R$ 2.999,00 (The dollar value varies according to the exchange rate of the day)" : 
              "Preço do curso: R$ 2.999,00"
            }<br/><br/>
            { 
              lang ? 
              "Cash via PIX or Cash: R$ 2.850,00" : 
              "À vista via PIX ou Dinheiro: R$ 2.850,00"
            }<br/><br/>
            { 
              lang ? 
              "Installments by Credit Card in up to 3 installments: R$ 2.999,00" : 
              "Parcelado no Cartão de Crédito em até 3x vezes: R$ 2.999,00"
            }<br/><br/>
          </b></p>
          <button id="myBtn">{ lang ? "Enroll" : "Matricular" }</button><br/><br/>
        </div><br/>

        <div className="capacitacaosubpages-instrutores">
          <h2>{ lang ? "Instructors" : "Instrutores" }</h2>
          <div className="owl-carousel">
            <div className="carousel-item">
              <img id="foto1" className="direcaofoto" src={require("../../../../assets/images/direcao1.png")} alt="Ronaldo Dantas"/>
            </div>
            <div className="carousel-item">
              <img id="foto3" className="direcaofoto" src={require("../../../../assets/images/direcao3.png")} alt="Glaucio Lima Fonte Boa"/>
            </div>
          </div>
        </div><br/><br/><br/>

        <div className="capacitacaosubpages-instrutores">
          <h2>{ lang ? "Technical Assistants" : "Assistentes Técnicos" }</h2>
          <div className="owl-carousel">
            <div className="carousel-item">
              <img id="foto4" className="direcaofoto" src={require("../../../../assets/images/nivaldo.png")} alt="Nivaldo"/>
            </div>
            <div className="carousel-item">
              <img id="foto5" className="direcaofoto" src={require("../../../../assets/images/luis.png")} alt="Luis"/>
            </div>
          </div>
        </div><br/><br/>

        <div id="myModal" className="modal">
          <div className="modal-content">
            <div className="modal-header">
              <span className="close">&times;</span>
              <h2>{ lang ? "Enroll now!" : "Matricule-se agora!" }</h2>
            </div>
            <div className="modal-body">
              <form onSubmit={sendEmail}>
                <span><strong>{ lang ? "CONTRACTOR'S Personal Data" : "Dados Pessoais do CONTRATANTE" }</strong></span>
                <input type="text" name="curso" disabled value={curso}></input> <br/>
                <input type="text" name="nome" onChange={(e) => setNome(e.target.value)} value={nome} placeholder={ lang ? "Full name*" : "Nome completo*" }></input> <br/>
                <input type="text" name="nomeCracha" onChange={(e) => setNomeCracha(e.target.value)} value={nomeCracha} placeholder={ lang ? "Name highlighted on badge*" : "Nome em destaque no crachá*" }></input> <br/>
                <input type="text" name="endereco" onChange={(e) => setEndereco(e.target.value)} value={endereco} placeholder={ lang ? "Address*" : "Endereço*" }></input> <br/>
                <input type="text" name="numero" onChange={(e) => setNumero(e.target.value)} value={numero} placeholder={ lang ? "Address number*" : "Número*" }></input> <br/>
                <input type="text" name="bairro" onChange={(e) => setBairro(e.target.value)} value={bairro} placeholder={ lang ? "Neighbourhood*" : "Bairro*" }></input> <br/>
                <input type="text" name="cep" onChange={(e) => maskCep(e.target.value)} value={cep} placeholder={ lang ? "Postal Code*" : "CEP*" }></input> <br/>
                <input type="text" name="cidadeEstado" onChange={(e) => setCidadeEstado(e.target.value)} value={cidadeEstado} placeholder={ lang ? "City and State*" : "Cidade e Estado*" }></input> <br/>
                <input type="tel" name="telefone" onChange={(e) => handleTel1Input(e.target.value)} value={telefone} placeholder={ lang ? "Phone number*" : "Telefone*" }></input> <br/>
                <input type="email" name="email" onChange={(e) => setEmail(e.target.value)} value={email} placeholder={ lang ? "E-mail*" : "E-mail*" }></input> <br/>
                <input type="tel" name="telefone2" onChange={(e) => handleTel2Input(e.target.value)} value={telefone2} placeholder={ lang ? "Phone number of a relative or someone close" : "Telefone de um parente ou alguém próximo" }></input> <br/>
                <input type="text" name="rg" onChange={(e) => setRg(e.target.value)} value={rg} placeholder={ lang ? "RG*" : "RG*" }></input> <br/>
                <input type="text" name="cpf" onChange={(e) => maskCpf(e.target.value)} value={cpf} placeholder={ lang ? "CPF*" : "CPF*" }></input> <br/>
                <input type="text" name="dataNascimento" onChange={(e) => maskData(e.target.value)} value={dataNascimento} placeholder={ lang ? "Birth Date*" : "Data de Nascimento*" }></input> <br/>
                <input type="text" name="escolaridade" onChange={(e) => setEscolaridade(e.target.value)} value={escolaridade} placeholder={ lang ? "Education*" : "Escolaridade*" }></input> <br/>
                <span><strong>{ lang ? "Company Data - for LEGAL ENTITY" : "Dados da Empresa - para PESSOA JURÍDICA" }</strong></span>
                <input type="text" name="razaoSocial" onChange={(e) => setRazaoSocial(e.target.value)} value={razaoSocial} placeholder={ lang ? "Corporate name" : "Razão Social" }></input> <br/>
                <input type="text" name="enderecoCnpj" onChange={(e) => setEnderecoCnpj(e.target.value)} value={enderecoCnpj} placeholder={ lang ? "Address" : "Endereço" }></input> <br/>
                <input type="text" name="numeroCnpj" onChange={(e) => setNumeroCnpj(e.target.value)} value={numeroCnpj} placeholder={ lang ? "Address number" : "Número" }></input> <br/>
                <input type="text" name="bairroCnpj" onChange={(e) => setBairroCnpj(e.target.value)} value={bairroCnpj} placeholder={ lang ? "Neighbourhood" : "Bairro" }></input> <br/>
                <input type="text" name="cidadeEstadoCnpj" onChange={(e) => setCidadeEstadoCnpj(e.target.value)} value={cidadeEstadoCnpj} placeholder={ lang ? "City and State" : "Cidade e Estado" }></input> <br/>
                <input type="tel" name="telefoneCnpj" onChange={(e) => handleTelCnpjInput(e.target.value)} value={telefoneCnpj} placeholder={ lang ? "Phone number" : "Telefone" }></input> <br/>
                <input type="text" name="cnpj" onChange={(e) => maskCnpj(e.target.value)} value={cnpj} placeholder={ lang ? "CNPJ" : "CNPJ" }></input> <br/>
                <input type="text" name="inscricaoEstadual" onChange={(e) => setInscricaoEstadual(e.target.value)} value={inscricaoEstadual} placeholder={ lang ? "State registration" : "Inscrição Estadual" }></input> <br/>
                <span><strong>{ lang ? "Payment" : "Pagamento" }</strong></span>
                <input type="text" name="valor" disabled value={lang ? 'US$602,00' : 'R$2.999,00'}></input> <br/>
                <input type="text" name="formaPagamento" onChange={(e) => setFormaPagamento(e.target.value)} value={formaPagamento} placeholder={ lang ? "Payment Method*" : "Forma de Pagamento*" }></input> <br/>
                <input type="text" name="responsavelPagamento" onChange={(e) => setResponsavelPagamento(e.target.value)} value={responsavelPagamento} placeholder={ lang ? "Responsible for payment*" : "Responsável pelo pagamento*" }></input> <br/>
                <input id="modalBtn" type="submit" name="enviar" value={ lang ? "Send" : "Enviar" }></input> <br/>
              </form>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>

        <Tabs className="capacitacaotabs">
          <TabList>
            <Tab>{ lang ? "Methodology" : "Metodologia" }</Tab>
            <Tab>{ lang ? "Activities" : "Atividades" }</Tab>
            <Tab>{ lang ? "Target Audience" : "Público-Alvo" }</Tab>
            <Tab>{ lang ? "Workload" : "Carga-Horária" }</Tab>
            <Tab>{ lang ? "Where to Stay" : "Onde Hospedar" }</Tab>
          </TabList>

          <TabPanel className="capacitacaotabpanel">
            <br/>
            <span>{ lang ? "Students will receive classes and guidance on:" : "Os alunos receberão aulas e orientações sobre:" }</span>
            <p>
              <li>
                { lang ? "Sensory Skill;" : "Habilidade Sensorial;" }
              </li>
              <li>
                { lang ? "Tasting Skill;" : "Habilidade de Degustação;" }
              </li>
              <li>
                { lang ? "Triangulation Skill;" : "Habilidade de Triangulação;" }
              </li>
              <li>
                { lang ? "Acid Identification Skill;" : "Habilidade de Identificação de Ácidos;" }
              </li>
              <li>
                { lang ? "Skill to Grading Green Coffee;" : "Habilidade em Classificação de Café Verde;" }
              </li>
              <li>
                { lang ? "Skill in Roasted Coffee Grading;" : "Habilidade em Classificação de Café Torrado;" }
              </li>
              <li>
                { lang ? "Skill in General knowledge about coffee." : "Habilidade em Conhecimentos Gerais." }
              </li>
            </p><br/>
          </TabPanel>
          <TabPanel className="capacitacaotabpanel">
            <br/>
            <h3>{ lang ? "Sensory Skill:" : "Habilidade Sensorial:" }</h3>
            <p style={{textAlign:'left'}}>
              {
                lang ? 
                "The purpose of this module is to provide the identification of the basic flavors and their intensities in three different solutions, they are: salty, sweet and acid or sour taste in three different levels of intensity. The students will be trained in the identification of two parts: part 1, the students will identify the intensities of the flavors and, part 2 they will identify the intensities of the blends of the flavors." : 
                "O objetivo deste módulo é proporcionar a identificação dos sabores básicos e suas intensidades em três soluções diferentes, são eles: o gosto salgado, doce e ácido ou azedo em três diferentes níveis de intensidade. Os alunos serão treinados na identificação de duas partes: na parte 1 os alunos identificarão as intensidades dos sabores e na parte 2 identificarão as intensidades das misturas dos sabores."
              }
            </p><br/>
            <h3>{ lang ? "Tasting Skills:" : "Habilidade de Degustação:" }</h3>
            <p style={{textAlign:'left'}}>
              { 
                lang ? 
                "The objective of this module is to provide the interpretation of international tasting protocols through the sensorial analyzes of coffees from different producing regions and their origins; The students will be trained in the identification of the evaluated characteristics of the coffee and its determination in punctuation." : 
                "O objetivo deste módulo é proporcionar a interpretação de protocolos de degustação internacionais através das análises sensoriais de cafés de diferentes regiões produtoras e suas origens; Os alunos serão treinados na identificação das característica avaliadas do café e sua determinação em pontuação."
              }<br/><br/>
            </p>
            <h3>{ lang ? "Triangulation Skill:" : "Habilidade de Triangulação:" }</h3>
            <p style={{textAlign:'left'}}>
              { 
                lang ? 
                "The purpose of this module is to provide the identification of different beverages in three sets presented from different sources." : 
                "O objetivo deste módulo é proporcionar a identificação de diferentes bebidas em três conjuntos apresentados de origens diferentes."
              }<br/><br/>
            </p>
            <h3>{ lang ? "Acid Identification Skill:" : "Habilidade de Identificação de Ácidos:" }</h3>
            <p style={{textAlign:'left'}}>
              { 
                lang ? 
                "The purpose of this module is to provide the identification of different organics acids present in the coffee and its intensities." : 
                "O objetivo deste módulo é proporcionar a identificação de diferentes ácidos presentes no café e suas intensidades."
              }<br/><br/>
            </p>
            <h3>{ lang ? "Skill to Grading Green Coffee:" : "Habilidade em Classificação de Café verde:" }</h3>
            <p style={{textAlign:'left'}}>
              { 
                lang ? 
                "The purpose of this module is to provide interpretation and identification of the classification of international protocols for green coffee." : 
                "O objetivo deste módulo é proporcionar a interpretação e identificação da classificação de protocolos internacionais de café verde."
              }<br/><br/>
            </p>
            <h3>{ lang ? "Skill in Roasted Coffee Grading:" : "Habilidade em Classificação de Café Torrado:" }</h3>
            <p style={{textAlign:'left'}}>
              { 
                lang ? 
                "The purpose of this module is to provide interpretation and identification of the classification of international protocols for roasted coffee." : 
                "O objetivo deste módulo é proporcionar a interpretação e identificação da classificação de protocolos internacionais de café torrado."
              }<br/><br/>
            </p>
            <h3>{ lang ? "Skill in General knowledge about coffee:" : "Habilidade em Conhecimentos Gerais:" }</h3>
            <p style={{textAlign:'left'}}>
              { 
                lang ? 
                "The objective of this module is to provide a wide dissemination of information regarding the agribusiness coffee at national and international level." : 
                "O objetivo deste módulo é proporcionar a uma ampla divulgação de informação a respeito do agronegócio de café a nível nacional e internacional."
              }<br/><br/>
            </p>
          </TabPanel>
          <TabPanel className="capacitacaotabpanel">
            <br/>
            <p>
              <li>
                { lang ? "Agricultural cooperatives" : "Cooperativas agrícolas" }
              </li>
              <li>
                { lang ? "General stores" : "Armazéns gerais" }
              </li>
              <li>
                { lang ? "Agricultural technicians" : "Técnicos agrícolas" }
              </li>
              <li>
                { lang ? "Coffee growers" : "Cafeicultores" }
              </li>
              <li>
                { lang ? "Professionals in the agricultural sector" : "Profissionais do setor agrícola" }
              </li>
              <li>
                { lang ? "Students" : "Estudantes" }
              </li>
              <li>
                { lang ? "Hospitality" : "Hotelaria" }
              </li>
              <li>
                { lang ? "Coffee shops" : "Cafeterias" }
              </li>
              <li>
                { lang ? "Supermarket sector" : "Setor supermercadista" }
              </li>
              <li>
                { lang ? "Big consumers" : "Grandes consumidores" }
              </li>
            </p><br/>
          </TabPanel>
          <TabPanel className="capacitacaotabpanel">
            <br/>
            <span>
              { 
                lang ? 
                "The course has a total of 40 class hours (from 8 am to 5:30 pm, lasting one week). Students will receive all teaching material, and, if approved (minimum score of 70%), they will receive professional registration and a certificate of completion of the Savassi Agronegócio course." : 
                "O curso tem um total de 40 horas aula (de 8h às 17h30, com duração de uma semana). Os Alunos receberão todo o material didático, e, em caso de aprovação (aproveitamento mínimo de 70%), receberão registro de profissional e certificado de conclusão do curso da Savassi Agronegócio." 
              }<br/>
              { 
                lang ? 
                "The Course will be taught by professionals qualified in the international Q-GRADER certification." : 
                "O Curso será lecionado por profissionais habilitados na certificação internacional Q-GRADER." 
              }
            </span>
            <br/><br/>
          </TabPanel>
          <TabPanel className="capacitacaotabpanel">
            <br/>
            <h4>{ lang ? "Partner Hotels:" : "Hotéis Parceiros:" }</h4>
            <Link to="https://www.ourocerradohotel.com.br/" target="_blank">Ouro Cerrado Hotel</Link><br/>
            <Link to="http://www.minashotel.com.br/" target="_blank">Minas Hotel</Link><br/>
            <span>Center Hotel</span><br/>
            <span>Guines Hotel</span><br/>
            <span>Grande Avenida</span><br/><br/>
            <h4>{ lang ? "Other Hotels:" : "Outros Hotéis:" }</h4>
            <Link to="http://bit.ly/2sISAXG" target="_blank">{ lang ? "Patrocínio hotels in Trivago" : "Hotéis de Patrocínio em Trivago" }</Link><br/>
            <br/>
          </TabPanel>
        </Tabs><br/>
      </div>

        <div id="myModal1" className="modalquemsomos">
          <div className="modalquemsomos-content">
            <div className="modalquemsomos-header">
              <span className="close">&times;</span>
            </div>
            <div className="modalquemsomos-body">
              <img src={require("../../../../assets/images/direcao1.png")} alt="Glaucio Lima Fonte Boa"/>
              <p><b>{ lang ? "Name: " : "Nome: " }</b>Ronaldo Dantas</p>
              <p>
                <b>{ lang ? "Role: " : "Cargo: " }</b>
                {
                  lang ? 
                  "Executive Director, Lead Auditor, Q-Grader, Course Instructor (Coffee Classification and Tasting and PMT), Financial Manager and Roasting Master" : 
                  "Diretor Executivo, Auditor Líder, Q-Grader, Instrutor de cursos (Classificação e Degustação de Café e PMT), Gestor Financeiro e Mestre de Torra"
                }
              </p>
              <p>
                <b>{ lang ? "Description:" : "Descrição:" }</b>
                <li>{ lang ? "Born in Loanda/PR" : "Natural de Loanda/PR" }</li>
                <li>{ lang ? "Agribusiness Technologist (Instituto de Ensino Superior de Patrocínio - IESP)" : "Tecnólogo do Agronegócio (Instituto de Ensino Superior de Patrocínio - IESP)" }</li>
                <li>{ lang ? "Computer Technician" : "Técnico em Informática" }</li>
                <li>{ lang ? "Fluency in English" : "Fluência em inglês" }</li>
              </p>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
        
        <div id="myModal3" className="modalquemsomos">
          <div className="modalquemsomos-content">
            <div className="modalquemsomos-header">
              <span className="close">&times;</span>
            </div>
            <div className="modalquemsomos-body">
              <img src={require("../../../../assets/images/direcao3.png")} alt="Glaucio Lima Fonte Boa"/>
              <p><b>{ lang ? "Name: " : "Nome: " }</b>Glaucio Lima Fonte Boa</p>
              <p>
                <b>{ lang ? "Role: " : "Cargo: " }</b>
                {
                  lang ? 
                  "Senior Executive, Lead Auditor, Q-Grader, Course Instructor (Coffee Classification and Tasting, Coffee Marketing and PMT), Field Auditor, Works in the UTZ, 4C, Strarbucks C.A.F.E. Practices, IN29/MAPA" : 
                  "Executivo Sênior, Auditor Líder, Q-Grader, Instrutor de cursos (Classificação e Degustação de Café, Comercialização de Café e PMT), Auditor de Campo, Atua nos programas UTZ, 4C, Strarbucks C.A.F.E. Practices, IN29/MAPA"
                }
              </p>
              <p>
                <b>{ lang ? "Description:" : "Descrição: " }</b>
                <li>{ lang ? "Born in Guarapari/ES" : "Natural de Guarapari/ES" }</li>
                <li>{ lang ? "Agricultural Engineer (Universidade Federal do Espirito Santo - UFES)" : "Engenheiro Agrônomo (Universidade Federal do Espirito Santo - UFES)" }</li>
                <li>{ lang ? "MBA in Strategic Agribusiness Management (Fundação Getúlio Vargas - FGV)" : "MBA em Gestão Estratégica do Agronegócio (Fundação Getúlio Vargas - FGV)" }</li>
                <li>{ lang ? "Fluency in English" : "Fluência em inglês" }</li>
                <li>Q-Grader</li>
              </p>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>

        <div id="myModal4" className="modalquemsomos">
          <div className="modalquemsomos-content">
            <div className="modalquemsomos-header">
              <span className="close">&times;</span>
            </div>
            <div className="modalquemsomos-body">
              <img src={require("../../../../assets/images/nivaldo.png")} alt="Nivaldo Henrique das Neves Caixeta"/>
              <p><b>{ lang ? "Name: " : "Nome: " }</b>Nivaldo Henrique das Neves Caixeta</p>
              <p>
                <b>{ lang ? "Role: " : "Cargo: " }</b>
                {
                  lang ? 
                  "Commercial and Courses Coordinator" : 
                  "Coordenador Comercial e Cursos"
                }
              </p>
              <p>
                <b>{ lang ? "Description:" : "Descrição:" }</b>
                <li>{ lang ? "Born in Patrocínio/MG" : "Natural de Patrocínio/MG" }</li>
                <li>{ lang ? "Environmental Manager (Unicesumar University)" : "Gestor Ambiental (Universidade Unicesumar)" }</li>
                <li>{ lang ? "Postgraduate in Digital Marketing (Unicesumar University)" : "Pós em Marketing Digital (Universidade Unicesumar)" }</li>
              </p>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
        
        <div id="myModal5" className="modalquemsomos">
          <div className="modalquemsomos-content">
            <div className="modalquemsomos-header">
              <span className="close">&times;</span>
            </div>
            <div className="modalquemsomos-body">
              <img src={require("../../../../assets/images/luis.png")} alt="Luís Carlos dos Anjos"/>
              <p><b>{ lang ? "Name: " : "Nome: " }</b>Luís Carlos dos Anjos</p>
              <p>
                <b>{ lang ? "Role: " : "Cargo: " }</b>{ lang ? "Classifier" : "Classificador" }
              </p>
              <p>
                <b>{ lang ? "Description:" : "Descrição: " }</b>
                <li>{ lang ? "Born in Patrocínio/MG" : "Natural de Patrocínio/MG" }</li>
              </p>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>

    </div>
  )
}

export default PMT;