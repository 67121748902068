import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function DocumentosOrganicoBrasil(){

	const [lang, setLang] = useState();

	useEffect(()  => {
		const checkLang = function() {
			if(localStorage.getItem('lang')) {
				setLang(JSON.parse(localStorage.getItem('lang')));
			} else {
				setLang(0);
			}
		}

		window.addEventListener('storage', checkLang)

		return () => {
			window.removeEventListener('storage', checkLang)
		}
  }, []);

  useEffect(()  => {		
		if(localStorage.getItem('lang')) {
			setLang(JSON.parse(localStorage.getItem('lang')));
		} else {
			setLang(0);
		}	
    window.dispatchEvent(new Event("newRoute"));	
  }, []);

  return(
    <div style={{backgroundColor:'#F7FBF5'}}>
      <div className="documentossubpages">
        <div>
        <Link className="fa fa-chevron-left fa-2x" to="/documentos" state={{from: 0}}/><br/>
        <h1>{ lang ? "Organic Brazil" : "Orgânico Brasil" }</h1><br/>
        </div>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "DECREE No. 6,323, OF DECEMBER 27, 2007 - Regulates Law No. 10,831, of December 23, 2003, which provides for organic agriculture" : "DECRETO Nº 6.323, DE 27 DE DEZEMBRO DE 2007 - Regulamenta a Lei nº 10.831, de 23 de dezembro de 2003, que dispõe sobre a agricultura orgânica" }</b><br/>
          <Link to="../../../../assets/documents/DECRETO_N_6323-ALT_DECRETO_N_7794-2012.pdf" download="DECRETO_N_6323-ALT_DECRETO_N_7794-2012.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "DECREE No. 6,913 OF JULY 23, 2009 - Adds provisions to Decree No. 4,074, January 4, 2002, which regulates. Law No. 7,802, July 11, 1989, provides for research, production." : "DECRETO Nº 6.913 DE 23 DE JULHO DE 2009 - Acresce dispositivos Decreto Nº 4.074, 4 de janeiro de 2002, que regul. Lei Nº 7.802, 11 de julho de 1989, dispõe sobre a pesquisa, prod." }</b><br/>
          <Link to="../../../../assets/documents/DECRETO_N_6913.pdf" download="DECRETO_N_6913.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "DECREE No. 7,048 OF DECEMBER 23, 2009 - New wording of art. 115 of Decree No. 6,323, December 27, 2007, regulation. Law No. 10,831, December 23, 2003, provides for organic agriculture" : "DECRETO Nº 7.048 DE 23 DE DEZEMBRO DE 2009 - Dá nova redação art. 115 do Decreto Nº 6.323, 27 de dezembro de 2007, regul. a Lei Nº 10.831, 23 de dezembro de 2003, dispõe sobre agricultura orgânica" }</b><br/>
          <Link to="../../../../assets/documents/DECRETO_N_7048-ALT_RED_ART_115_DEC_N_6323.pdf" download="DECRETO_N_7048-ALT_RED_ART_115_DEC_N_6323.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "Joint Normative Instruction No. 17/2009" : "Instrução Normativa Conjunta n° 17/2009" }</b><br/>
          <Link to="../../../../assets/documents/INSTRUÇÃO_NORMATIVA_CONJUNTA_N_17_2009.pdf" download="INSTRUÇÃO_NORMATIVA_CONJUNTA_N_17_2009.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "Joint Normative Instruction No. 18/2009 amended by IN 24/2011" : "Instrução Normativa Conjunta n° 18/2009 alterada pela IN 24/2011" }</b><br/>
          <Link to="../../../../assets/documents/IN_18_DE_2008.pdf" download="IN_18_DE_2008.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "Normative Instruction No. 13/2015" : "Instrução Normativa n° 13/2015" }</b><br/>
          <Link to="../../../../assets/documents/INSTRUÇÃO_NORMATIVA_N_13_2015.pdf" download="INSTRUÇÃO_NORMATIVA_N_13_2015.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "Normative Instruction No. 18/2014" : "Instrução Normativa n° 18/2014" }</b><br/>
          <Link to="../../../../assets/documents/in_18_2014.pdf" download="in_18_2014.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "Normative Instruction No. 19/2009" : "Instrução Normativa n° 19/2009" }</b><br/>
          <Link to="../../../../assets/documents/INSTRUÇÃO_NORMATIVA_N_19_2009.pdf" download="INSTRUÇÃO_NORMATIVA_N_19_2009.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "Normative Instruction No. 37/2011" : "Instrução Normativa n° 37/2011" }</b><br/>
          <Link to="../../../../assets/documents/INSTRUÇÃO_NORMATIVA_N_37_2011.pdf" download="INSTRUÇÃO_NORMATIVA_N_37_2011.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "Law No. 10,831/2003" : "Lei nº 10.831/2003" }</b><br/>
          <Link to="../../../../assets/documents/lei_10831.pdf" download="lei_10831.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "Law No. 7,802/1989" : "Lei nº 7.802/1989" }</b><br/>
          <Link to="../../../../assets/documents/LEI_N_7802.pdf" download="LEI_N_7802.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "Step by Step for Organic Conformity Certification - PPV" : "Passo a Passo para Certificação da Conformidade Orgânica - PPV" }</b><br/>
          <Link to="../../../../assets/documents/FORMSC-PASSO_A_PASSO-CPO.pdf" download="FORMSC-PASSO_A_PASSO-CPO.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "Ordinance No. 200/2021" : "Portaria nº 200/2021" }</b><br/>
          <Link to="../../../../assets/documents/PORTARIA_200_2021.pdf" download="PORTARIA_200_2021.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "Ordinance No. 52/2021 amended by Ordinance No. 404/2022 amended by Ordinance No. 811/2023" : "Portaria nº 52/2021 alterada pela Portaria nº 404/2022 alterada pela Portaria nº 811/2023" }</b><br/>
          <Link to="../../../../assets/documents/PORTARIA_N_52.pdf" download="PORTARIA_N_52.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "Ordinance No. 811/2023 amends Ordinance No. 52/2021" : "Portaria nº 811/2023 altera Portaria nº 52/2021" }</b><br/>
          <Link to="../../../../assets/documents/PORTARIA_811_2023_ALTERA_PORTARIA _52_2021.pdf" download="PORTARIA_811_2023_ALTERA_PORTARIA _52_2021.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "Rectified Ordinance No. 811/2023 amends Ordinance No. 52/2021" : "Portaria nº 811/2023 retificado altera Portaria nº 52/2021" }</b><br/>
          <Link to="../../../../assets/documents/PORTARIA_811_2023_RETIFICADO.pdf" download="PORTARIA_811_2023_RETIFICADO.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "Request for Certification of Organic Products" : "Solicitação de Certificação de Produtos Orgânicos" }</b><br/>
          <Link to="../../../../assets/documents/FORM-66-SOL_CERT_CPO-REV-11.docx" download="FORM-66-SOL_CERT_CPO-REV-11.docx" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "Request for Declaration of Commercial Transaction - DTC" : "Solicitação de Declaração de Transação Comercial - DTC" }</b><br/>
          <Link to="../../../../assets/documents/FORM-66-1-SOL_DECL_TRAN_COM-REV01.docx" download="FORM-66-1-SOL_DECL_TRAN_COM-REV01.docx" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/><br/>
      </div>
    </div>
  )
}

export default DocumentosOrganicoBrasil;