import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function DocumentosCAFEPractices(){

	const [lang, setLang] = useState();

	useEffect(()  => {
		const checkLang = function() {
			if(localStorage.getItem('lang')) {
				setLang(JSON.parse(localStorage.getItem('lang')));
			} else {
				setLang(0);
			}
		}

		window.addEventListener('storage', checkLang)

		return () => {
			window.removeEventListener('storage', checkLang)
		}
  }, []);

  useEffect(()  => {		
		if(localStorage.getItem('lang')) {
			setLang(JSON.parse(localStorage.getItem('lang')));
		} else {
			setLang(0);
		}		
    window.dispatchEvent(new Event("newRoute"));
  }, []);

  return(
    <div style={{backgroundColor:'#F7FBF5'}}>
      <div className="documentossubpages">
        <div>
        <Link className="fa fa-chevron-left fa-2x" to="/documentos" state={{from: 0}}/><br/>
        <h1>C.A.F.E. Practices - Starbucks</h1><br/>
        </div>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "C.A.F.E Practices Verifier and Inspector Operations Manual V 5.3" : "C.A.F.E Practices Manual de Operações Verificador e Inspetor V 5.3" }</b><br/>
          <Link to="../../../../assets/documents/cafe_man_verinsops_v5_3_por_051018_0.pdf" download="cafe_man_verinsops_v5_3_por_051018_0.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>Verifier and Inspector user Manual (VRS) V2.0_ENG</b><br/>
          <Link to="../../../../assets/documents/Verifier_and_Inspector_user_manual.pdf" download="Verifier_and_Inspector_user_manual.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/><br/>
      </div>
    </div>
  )
}

export default DocumentosCAFEPractices;