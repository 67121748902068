import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function AnaliseTecnica(){

	const [lang, setLang] = useState();

	useEffect(()  => {
		const checkLang = function() {
			if(localStorage.getItem('lang')) {
				setLang(JSON.parse(localStorage.getItem('lang')));
			} else {
				setLang(0);
			}
		}

		window.addEventListener('storage', checkLang)

		return () => {
			window.removeEventListener('storage', checkLang)
		}
  }, []);

  useEffect(()  => {		
		if(localStorage.getItem('lang')) {
			setLang(JSON.parse(localStorage.getItem('lang')));
		} else {
			setLang(0);
		}
    window.dispatchEvent(new Event("newRoute"));		
  }, []);

  return(
    <div style={{backgroundColor:'#F7FBF5'}}>
      <div className="analisetecnica">
      <img style={{borderRadius:'0px 0px 25px 25px'}} className="certificadorabanner" src={lang ? require("../../assets/images/b-analise-i.png") : require("../../assets/images/b-analise.png")} alt="banner"/><br/><br/>
        <div className="analisetecnicacontainer">
          <Link to="/analise-tecnica/emissao-de-laudos-cob-e-scaa">
            <img className="analisetecnicafoto" src={require("../../assets/images/laudos-cob-scaa.png")} alt={ lang ? "Issuance of COB and SCAA Reports" : "Emissão de Laudos COB e SCAA" }/>
            <div className="analisetecnicamiddle1">
              <div className="analisetecnicatext">{ lang ? "Issuance of COB and SCAA Reports" : "Emissão de Laudos COB e SCAA" }</div>
            </div>
          </Link>
          <Link to="/analise-tecnica/auditoria-de-embarques-de-produtos">
            <img className="analisetecnicafoto" src={require("../../assets/images/auditoria-embarque.png")} alt={ lang ? "Audit of grain and product shipments" : "Auditoria de Embarques de Grãos e Produtos" }/>
            <div className="analisetecnicamiddle1">
              <div className="analisetecnicatext">{ lang ? "Audit of grain and product shipments" : "Auditoria de Embarques de Grãos e Produtos" }</div>
            </div>
          </Link>
        </div>
        <br/>
        <div className="analisetecnicacontainer">
          <Link to="/analise-tecnica/auditoria-de-estoque-e-qualidade">
            <img className="analisetecnicafoto" src={require("../../assets/images/auditoria-estoque-qualidade.png")} alt={ lang ? "Stock and Quality Audit" : "Auditoria de Estoque e Qualidade" }/>
            <div className="analisetecnicamiddle1">
              <div className="analisetecnicatext">{ lang ? "Stock Audit in Warehouses" : "Auditoria de Estoque em Armazéns" }</div>
            </div>
          </Link>
          <Link to="/analise-tecnica/coordenacao-de-concursos-de-qualidade">
            <img className="analisetecnicafoto" src={require("../../assets/images/coord-concursos-qualidade.png")} alt={ lang ? "Coordination of Quality Contests" : "Coordenação de Concursos de Qualidade" }/>
            <div className="analisetecnicamiddle1">
              <div className="analisetecnicatext">{ lang ? "Coordination of Quality Coffee Competitions and Events" : "Coordenação de Concursos e Eventos de Café de Qualidade" }</div>
            </div>
          </Link>
        </div><br/><br/>
      </div>
    </div>
  )
}

export default AnaliseTecnica;