import { useState, useEffect } from 'react';

function AuditoriaEstoqueQualidade(){

  const [lang, setLang] = useState();

	useEffect(()  => {
		const checkLang = function() {
			if(localStorage.getItem('lang')) {
				setLang(JSON.parse(localStorage.getItem('lang')));
			} else {
				setLang(0);
			}
		}

		window.addEventListener('storage', checkLang)

		return () => {
			window.removeEventListener('storage', checkLang)
		}
  }, []);

  useEffect(()  => {		
		if(localStorage.getItem('lang')) {
			setLang(JSON.parse(localStorage.getItem('lang')));
		} else {
			setLang(0);
		}
    window.dispatchEvent(new Event("openModal"));	
    window.dispatchEvent(new Event("newRoute"));		
  }, []);

  const [nome, setNome] = useState('');
  const [telefone, setTelefone] = useState('');
  const [email, setEmail] = useState('');
  const [assunto] = useState('Auditoria de Estoque em Armazéns');
  const [mensagem, setMensagem] = useState('');

  const maskTel = (v) => {
    v = v.replace(/\D/g, "")

    v = v.replace(/(\d{2})(\d)/,"($1) $2")
    v = v.replace(/(\d)(\d{4})$/,"$1-$2")

    setTelefone(v)
  }

  const sendEmail = async (e) => {
    e.preventDefault();

    if(nome === '' || telefone === '' || email === '' || assunto === '' || mensagem === ''){
      const alertMessage = lang ? "Please fill in all fields" : "Por favor preencha todos os campos";
      alert(alertMessage);
      return;
    }

    var destinatario = 'comercial4@savassicertificadora.com.br,comercial@savassicertificadora.com.br,fonteboag@savassicertificadora.com.br,dantasr@savassicertificadora.com.br';

    const templateParams = {
      origemMensagem: "Novo pedido de orçamento para Auditoria de Estoque em Armazéns",
      nome: nome,
      telefone: telefone,
      email: email,
      assunto: assunto,
      mensagem: mensagem,
      destinatario: destinatario
    }

    window.dispatchEvent(new Event("closeModal"));
    setNome('');
    setTelefone('');
    setEmail('');
    setMensagem('');

    let response = await fetch("/contato", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8"
      },
      body: JSON.stringify(templateParams)
    })

    let result = await response.json();

    if(result.status==="200 OK") {
      console.log("EMAIL ENVIADO", response.status, response.text);
      alert(lang ? 'Budget request sent successfully!' : 'Solicitação de orçamento enviada com sucesso!')
    } else {
      console.log("ERRO: ", result.status);
    }

  }

  return(
    <div style={{backgroundColor:'#F7FBF5'}}>
      <div className="analisesubpages">
        <img className="analisesubpagesbanner" src={ lang ? require("../../../../assets/images/auditoria-estoque-qualidade-banner-ingles.png") : require("../../../../assets/images/auditoria-estoque-qualidade-banner.png") } alt="banner"/><br/><br/>
        <p>
          {
            lang ?  
            "If you work in agribusiness, especially as rural producers and cooperatives, it is worth considering carrying out a stock audit to ensure the quality of your products. Savassi Agronegócio specializes in this service, focusing on warehouse audits, stock analysis, product quality and procedures, where we issue a Technical Responsibility Note (ART) with CREA." : 
            "Você que trabalha no agronegócio, especialmente como produtor rural e cooperativas, é interessante considerar a realização de uma auditoria de estoque para assegurar a qualidade dos seus produtos. A Savassi Agronegócio é especializada nesse serviço, focando na auditoria de armazéns, análise do estoque, qualidade dos produtos e procedimentos, onde emitimos uma Anotação de Responsabilidade Técnica (ART) junto ao CREA."
          }
        </p><br/>
        <p>
          {
            lang ?  
            "Considering the natural wear and tear of building structures over time, faults arise that can directly affect the stored products, such as leaky tiles, broken windows, exposure to rain and humidity, as well as openings for pests, which can harm the quality of the products." : 
            "Considerando o desgaste natural das estruturas prediais ao longo do tempo, surgem falhas que podem afetar diretamente os produtos armazenados, como telhas furadas, vidraças quebradas, exposição a chuva e umidade, assim como aberturas para pragas, podem prejudicar a qualidade dos produtos."
          }
        </p><br/>
        <p>
          {
            lang ?  
            "In stock and quality auditing, we play a crucial role, carrying out audits, controlling inventories and the movement of materials. We analyze divergences or conformities in procedures, contributing to the standardization of processes. Furthermore, applying preventive and corrective measures to preserve the financial and physical integrity of the assets." : 
            "Na auditoria de estoque e qualidade desempenhamos um papel crucial, realizando auditorias, controlando inventários e a movimentação de materiais. Analisamos divergências ou conformidades nos procedimentos, contribuindo para a padronização dos processos. Além disso, aplicando medidas preventivas e corretivas para preservar a integridade financeira e física do patrimônio."
          }
        </p><br/>
        <p>
          {
            lang ?  
            "Hiring Stock and Quality Audit services becomes essential for warehouses. Savassi Agronegócio, through the SAVASSI TECHNICAL ANALYSIS department, offers a team prepared to carry out this work responsibly and effectively." : 
            "A contratação de serviços de Auditoria de Estoque e Qualidade torna-se essencial para armazens. A Savassi Agronegócio, por meio do departamento SAVASSI ANÁLISE TÉCNICA, oferece uma equipe preparada para realizar esse trabalho com responsabilidade e eficácia."
          }
        </p><br/><br/>

        <div className="capacitacaodetalhes">
          <button id="myBtn">{ lang ? "Request a Quote" : "Solicitar Orçamento" }</button><br/><br/>
        </div>

        <div id="myModal" className="modal">
          <div className="modal-content">
            <div className="modal-header">
              <span className="close">&times;</span>
              <h2>{ lang ? "Request a Quote" : "Solicite um orçamento" }</h2>
            </div>
            <div className="modal-body">
              <form onSubmit={sendEmail}>
                <input type="text" name="nome" onChange={(e) => setNome(e.target.value)} value={nome} placeholder={ lang ? "Enter your name" : "Informe seu nome" }></input> <br/>
                <input type="tel" name="telefone" onChange={(e) => maskTel(e.target.value)} value={telefone} placeholder={ lang ? "Enter your phone" : "Informe seu telefone" }></input> <br/>
                <input type="email" name="email" onChange={(e) => setEmail(e.target.value)} value={email} placeholder={ lang ? "Enter your e-mail" : "Informe seu e-mail" }></input> <br/>
                <textarea name="mensagem" rows="5" onChange={(e) => setMensagem(e.target.value)} value={mensagem} placeholder={ lang ? "Enter your message" : "Informe sua mensagem" }></textarea> <br/>
                <input id="modalBtn" type="submit" name="enviar" value={ lang ? "Send" : "Enviar" }></input> <br/>
              </form>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default AuditoriaEstoqueQualidade;