import { useState, useEffect } from 'react';

function Certificado4C(){

	const [lang, setLang] = useState();

	useEffect(()  => {
		const checkLang = function() {
			if(localStorage.getItem('lang')) {
				setLang(JSON.parse(localStorage.getItem('lang')));
			} else {
				setLang(0);
			}
		}

		window.addEventListener('storage', checkLang)

		return () => {
			window.removeEventListener('storage', checkLang)
		}
  }, []);

  useEffect(()  => {		
		if(localStorage.getItem('lang')) {
			setLang(JSON.parse(localStorage.getItem('lang')));
		} else {
			setLang(0);
		}
    window.dispatchEvent(new Event("openModal"));	
    window.dispatchEvent(new Event("newRoute"));		
  }, []);

  const [nome, setNome] = useState('');
  const [telefone, setTelefone] = useState('');
  const [email, setEmail] = useState('');
  const [assunto] = useState('4C');
  const [mensagem, setMensagem] = useState('');

  const maskTel = (v) => {
    v = v.replace(/\D/g, "")

    v = v.replace(/(\d{2})(\d)/,"($1) $2")
    v = v.replace(/(\d)(\d{4})$/,"$1-$2")

    setTelefone(v)
  }

  const sendEmail = async (e) => {
    e.preventDefault();

    if(nome === '' || telefone === '' || email === '' || assunto === '' || mensagem === ''){
      const alertMessage = lang ? "Please fill in all fields" : "Por favor preencha todos os campos";
      alert(alertMessage);
      return;
    }

    var destinatario = 'comercial@savassicertificadora.com.br,fonteboag@savassicertificadora.com.br,dantasr@savassicertificadora.com.br';

    const templateParams = {
      origemMensagem: "Novo pedido de orçamento para 4C",
      nome: nome,
      telefone: telefone,
      email: email,
      assunto: assunto,
      mensagem: mensagem,
      destinatario: destinatario
    }

    window.dispatchEvent(new Event("closeModal"));
    setNome('');
    setTelefone('');
    setEmail('');
    setMensagem('');

    let response = await fetch("/contato", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8"
      },
      body: JSON.stringify(templateParams)
    })

    let result = await response.json();

    if(result.status==="200 OK") {
      console.log("EMAIL ENVIADO", response.status, response.text);
      alert(lang ? 'Budget request sent successfully!' : 'Solicitação de orçamento enviada com sucesso!')
    } else {
      console.log("ERRO: ", result.status);
    }

  }

  return(
    <div style={{backgroundColor:'#F7FBF5'}}>
      <div className="certificadorasubpages">
        <img src={require("../../../../assets/images/4c-banner.png")} alt="banner"/><br/><br/>
        <p>
          {
            lang ?  
            "The Savassi Certification body has been accredited with the Common Code of the Coffee Community (4C) since July 19, 2012 to carry out the conformity assessment of the 4C certification program." : 
            "O organismo Savassi Certificadora é credenciado junto ao Código Comum da Comunidade Cafeeira (4C) desde 19 de julho de 2012 para a realização da avaliação da conformidade do programa de certificação 4C."
          }<br/><br/>
          {
            lang ?  
            "4C certification governed by the 4C Certification Body Regulations in current version, which establishes the requirements for Certification Bodies (CBs) to become cooperating CBs of 4C Services GmbH, and the duties of cooperating CBs to perform certification services in accordance with 4C requirements. Furthermore, this document describes the requirements and qualifications needed for auditors and assessors to become 4C-appointed auditors and assessors in order to conduct and assess 4C audits." : 
            "A certificação 4C regida pelo Regulamento do Organismo de Certificação 4C em versão vigente, a qual estabelece os requisitos para os Organismos de Certificação (OCs) se tornarem OCs cooperante da 4C Services GmbH, e os deveres dos OCs cooperantes para executar serviços de certificação de acordo com os requisitos 4C. Além disso, este documento descreve os requisitos e qualificações necessárias para que os auditores e avaliadores se tornem auditores e avaliadores nomeados pela 4C, a fim de conduzir e avaliar auditorias 4C."
          }<br/><br/>
          {
            lang ?  
            "The 4C sustainability principles and criteria are set out in the “4C Code of Conduct” document, which was developed in a comprehensive and transparent multi-stakeholder process. The focus of the 4C Code of Conduct is the sustainable production of green coffee beans and their post-harvest activities. The certified target is the \"4C Units\", which are groups producing green coffee beans that consist of a Management Entity (ME) and its Business Partners (BPs). ME manages the 4C Unit, is responsible for implementing 4C requirements within the 4C Unit and is the holder of the 4C certificate. BPs are coffee producers and service providers, such as wet and dry processors, local traders and/or warehouses. In addition to certification for 4C Units, 4C offers chain of custody certification, which can be applied by Intermediate Buyers (IBs) and Final Buyers (FBs) outside the 4C Unit." : 
            "Os princípios e critérios de sustentabilidade do 4C estão estabelecidos no documento “Código de Conduta 4C”, que foi desenvolvido em um processo abrangente e transparente de múltiplas partes interessadas. O foco do Código de Conduta 4C é a produção sustentável de grãos verdes de café e suas atividades pós-colheita. O alvo certificado são as \"Unidades 4C\", que são grupos produtores de café verde em grão que consistem em uma Entidade Gestora (ME) e seus Parceiros de Negócio (BPs). A ME administra a Unidade 4C, é responsável pela implementação dos requisitos 4C dentro da Unidade 4C e é a detentora do certificado 4C. Os BPs são produtores de café e prestadores de serviço, tais como processadoras a úmido e a seco, comerciantes locais e/ou armazéns. Além da certificação para as Unidades 4C, o 4C oferece uma certificação de cadeia de custódia, que pode ser aplicada por Compradores Intermediários (IBs) e Compradores Finais (FBs) fora da Unidade 4C."
          }<br/><br/>
          {
            lang ?  
            "The 4C (Common Code for the Coffee Community) certification seal is a global initiative focused on sustainability in the coffee industry. It was developed to promote responsible and sustainable practices in coffee production, processing and trade." : 
            "O selo de certificação 4C (Common Code for the Coffee Community) é uma iniciativa global voltada para a sustentabilidade na indústria do café. Ele foi desenvolvido para promover práticas responsáveis e sustentáveis de produção, beneficiamento e comércio de café."
          }<br/><br/>
          {
            lang ?  
            "The main objective of the 4C certification seal is to ensure that coffee is produced in a socially fair, environmentally correct and economically viable way. Some important features of this seal include:" : 
            "O principal objetivo do selo de certificação 4C é garantir que o café seja produzido de forma socialmente justa, ambientalmente correta e economicamente viável. Algumas características importantes desse selo incluem:"
          }<br/><br/>
          <ol>
            <li>
              {
                lang ?  
                "Social responsibility: The 4C seal seeks to promote fair and safe working conditions for workers involved in coffee production. This includes respecting human rights, combating child labor and ensuring equal opportunities." : 
                "Responsabilidade social: O selo 4C busca promover condições de trabalho justas e seguras para os trabalhadores envolvidos na produção de café. Isso inclui respeitar os direitos humanos, combater o trabalho infantil e garantir a igualdade de oportunidades."
              }
            </li>
            <li>
              {
                lang ?  
                "Environmental conservation: Certification encourages agricultural practices that protect the environment, such as sustainable management of natural resources, conservation of biodiversity and reducing the use of harmful chemicals. Reforestation and water conservation practices are also encouraged." : 
                "Conservação ambiental: A certificação incentiva práticas agrícolas que protegem o meio ambiente, como o manejo sustentável dos recursos naturais, a conservação da biodiversidade e a redução do uso de produtos químicos prejudiciais. Também são encorajadas práticas de reflorestamento e conservação da água."
              }
            </li>
            <li>
              {
                lang ?  
                "Good agricultural practices: The 4C seal promotes the adoption of good agricultural practices, including the efficient use of resources, the control of pests and diseases in a sustainable way, the promotion of crop diversification and the improvement of coffee quality." : 
                "Boas práticas agrícolas: O selo 4C promove a adoção de boas práticas agrícolas, incluindo o uso eficiente de recursos, o controle de pragas e doenças de forma sustentável, a promoção da diversificação de culturas e a melhoria da qualidade do café."
              }
            </li>
            <li>
              {
                lang ?  
                "Traceability and transparency: 4C certification requires that coffee be tracked throughout the entire production chain, from the farm to the final consumer. This allows for greater transparency and ensures that certified coffee actually comes from producers who adhere to sustainable practices." : 
                "Rastreabilidade e transparência: A certificação 4C exige que o café seja rastreado ao longo de toda a cadeia produtiva, desde a fazenda até o consumidor final. Isso permite maior transparência e garante que o café certificado seja de fato proveniente de produtores aderentes às práticas sustentáveis."
              }
            </li>
          </ol><br/><br/>
          {
            lang ?  
            "It is important to highlight that the 4C seal is not a Fair Trade certification and does not guarantee minimum prices to producers. However, it seeks to establish a minimum sustainability standard for the coffee industry, with the aim of encouraging continuous improvement in production practices." : 
            "É importante destacar que o selo 4C não é uma certificação de comércio justo (Fair Trade) e não garante preços mínimos aos produtores. No entanto, ele busca estabelecer um padrão mínimo de sustentabilidade para a indústria do café, com o objetivo de incentivar a melhoria contínua das práticas de produção."
          }<br/><br/>
          {
            lang ?  
            "By choosing coffee with the 4C certification seal, consumers contribute to a more sustainable coffee industry, supporting producers engaged in responsible practices and promoting environmental conservation and social justice." : 
            "Ao escolher café com o selo de certificação 4C, os consumidores contribuem para uma indústria do café mais sustentável, apoiando produtores engajados em práticas responsáveis e promovendo a conservação ambiental e a justiça social."
          }
        </p><br/><br/>

        <div className="capacitacaodetalhes">
          <button id="myBtn">{ lang ? "Request a Quote" : "Solicitar Orçamento" }</button><br/><br/>
        </div>

        <div id="myModal" className="modal">
          <div className="modal-content">
            <div className="modal-header">
              <span className="close">&times;</span>
              <h2>{ lang ? "Request a Quote" : "Solicite um orçamento" }</h2>
            </div>
            <div className="modal-body">
              <form onSubmit={sendEmail}>
                <input type="text" name="nome" onChange={(e) => setNome(e.target.value)} value={nome} placeholder={ lang ? "Enter your name" : "Informe seu nome" }></input> <br/>
                <input type="tel" name="telefone" onChange={(e) => maskTel(e.target.value)} value={telefone} placeholder={ lang ? "Enter your phone" : "Informe seu telefone" }></input> <br/>
                <input type="email" name="email" onChange={(e) => setEmail(e.target.value)} value={email} placeholder={ lang ? "Enter your e-mail" : "Informe seu e-mail" }></input> <br/>
                <textarea name="mensagem" rows="5" onChange={(e) => setMensagem(e.target.value)} value={mensagem} placeholder={ lang ? "Enter your message" : "Informe sua mensagem" }></textarea> <br/>
                <input id="modalBtn" type="submit" name="enviar" value={ lang ? "Send" : "Enviar" }></input> <br/>
              </form>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Certificado4C;