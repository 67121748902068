import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Procedimentos(){

	const [lang, setLang] = useState();

	useEffect(()  => {
		const checkLang = function() {
			if(localStorage.getItem('lang')) {
				setLang(JSON.parse(localStorage.getItem('lang')));
			} else {
				setLang(0);
			}
		}

		window.addEventListener('storage', checkLang)

		return () => {
			window.removeEventListener('storage', checkLang)
		}
  }, []);

  useEffect(()  => {		
		if(localStorage.getItem('lang')) {
			setLang(JSON.parse(localStorage.getItem('lang')));
		} else {
			setLang(0);
		}
    window.dispatchEvent(new Event("newRoute"));		
  }, []);

  return(
    <div style={{backgroundColor:'#F7FBF5'}}>
      <div className="documentossubpages">
        <div>
        <Link className="fa fa-chevron-left fa-2x" to="/documentos" state={{from: 0}}/><br/>
        <h1>{ lang ? "Procedures" : "Procedimentos" }</h1><br/>
        </div>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "Basic Rights and Duties of the Certification Applicant" : "Direitos e Deveres Básicos do Solicitante da Certificação" }</b><br/>
          <Link to="../../../../assets/documents/Procedimento_Dir_e_Dev_Bas_Sol_Cert.pdf" download="Procedimento_Dir_e_Dev_Bas_Sol_Cert.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "Integrity is everyone's role. Report it." : "Integridade é papel de todos. Denuncie." }</b><br/>
          <Link to="../../../../assets/documents/Integridade_papel_de_todos_denuncie.pdf" download="Integridade_papel_de_todos_denuncie.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "Recycling" : "Reciclagem" }</b><br/>
          <span>{ lang ? "Reduce, reuse and recycle" : "Reduzir, Reutilizar e Reciclar" }</span><br/>
          <Link to="../../../../assets/documents/Reciclagem.pdf" download="Reciclagem.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "Complaints, Appeals and Protests" : "Reclamações, Apelações e Denúncias" }</b><br/>
          <Link to="../../../../assets/documents/Reclamacoes_Apelacoes_Denuncias.pdf" download="Reclamacoes_Apelacoes_Denuncias.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "Suspension, Cancellation, Termination, Extension and Reduction of Certification Scope" : "Suspensão, Cancelamento, Término, Extensão e Redução do Escopo da Certificação" }</b><br/>
          <Link to="../../../../assets/documents/suspensao_cancelamento_termino_extensao_reducao_escopo-da-certificacao.pdf" download="suspensao_cancelamento_termino_extensao_reducao_escopo-da-certificacao.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "Treatment of non-compliance, corrective and preventive actions" : "Tratamento de não conformidade, ações corretivas e preventivas" }</b><br/>
          <Link to="../../../../assets/documents/tratamento_nao-conformidade_corretiva_preventiva.pdf" download="tratamento_nao-conformidade_corretiva_preventiva.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "Use of Logo, Certificates and Brands" : "Uso do Logotipo, Certificados e Marcas" }</b><br/>
          <Link to="../../../../assets/documents/uso_logos_certificados_marcas.pdf" download="uso_logos_certificados_marcas.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "Procedure - Ombudsman" : "Procedimento - Ouvidoria" }</b><br/>
          <Link to="../../../../assets/documents/Ouvidoria.pdf" download="Ouvidoria.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "Procedure - Interested Parties Contribution" : "Procedimento - Contribuição de Partes Interessadas" }</b><br/>
          <Link to="../../../../assets/documents/Contribuicao-Partes-Interessadas.pdf" download="Contribuicao-Partes-Interessadas.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/><br/>
      </div>
    </div>
  )
}

export default Procedimentos;