import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function CafeGelado(){

	const [lang, setLang] = useState();

	useEffect(()  => {
		const checkLang = function() {
			if(localStorage.getItem('lang')) {
				setLang(JSON.parse(localStorage.getItem('lang')));
			} else {
				setLang(0);
			}
		}

		window.addEventListener('storage', checkLang)

		return () => {
			window.removeEventListener('storage', checkLang)
		}
  }, []);

  useEffect(()  => {		
		if(localStorage.getItem('lang')) {
			setLang(JSON.parse(localStorage.getItem('lang')));
		} else {
			setLang(0);
		}	
    window.dispatchEvent(new Event("newRoute"));	
  }, []);

  return(
    <div style={{backgroundColor:'#F7FBF5'}}>
      <div className="documentossubpages">
        <div>
        <Link className="fa fa-chevron-left fa-2x" to="/documentos" state={{from: 1}}/><br/>
        <h1>{ lang ? "Iced Coffee" : "Café Gelado" }</h1><br/>
        </div>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "Iced Coffee" : "Café Gelado" }</b><br/>
          <Link to="../../../../assets/documents/cafe_gelado.pdf" download="cafe gelado.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/><br/>
      </div>
    </div>
  )
}

export default CafeGelado;