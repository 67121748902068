import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function DocumentosUnidadeArmazenadora(){

	const [lang, setLang] = useState();

	useEffect(()  => {
		const checkLang = function() {
			if(localStorage.getItem('lang')) {
				setLang(JSON.parse(localStorage.getItem('lang')));
			} else {
				setLang(0);
			}
		}

		window.addEventListener('storage', checkLang)

		return () => {
			window.removeEventListener('storage', checkLang)
		}
  }, []);

  useEffect(()  => {		
		if(localStorage.getItem('lang')) {
			setLang(JSON.parse(localStorage.getItem('lang')));
		} else {
			setLang(0);
		}	
    window.dispatchEvent(new Event("newRoute"));	
  }, []);

  return(
    <div style={{backgroundColor:'#F7FBF5'}}>
      <div className="documentossubpages">
        <div>
        <Link className="fa fa-chevron-left fa-2x" to="/documentos" state={{from: 0}}/><br/>
        <h1>{ lang ? "Storage Unit in Natural Environment" : "Unidade Armazenadora em Ambiente Natural" }</h1><br/>
        </div>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "Decree No. 1,102/1903" : "Decreto Nº 1.102/1903" }</b><br/>
          <Link to="../../../../assets/documents/FORM-SC-DECRETO_N_1102-1903.pdf" download="FORM-SC-DECRETO_N_1102-1903.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "Decree No. 3,855/2001" : "Decreto Nº 3.855/2001" }</b><br/>
          <Link to="../../../../assets/documents/decreto_3855.pdf" download="decreto_3855.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "Normative Instruction No. 29/2011" : "Instrução Normativa Nº 29/2011" }</b><br/>
          <Link to="../../../../assets/documents/INSTRUÇÃO_NORMATIVA_N_29-08.pdf" download="INSTRUÇÃO_NORMATIVA_N_29-08.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "Normative Instruction No. 68/2020" : "Instrução Normativa Nº 68/2020" }</b><br/>
          <Link to="../../../../assets/documents/IN_MAPA_N_68_16-12-2020.pdf" download="IN_MAPA_N_68_16-12-2020.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "Law No. 11,076/2004" : "Lei Nº 11.076/2004" }</b><br/>
          <Link to="../../../../assets/documents/FORM-SC-LEI_N_11076-2004.pdf" download="FORM-SC-LEI_N_11076-2004.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "Law No. 8,171/1991" : "Lei Nº 8.171/1991" }</b><br/>
          <Link to="../../../../assets/documents/LEI_N_8171.pdf" download="LEI_N_8171.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "Law No. 9973/2000" : "Lei Nº 9973/2000" }</b><br/>
          <Link to="../../../../assets/documents/Lei_9973_2000.pdf" download="Lei_9973_2000.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "Step by Step for UAAN Certification" : "Passo a Passo para Certificação UAAN" }</b><br/>
          <Link to="../../../../assets/documents/PASSO_A_PASSO-UAAN.pdf" download="PASSO_A_PASSO-UAAN.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "Ordinance No. 274/2014" : "Portaria Nº 274/2014" }</b><br/>
          <Link to="../../../../assets/documents/PORTARIA_N_274_13.pdf" download="PORTARIA_N_274_13.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "Certification Request - UA" : "Solicitação de Certificação - UA" }</b><br/>
          <Link to="../../../../assets/documents/FORM-10-SOL_CERT_UAAN-REV-15.docx" download="FORM-10-SOL_CERT_UAAN-REV-15.docx" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/><br/>
      </div>
    </div>
  )
}

export default DocumentosUnidadeArmazenadora;