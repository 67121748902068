import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useState, useEffect } from 'react';

function RecertificacaoQGrader(){

	const [lang, setLang] = useState();	

	useEffect(()  => {
		const checkLang = function() {
			if(localStorage.getItem('lang')) {
				setLang(JSON.parse(localStorage.getItem('lang')));
			} else {
				setLang(0);
			}
		}

		window.addEventListener('storage', checkLang)

		return () => {
			window.removeEventListener('storage', checkLang)
		}
  }, []);

  useEffect(()  => {		
		if(localStorage.getItem('lang')) {
			setLang(JSON.parse(localStorage.getItem('lang')));
		} else {
			setLang(0);
		}
    window.dispatchEvent(new Event("openModal"));	
    window.dispatchEvent(new Event("newRoute"));
  }, []);

  const [curso] = useState('Recertificação Q-Grader');
  const [nome, setNome] = useState('');
  const [nomeCracha, setNomeCracha] = useState('');
  const [endereco, setEndereco] = useState('');
  const [numero, setNumero] = useState('');
  const [bairro, setBairro] = useState('');
  const [cep, setCep] = useState('');
  const [cidadeEstado, setCidadeEstado] = useState('');
  const [telefone, setTelefone] = useState('');
  const [email, setEmail] = useState('');
  const [telefone2, setTelefone2] = useState('');
  const [rg, setRg] = useState('');
  const [cpf, setCpf] = useState('');
  const [dataNascimento, setDataNascimento] = useState('');
  const [escolaridade, setEscolaridade] = useState('');
  const [razaoSocial, setRazaoSocial] = useState('');
  const [enderecoCnpj, setEnderecoCnpj] = useState('');
  const [numeroCnpj, setNumeroCnpj] = useState('');
  const [bairroCnpj, setBairroCnpj] = useState('');
  const [cidadeEstadoCnpj, setCidadeEstadoCnpj] = useState('');
  const [telefoneCnpj, setTelefoneCnpj] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [inscricaoEstadual, setInscricaoEstadual] = useState('');
  const [formaPagamento, setFormaPagamento] = useState('');
  const [responsavelPagamento, setResponsavelPagamento] = useState('');

  const handleTel1Input = (v) => {
    v = maskTel(v)
    setTelefone(v)
  }

  const handleTel2Input = (v) => {
    v = maskTel(v)
    setTelefone2(v)
  }

  const handleTelCnpjInput = (v) => {
    v = maskTel(v)
    setTelefoneCnpj(v)
  }

  const maskTel = (v) => {
    v = v.replace(/\D/g, "")

    v = v.replace(/(\d{2})(\d)/,"($1) $2")
    v = v.replace(/(\d)(\d{4})$/,"$1-$2")

    return v
  }

  const maskData = (v) => {
    v = v.replace(/\D/g, "")

    v = v.replace(/(\d{2})(\d)/,"$1/$2") 
    v = v.replace(/(\d{2})(\d)/,"$1/$2") 

    setDataNascimento(v)
  }

  const maskCep = (v) => {
    v = v.replace(/\D/g, "")

    v = v.replace(/\D/g,'')
    v = v.replace(/(\d{5})(\d)/,'$1-$2')

    setCep(v)

  }

  const maskCpf = (v) => {
    v = v.replace(/\D/g, "")
  
    v = v.replace(/(\d{3})(\d)/, "$1.$2")
    v = v.replace(/(\d{3})(\d)/, "$1.$2")
    v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2")

    setCpf(v)

  }

  const maskCnpj = (v) => {
    v = v.replace(/\D/g, "")

    v = v.replace(/^(\d{2})(\d)/, "$1.$2")
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
    v = v.replace(/\.(\d{3})(\d)/, ".$1/$2")
    v = v.replace(/(\d{4})(\d)/, "$1-$2")
    
    setCnpj(v)

  }

  const sendEmail = async (e) => {
    e.preventDefault();

    if(curso === '' || nome === '' || nomeCracha === '' || endereco === '' || numero === '' || bairro === '' || 
    cep === '' || cidadeEstado === '' || telefone === '' || email === '' || rg === '' || cpf === '' || 
    dataNascimento === '' || escolaridade === '' || formaPagamento === '' || responsavelPagamento === ''){
      const alertMessage = lang ? "Please, fill in all fields with an asterisk" : "Por favor, preencha todos os campos com asterisco";
      alert(alertMessage);
      return;
    }

    var destinatario = "cursos@savassiagronegocio.com.br,comercial@savassicertificadora.com.br,fonteboag@savassicertificadora.com.br,dantasr@savassicertificadora.com.br";

    const templateParams = {
      destinatario: destinatario,
      origemMensagem: "Novo pedido de matrícula no curso Recertificação Q-Grader",
      curso: curso,
      nome: nome,
      nomeCracha: nomeCracha,
      endereco: endereco,
      numero: numero,
      bairro: bairro,
      cep: cep,
      cidadeEstado: cidadeEstado,
      telefone: telefone,
      email: email,
      telefone2: telefone2,
      rg: rg,
      cpf: cpf,
      dataNascimento: dataNascimento,
      escolaridade: escolaridade,
      razaoSocial: razaoSocial,
      enderecoCnpj: enderecoCnpj,
      numeroCnpj: numeroCnpj,
      bairroCnpj: bairroCnpj,
      cidadeEstadoCnpj: cidadeEstadoCnpj,
      telefoneCnpj: telefoneCnpj,
      cnpj: cnpj,
      inscricaoEstadual: inscricaoEstadual,
      valor: 'US$1.120,00',
      formaPagamento: formaPagamento,
      responsavelPagamento: responsavelPagamento
    }

    window.dispatchEvent(new Event("closeModal"));
    setNome('');
    setNomeCracha('');
    setEndereco('');
    setNumero('');
    setBairro('');
    setCep('');
    setCidadeEstado('');
    setTelefone('');
    setEmail('');
    setTelefone2('');
    setRg('');
    setCpf('');
    setDataNascimento('');
    setEscolaridade('');
    setRazaoSocial('');
    setEnderecoCnpj('');
    setNumeroCnpj('');
    setBairroCnpj('');
    setCidadeEstadoCnpj('');
    setTelefoneCnpj('');
    setCnpj('');
    setInscricaoEstadual('');
    setFormaPagamento('');
    setResponsavelPagamento('');

    let response = await fetch("/cursos", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8"
      },
      body: JSON.stringify(templateParams)
    })

    let result = await response.json();

    if(result.status==="200 OK") {
      console.log("EMAIL ENVIADO", response.status, response.text);
      alert(lang ? 'Enrollment sent successfully!' : 'Matrícula enviada com sucesso!')
    } else {
      console.log("ERRO: ", result.status);
    }

  }

  return(
    <div style={{backgroundColor:'#F7FBF5'}}>
      <div className="capacitacaosubpages">
        <img className="capacitacaosubpagesbanner" src={ lang ? require("../../../../assets/images/banner-Recertificação-Q-Grader-ing.png") : require("../../../../assets/images/banner-Recertificação-Q-Grader.png") } alt="banner"/><br/><br/>
        <h2>{ lang ? "The Course" : "O Curso" }</h2><br/>
        <p>
          {
            lang ?  
            "It aims to carry out the Q-Grader renewal of the professional certificate at the advanced level of sensory analysis of specialty coffees;" : 
            "Tem como objetivo realizar a renovação Q-Grader do certificado profissionais no nível avançado de análise sensorial de cafés especiais;"
          }<br/><br/>
          {
            lang ?  
            "It is a recertification to maintain technical form and improvement in coffee tasting specialists. The technicians will be qualified and trained to guarantee the quality control in the production, handling, commercialization of the raw material and identification of unique characteristics;" : 
            "É uma recertificação para manter forma e aprimoramento técnicos especialistas em degustação de café. Os técnicos serão capacitados e treinados para garantir o controle de qualidade na produção, manipulação, comercialização da matéria prima e identificação de características únicas;"
          }<br/><br/>
          {
            lang ?  
            "Q-Grader certificates are valid for 3 (three) years from the date you completed your original Q-Grader course. Certificates will no longer be available in your profile when they expire, even if you still have 6 (six) months to calibrate. To renew the Q-Grader Certification, a calibration is required and after calibrating and passing, the certificate will be available again in your profile;" : 
            "Os certificados Q-Grader são válidos por 3 (três) anos a partir da data em que você terminou seu curso Q-Grader original. Os certificados não estarão mais disponíveis em seu perfil quando expirarem, mesmo que você ainda tenha 6 (seis) meses para calibrar. Para renovar a Certificação Q-Grader é necessário uma calibração e depois de calibrar e passar, o certificado estará disponível novamente em seu perfil;"
          }<br/><br/>
          {
            lang ?  
            "Calibration exams are required for all Q-Graders whose certificates are about to expire or have recently expired. To maintain your certificate, you must pass a calibration course starting 6 (six) months before the certificate expires or has already expired (within 6 (six) months);" : 
            "Os exames de calibração são necessários para todos os Q-Graders cujos certificados estão prestes a expirar ou expiraram recentemente. Para manter seu certificado, você deve ser aprovado no curso de calibração com início 6 (seis) meses antes do vencimento do certificado ou já ter expirado (dentro de 6 (seis) meses);"
          }<br/><br/>
          {
            lang ?  
            "Q-Graders who fail a calibration exam may retake a calibration exam 1 (one) time to renew their Q-Grader certificate. For this resumption, they must join another Calibration Course within 6 (six) months and pass it successfully. If a Q-Grader lets his certificate expire without calibrating or fails all calibration attempts, he will have 5 (five) years from the expiration date of the last certificate to participate in a Q-Grader Recertification Course. After this period, the Q-Grader must retake a full Q-Course and is no longer eligible for the Q Recertification Course." : 
            "Os Q-Graders que falharem em um exame de calibração podem refazer um exame de calibração 1 (uma) vez para renovar seu certificado de Q-Grader. Para essa retomada, eles devem ingressar em outro Curso de Calibração dentro de 6 (seis) meses e passar com sucesso. Se um Q-Grader deixar seu certificado expirar sem calibrar ou falhar em todas as tentativas de calibração, ele terá 5 (cinco) anos a partir da data de expiração do último certificado para participar de um Curso de Recertificação Q-Grader. Após este período, o Q-Grader deve fazer novamente um Q-Course completo e não é mais elegível para o Q Recertification Course."
          }
        </p><br/><br/>
        <div className="capacitacaodetalhes">
          <h2>{ lang ? "Investiment" : "Investimento" }</h2>
          <h2 id="money">US$ 1.120,00</h2><br/>
          <p><b>
            { 
              lang ? 
              "Course price: US$ 1,120.00 (Tourism Dollar)" : 
              "Preço do curso: US$ 1.120,00 (Dólar Turismo)"
            }<br/><br/>
            { 
              lang ? 
              "Cash via PIX or Cash: US$ 1,050.00" : 
              "À vista via PIX ou Dinheiro: US$ 1.050,00"
            }<br/><br/>
            { 
              lang ? 
              "Installments by Credit Card in up to 3 installments: US$ 1,050.00" : 
              "Parcelado no Cartão de Crédito em até 3x vezes: US$ 1.050,00"
            }<br/><br/>
            { 
              lang ? 
              "Installments on the Credit Card in up to 10 installments (interest on the card itself)" : 
              "Parcelado no Cartão de Crédito em até 10x vezes (juros do próprio cartão)"
            }<br/><br/>
            { 
              lang ? 
              "Note: The dollar value is quoted on the day of payment, the value can be up or down." : 
              "Observação: O valor do dólar é cotado no dia do pagamento, o valor pode ser para mais ou para menos"
            }<br/><br/>
          </b></p>
          <button id="myBtn">{ lang ? "Enroll" : "Matricular" }</button><br/><br/>
        </div><br/>

        <div id="myModal" className="modal">
          <div className="modal-content">
            <div className="modal-header">
              <span className="close">&times;</span>
              <h2>{ lang ? "Enroll now!" : "Matricule-se agora!" }</h2>
            </div>
            <div className="modal-body">
              <form onSubmit={sendEmail}>
                <span><strong>{ lang ? "CONTRACTOR'S Personal Data" : "Dados Pessoais do CONTRATANTE" }</strong></span>
                <input type="text" name="curso" disabled value={curso}></input> <br/>
                <input type="text" name="nome" onChange={(e) => setNome(e.target.value)} value={nome} placeholder={ lang ? "Full name*" : "Nome completo*" }></input> <br/>
                <input type="text" name="nomeCracha" onChange={(e) => setNomeCracha(e.target.value)} value={nomeCracha} placeholder={ lang ? "Name highlighted on badge*" : "Nome em destaque no crachá*" }></input> <br/>
                <input type="text" name="endereco" onChange={(e) => setEndereco(e.target.value)} value={endereco} placeholder={ lang ? "Address*" : "Endereço*" }></input> <br/>
                <input type="text" name="numero" onChange={(e) => setNumero(e.target.value)} value={numero} placeholder={ lang ? "Address number*" : "Número*" }></input> <br/>
                <input type="text" name="bairro" onChange={(e) => setBairro(e.target.value)} value={bairro} placeholder={ lang ? "Neighbourhood*" : "Bairro*" }></input> <br/>
                <input type="text" name="cep" onChange={(e) => maskCep(e.target.value)} value={cep} placeholder={ lang ? "Postal Code*" : "CEP*" }></input> <br/>
                <input type="text" name="cidadeEstado" onChange={(e) => setCidadeEstado(e.target.value)} value={cidadeEstado} placeholder={ lang ? "City and State*" : "Cidade e Estado*" }></input> <br/>
                <input type="tel" name="telefone" onChange={(e) => handleTel1Input(e.target.value)} value={telefone} placeholder={ lang ? "Phone number*" : "Telefone*" }></input> <br/>
                <input type="email" name="email" onChange={(e) => setEmail(e.target.value)} value={email} placeholder={ lang ? "E-mail*" : "E-mail*" }></input> <br/>
                <input type="tel" name="telefone2" onChange={(e) => handleTel2Input(e.target.value)} value={telefone2} placeholder={ lang ? "Phone number of a relative or someone close" : "Telefone de um parente ou alguém próximo" }></input> <br/>
                <input type="text" name="rg" onChange={(e) => setRg(e.target.value)} value={rg} placeholder={ lang ? "RG*" : "RG*" }></input> <br/>
                <input type="text" name="cpf" onChange={(e) => maskCpf(e.target.value)} value={cpf} placeholder={ lang ? "CPF*" : "CPF*" }></input> <br/>
                <input type="text" name="dataNascimento" onChange={(e) => maskData(e.target.value)} value={dataNascimento} placeholder={ lang ? "Birth Date*" : "Data de Nascimento*" }></input> <br/>
                <input type="text" name="escolaridade" onChange={(e) => setEscolaridade(e.target.value)} value={escolaridade} placeholder={ lang ? "Education*" : "Escolaridade*" }></input> <br/>
                <span><strong>{ lang ? "Company Data - for LEGAL ENTITY" : "Dados da Empresa - para PESSOA JURÍDICA" }</strong></span>
                <input type="text" name="razaoSocial" onChange={(e) => setRazaoSocial(e.target.value)} value={razaoSocial} placeholder={ lang ? "Corporate name" : "Razão Social" }></input> <br/>
                <input type="text" name="enderecoCnpj" onChange={(e) => setEnderecoCnpj(e.target.value)} value={enderecoCnpj} placeholder={ lang ? "Address" : "Endereço" }></input> <br/>
                <input type="text" name="numeroCnpj" onChange={(e) => setNumeroCnpj(e.target.value)} value={numeroCnpj} placeholder={ lang ? "Address number" : "Número" }></input> <br/>
                <input type="text" name="bairroCnpj" onChange={(e) => setBairroCnpj(e.target.value)} value={bairroCnpj} placeholder={ lang ? "Neighbourhood" : "Bairro" }></input> <br/>
                <input type="text" name="cidadeEstadoCnpj" onChange={(e) => setCidadeEstadoCnpj(e.target.value)} value={cidadeEstadoCnpj} placeholder={ lang ? "City and State" : "Cidade e Estado" }></input> <br/>
                <input type="tel" name="telefoneCnpj" onChange={(e) => handleTelCnpjInput(e.target.value)} value={telefoneCnpj} placeholder={ lang ? "Phone number" : "Telefone" }></input> <br/>
                <input type="text" name="cnpj" onChange={(e) => maskCnpj(e.target.value)} value={cnpj} placeholder={ lang ? "CNPJ" : "CNPJ" }></input> <br/>
                <input type="text" name="inscricaoEstadual" onChange={(e) => setInscricaoEstadual(e.target.value)} value={inscricaoEstadual} placeholder={ lang ? "State registration" : "Inscrição Estadual" }></input> <br/>
                <span><strong>{ lang ? "Payment" : "Pagamento" }</strong></span>
                <input type="text" name="valor" disabled value='US$1.120,00'></input> <br/>
                <input type="text" name="formaPagamento" onChange={(e) => setFormaPagamento(e.target.value)} value={formaPagamento} placeholder={ lang ? "Payment Method*" : "Forma de Pagamento*" }></input> <br/>
                <input type="text" name="responsavelPagamento" onChange={(e) => setResponsavelPagamento(e.target.value)} value={responsavelPagamento} placeholder={ lang ? "Responsible for payment*" : "Responsável pelo pagamento*" }></input> <br/>
                <input id="modalBtn" type="submit" name="enviar" value={ lang ? "Send" : "Enviar" }></input> <br/>
              </form>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>

        <Tabs className="capacitacaotabs">
          <TabList>
            <Tab>{ lang ? "Activities" : "Atividades" }</Tab>
            <Tab>{ lang ? "Target Audience" : "Público-Alvo" }</Tab>
            <Tab>{ lang ? "Workload" : "Carga-Horária" }</Tab>
            <Tab>{ lang ? "Where to Stay" : "Onde Hospedar" }</Tab>
          </TabList>

          <TabPanel className="capacitacaotabpanel">
            <br/>
            <span>
              { 
                lang ? 
                "Tasting alignment activities based on protocol and cupping of coffees from different origins." : 
                "Atividades de alinhamento de degustação baseado no protocolo e cupping de cafés de origens distintas."
              }
            </span>
            <br/><br/>
          </TabPanel>
          <TabPanel className="capacitacaotabpanel">
            <br/>
            <span>{ lang ? "Professionals in the Coffee business in order to renew the Q-Grader certificate." : "Profissionais no ramo do Café com a finalidade de renovar o certificado Q-Grader." }</span>
            <br/><br/>
          </TabPanel>
          <TabPanel className="capacitacaotabpanel">
            <br/>
            <span>{ lang ? "8 hours" : "8 horas" }</span>
            <br/><br/>
          </TabPanel>
          <TabPanel className="capacitacaotabpanel">
            <br/>
            <h4>{ lang ? "Partner Hotels:" : "Hotéis Parceiros:" }</h4>
            <Link to="https://www.ourocerradohotel.com.br/" target="_blank">Ouro Cerrado Hotel</Link><br/>
            <Link to="http://www.minashotel.com.br/" target="_blank">Minas Hotel</Link><br/>
            <span>Center Hotel</span><br/>
            <span>Guines Hotel</span><br/>
            <span>Grande Avenida</span><br/><br/>
            <h4>{ lang ? "Other Hotels:" : "Outros Hotéis:" }</h4>
            <Link to="http://bit.ly/2sISAXG" target="_blank">{ lang ? "Patrocínio hotels in Trivago" : "Hotéis de Patrocínio em Trivago" }</Link><br/>
            <br/>
          </TabPanel>
        </Tabs><br/>
      </div>
    </div>
  )
}

export default RecertificacaoQGrader;