import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Formularios(){

	const [lang, setLang] = useState();

	useEffect(()  => {
		const checkLang = function() {
			if(localStorage.getItem('lang')) {
				setLang(JSON.parse(localStorage.getItem('lang')));
			} else {
				setLang(0);
			}
		}

		window.addEventListener('storage', checkLang)

		return () => {
			window.removeEventListener('storage', checkLang)
		}
  }, []);

  useEffect(()  => {		
		if(localStorage.getItem('lang')) {
			setLang(JSON.parse(localStorage.getItem('lang')));
		} else {
			setLang(0);
		}
    window.dispatchEvent(new Event("newRoute"));		
  }, []);

  return(
    <div style={{backgroundColor:'#F7FBF5'}}>
      <div className="documentossubpages">
        <div>
        <Link className="fa fa-chevron-left fa-2x" to="/documentos" state={{from: 0}}/><br/>
        <h1>{ lang ? "Forms" : "Formulários" }</h1><br/>
        </div>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "FORM.12 - SATISFACTION SURVEY - CUSTOMER" : "FORM.12 - PESQUISA SATISFAÇÃO - CLIENTE" }</b><br/>
          <Link to="../../../../assets/documents/FORM-12-PESQUISA_SATISFAÇÃO-REV-09.docx" download="FORM-12-PESQUISA_SATISFAÇÃO-REV-09.docx" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "FORM.17 - COMPLAINT APPEAL AND PROTEST" : "FORM.17 - RECLAMAÇÃO APELAÇÃO E DENÚNCIA" }</b><br/>
          <Link to="../../../../assets/documents/FORM-17-RECL_APEL_DENU-REV09.docx" download="FORM-17-RECL_APEL_DENU-REV09.docx" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "FORM.17.2 - CONTRIBUTION OF INTERESTED PARTIES" : "FORM.17.2 - CONTRIBUIÇÃO DE PARTES INTERESSADAS" }</b><br/>
          <Link to="../../../../assets/documents/FORM.17.2-CONTRIBUIÇÃO_DE_PARTES_INTERESSADAS-REV-02.docx" download="FORM.17.2-CONTRIBUIÇÃO_DE_PARTES_INTERESSADAS-REV-02.docx" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/><br/>
      </div>
    </div>
  )
}

export default Formularios;