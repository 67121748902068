import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Capacitacao(){

	const [lang, setLang] = useState();

	useEffect(()  => {
		const checkLang = function() {
			if(localStorage.getItem('lang')) {
				setLang(JSON.parse(localStorage.getItem('lang')));
			} else {
				setLang(0);
			}
		}

		window.addEventListener('storage', checkLang)

		return () => {
			window.removeEventListener('storage', checkLang)
		}
  }, []);

  useEffect(()  => {		
		if(localStorage.getItem('lang')) {
			setLang(JSON.parse(localStorage.getItem('lang')));
		} else {
			setLang(0);
		}
    window.dispatchEvent(new Event("newRoute"));		
  }, []);

  return(
    <div style={{backgroundColor:'#F7FBF5'}}>
      <div className="capacitacao">
        <img style={{borderRadius:'0px 0px 25px 25px'}} className="certificadorabanner" src={lang ? require("../../assets/images/b-capacitacao-i.png") : require("../../assets/images/b-capacitacao.png")} alt="banner"/><br/><br/>
        <div className="capacitacaocontainer">
          <Link to="/capacitacao/cdc">
            <img className="capacitacaofoto" src={require("../../assets/images/cdc.png")} alt={ lang ? "Coffee Classification and Tasting - CDC" : "Classificação e Degustação de Café - CDC" }/>
            <div className="capacitacaomiddle1">
              <div className="capacitacaotext">{ lang ? "Coffee Classification and Tasting - CDC" : "Classificação e Degustação de Café - CDC" }</div>
            </div>
          </Link>
          <Link to="/capacitacao/com">
            <img className="capacitacaofoto" src={require("../../assets/images/com.png")} alt={ lang ? "Marketing of Coffee - COM" : "Comercialização de Café - COM" }/>
            <div className="capacitacaomiddle1">
              <div className="capacitacaotext">{ lang ? "Marketing of Coffee - COM" : "Comercialização de Café - COM" }</div>
            </div>
          </Link>
        </div>
        <br/>
        <div className="capacitacaocontainer">
          <Link to="/capacitacao/pmt">
            <img className="capacitacaofoto" src={require("../../assets/images/pmt.png")} alt="Professional Master in Tasting - PMT"/>
            <div className="capacitacaomiddle1">
              <div className="capacitacaotext">Professional Master in Tasting - PMT</div>
            </div>
          </Link>
          <Link to="/capacitacao/q-grader">
            <img className="capacitacaofoto" src={require("../../assets/images/q-grader.png")} alt="Q-Grader"/>
            <div className="capacitacaomiddle1">
              <div className="capacitacaotext">Q-Grader</div>
            </div>
          </Link>
        </div>
        <br/>
        <div className="capacitacaocontainer">
          <Link to="/capacitacao/calibracao-q-grader">
            <img className="capacitacaofoto" src={require("../../assets/images/calibracao-q-grader.png")} alt={ lang ? "Q-Grader Calibration" : "Calibração Q-Grader" }/>
            <div className="capacitacaomiddle1">
              <div className="capacitacaotext">{ lang ? "Q-Grader Calibration" : "Calibração Q-Grader" }</div>
            </div>
          </Link>
          <Link to="/capacitacao/recertificacao-q-grader">
            <img className="capacitacaofoto" src={require("../../assets/images/recertificacao-q-grader.png")} alt={ lang ? "Q-Grader Recertification" : "Recertificação Q-Grader" }/>
            <div className="capacitacaomiddle1">
              <div className="capacitacaotext">{ lang ? "Q-Grader Recertification" : "Recertificação Q-Grader" }</div>
            </div>
          </Link>
        </div>
        <br/>
        <div className="capacitacaocontainer">
          <Link to="/capacitacao/retake-cdc">
            <img className="capacitacaofoto" src={require("../../assets/images/retake-cdc.png")} alt={ lang ? "CDC Retake" : "Retake CDC" }/>
            <div className="capacitacaomiddle1">
              <div className="capacitacaotext">{ lang ? "CDC Retake" : "Retake CDC" }</div>
            </div>
          </Link>
          <Link to="/capacitacao/retake-pmt">
            <img className="capacitacaofoto" src={require("../../assets/images/retake-pmt.png")} alt={ lang ? "PMT Retake" : "Retake PMT" }/>
            <div className="capacitacaomiddle1">
              <div className="capacitacaotext">{ lang ? "PMT Retake" : "Retake PMT" }</div>
            </div>
          </Link>
        </div>
        <br/>
        <div className="capacitacaocontainer">
          <Link to="/capacitacao/retake-q-grader">
            <img className="capacitacaofoto" src={require("../../assets/images/retake-q-grader.png")} alt={ lang ? "Q-Grader Retake" : "Retake Q-Grader" }/>
            <div className="capacitacaomiddle1">
              <div className="capacitacaotext">{ lang ? "Q-Grader Retake" : "Retake Q-Grader" }</div>
            </div>
          </Link>
          <Link to="/capacitacao/curso-torra">
            <img className="capacitacaofoto" src={require("../../assets/images/curso-torra.png")} alt={ lang ? "Roasting Course" : "Curso de Torra" }/>
            <div className="capacitacaomiddle1">
              <div className="capacitacaotext">{ lang ? "Roasting Course" : "Curso de Torra" }</div>
            </div>
          </Link>
        </div><br/><br/>
      </div>
    </div>
  )
}

export default Capacitacao;