import { useState, useEffect } from 'react';

function RainforestAlliance(){

	const [lang, setLang] = useState();

	useEffect(()  => {
		const checkLang = function() {
			if(localStorage.getItem('lang')) {
				setLang(JSON.parse(localStorage.getItem('lang')));
			} else {
				setLang(0);
			}
		}

		window.addEventListener('storage', checkLang)

		return () => {
			window.removeEventListener('storage', checkLang)
		}
  }, []);

  useEffect(()  => {		
		if(localStorage.getItem('lang')) {
			setLang(JSON.parse(localStorage.getItem('lang')));
		} else {
			setLang(0);
		}		
    window.dispatchEvent(new Event("openModal"));	
    window.dispatchEvent(new Event("newRoute"));
  }, []);

  const [nome, setNome] = useState('');
  const [telefone, setTelefone] = useState('');
  const [email, setEmail] = useState('');
  const [assunto] = useState('Rainforest Alliance');
  const [mensagem, setMensagem] = useState('');

  const maskTel = (v) => {
    v = v.replace(/\D/g, "")

    v = v.replace(/(\d{2})(\d)/,"($1) $2")
    v = v.replace(/(\d)(\d{4})$/,"$1-$2")

    setTelefone(v)
  }

  const sendEmail = async (e) => {
    e.preventDefault();

    if(nome === '' || telefone === '' || email === '' || assunto === '' || mensagem === ''){
      const alertMessage = lang ? "Please fill in all fields" : "Por favor preencha todos os campos";
      alert(alertMessage);
      return;
    }

    var destinatario = 'comercial@savassicertificadora.com.br,fonteboag@savassicertificadora.com.br,dantasr@savassicertificadora.com.br';

    const templateParams = {
      origemMensagem: "Novo pedido de orçamento para Rainforest Alliance",
      nome: nome,
      telefone: telefone,
      email: email,
      assunto: assunto,
      mensagem: mensagem,
      destinatario: destinatario
    }

    window.dispatchEvent(new Event("closeModal"));
    setNome('');
    setTelefone('');
    setEmail('');
    setMensagem('');

    let response = await fetch("/contato", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8"
      },
      body: JSON.stringify(templateParams)
    })

    let result = await response.json();

    if(result.status==="200 OK") {
      console.log("EMAIL ENVIADO", response.status, response.text);
      alert(lang ? 'Budget request sent successfully!' : 'Solicitação de orçamento enviada com sucesso!')
    } else {
      console.log("ERRO: ", result.status);
    }

  }

  return(
    <div style={{backgroundColor:'#F7FBF5'}}>
      <div className="certificadorasubpages">
        <img className="certificadorasubpagesbanner" src={require("../../../../assets/images/rainfores-alliance-banner.png")} alt="banner"/><br/><br/>
        <p>
          {
            lang ?  
            "The Savassi Certification body has been accredited with the Rainforest Alliance 2020 (RA) Certification Program since April 14, 2021 to carry out compliance assessments of the Sustainable Agriculture Standard for agricultural production and supply chain clients." : 
            "O organismo Savassi Certificadora é credenciado junto ao Programa de Certificação Rainforest Alliance 2020 (RA) desde 14 de abril de 2021 para a realização da avaliação da conformidade do Padrão de Agricultura Sustentável para clientes de produção agrícola e cadeia de suprimento."
          }<br/><br/>
          {
            lang ?  
            "The Rainforest Alliance 2020 certification is governed by two documents, namely the 2020 Certification and Audit Rules and the Rainforest Alliance Rules for Certification Entities 2020 in the current version, which establishes the requirements for Certificate Holders and Certification Entities to become or remain part of of the Rainforest Alliance Certification Program." : 
            "A certificação Rainforest Alliance 2020 regida por dois documentos, sendo eles Regras de Certificação e Auditoria 2020 e Regras para Entidades Certificadoras 2020 da Rainforest Alliance em versão vigente, a qual estabelece os requisitos para Detentores de Certificado e Entidades Certificadoras a se tornarem ou permanecerem como parte do Programa de Certificação Rainforest Alliance."
          }<br/><br/>
          {
            lang ?  
            "Rainforest Alliance certification ensures that your agricultural practices are aligned with the highest standards of environmental, social and economic sustainability. This strengthens consumer confidence, who are increasingly concerned about the origin and quality of the products they consume." : 
            "A certificação Rainforest Alliance garante que suas práticas agrícolas estejam alinhadas com os mais altos padrões de sustentabilidade ambiental, social e econômica. Isso fortalece a confiança dos consumidores, que estão cada vez mais preocupados com a origem e qualidade dos produtos que consomem."
          }<br/><br/>
          {
            lang ?  
            "The Rainforest Alliance 2020 certification seal is an important recognition for products that meet strict environmental sustainability criteria, ensure respect for biodiversity, workers' rights and efficient management practices." : 
            "O selo de certificação Rainforest Alliance 2020 é um importante reconhecimento para produtos que atendem a rigorosos critérios de sustentabilidade ambiental, asseguram o respeito pela biodiversidade pelos direitos dos trabalhadores e por práticas de gestão eficientes."
          }<br/><br/>
          {
            lang ?  
            "Obtaining a Rainforest Alliance agricultural certification seal is key to boosting your competitive advantage as a rural producer. By adopting this recognition, you are opening the doors to continuous improvement of your internal processes, including traceability and efficient management. This results in significant benefits such as better farming and working conditions for your team and, in addition, monetary gains for delivering a reliable product to the market." : 
            "Obter um selo de certificação agrícola Rainforest Alliance é a chave para impulsionar a vantagem competitiva como produtor rural. Ao adotar esse reconhecimento, você está abrindo as portas à melhoria contínua dos seus processos internos, incluindo a rastreabilidade e uma gestão eficiente. Isso resulta em benefícios significativos como melhores condições de agricultura e de trabalho para sua equipe e, além disso, ganhos monetários por entregar um produto confiável ao mercado."
          }<br/><br/>
          {
            lang ?  
            "Additionally, certification provides new markets and commercial partnerships, as many buyers demand certified products to guarantee origin and sustainability. By obtaining this seal, you demonstrate your commitment to preserving the environment and protecting biodiversity. Not only will you be contributing to a more sustainable future, but you will also be strengthening your position as a leader in the agricultural sector." : 
            "Adicionalmente, a certificação proporciona novos mercados e parcerias comerciais, pois muitos compradores exigem produtos certificados para garantir a procedência e a sustentabilidade. Ao obter esse selo, você demonstra o seu compromisso em preservar o meio ambiente e a proteger a biodiversidade. Não apenas você estará contribuindo para um futuro mais sustentável, mas também estará fortalecendo sua posição como líder no setor agrícola."
          }<br/><br/>
          {
            lang ?  
            "Advantages for Producers: Access to different markets, product certification opens doors to demanding markets, which value sustainable products. This can result in better sales opportunities and more attractive prices." : 
            "Vantagens para os Produtores: Acesso a mercados diferentes, a certificação de produtos abre portas para mercados exigentes, que valorizam produtos sustentáveis. Isso pode resultar em melhores oportunidades de vendas e preços mais atrativos."
          }<br/><br/>
          {
            lang ?  
            "Crops included: Fruits, coffee, cocoa, flowers, herbs, tea, vegetables, nuts, among others." : 
            "Cultivos englobados: Frutas, café, cacau, flores, ervas, chá, vegetais, castanhas, entre outras."
          }
        </p><br/><br/>

        <div className="capacitacaodetalhes">
          <button id="myBtn">{ lang ? "Request a Quote" : "Solicitar Orçamento" }</button><br/><br/>
        </div>

        <div id="myModal" className="modal">
          <div className="modal-content">
            <div className="modal-header">
              <span className="close">&times;</span>
              <h2>{ lang ? "Request a Quote" : "Solicite um orçamento" }</h2>
            </div>
            <div className="modal-body">
              <form onSubmit={sendEmail}>
                <input type="text" name="nome" onChange={(e) => setNome(e.target.value)} value={nome} placeholder={ lang ? "Enter your name" : "Informe seu nome" }></input> <br/>
                <input type="tel" name="telefone" onChange={(e) => maskTel(e.target.value)} value={telefone} placeholder={ lang ? "Enter your phone" : "Informe seu telefone" }></input> <br/>
                <input type="email" name="email" onChange={(e) => setEmail(e.target.value)} value={email} placeholder={ lang ? "Enter your e-mail" : "Informe seu e-mail" }></input> <br/>
                <textarea name="mensagem" rows="5" onChange={(e) => setMensagem(e.target.value)} value={mensagem} placeholder={ lang ? "Enter your message" : "Informe sua mensagem" }></textarea> <br/>
                <input id="modalBtn" type="submit" name="enviar" value={ lang ? "Send" : "Enviar" }></input> <br/>
              </form>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default RainforestAlliance;