import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useState, useEffect } from 'react';

function CalibracaoQGrader(){

	const [lang, setLang] = useState();

	useEffect(()  => {
		const checkLang = function() {
			if(localStorage.getItem('lang')) {
				setLang(JSON.parse(localStorage.getItem('lang')));
			} else {
				setLang(0);
			}
		}

		window.addEventListener('storage', checkLang)

		return () => {
			window.removeEventListener('storage', checkLang)
		}
  }, []);

  useEffect(()  => {		
		if(localStorage.getItem('lang')) {
			setLang(JSON.parse(localStorage.getItem('lang')));
		} else {
			setLang(0);
		}
    window.dispatchEvent(new Event("openModal"));	
    window.dispatchEvent(new Event("newRoute"));
  }, []);

  const [curso] = useState('Calibração Q-Grader');
  const [nome, setNome] = useState('');
  const [nomeCracha, setNomeCracha] = useState('');
  const [endereco, setEndereco] = useState('');
  const [numero, setNumero] = useState('');
  const [bairro, setBairro] = useState('');
  const [cep, setCep] = useState('');
  const [cidadeEstado, setCidadeEstado] = useState('');
  const [telefone, setTelefone] = useState('');
  const [email, setEmail] = useState('');
  const [telefone2, setTelefone2] = useState('');
  const [rg, setRg] = useState('');
  const [cpf, setCpf] = useState('');
  const [dataNascimento, setDataNascimento] = useState('');
  const [escolaridade, setEscolaridade] = useState('');
  const [razaoSocial, setRazaoSocial] = useState('');
  const [enderecoCnpj, setEnderecoCnpj] = useState('');
  const [numeroCnpj, setNumeroCnpj] = useState('');
  const [bairroCnpj, setBairroCnpj] = useState('');
  const [cidadeEstadoCnpj, setCidadeEstadoCnpj] = useState('');
  const [telefoneCnpj, setTelefoneCnpj] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [inscricaoEstadual, setInscricaoEstadual] = useState('');
  const [formaPagamento, setFormaPagamento] = useState('');
  const [responsavelPagamento, setResponsavelPagamento] = useState('');

  const handleTel1Input = (v) => {
    v = maskTel(v)
    setTelefone(v)
  }

  const handleTel2Input = (v) => {
    v = maskTel(v)
    setTelefone2(v)
  }

  const handleTelCnpjInput = (v) => {
    v = maskTel(v)
    setTelefoneCnpj(v)
  }

  const maskTel = (v) => {
    v = v.replace(/\D/g, "")

    v = v.replace(/(\d{2})(\d)/,"($1) $2")
    v = v.replace(/(\d)(\d{4})$/,"$1-$2")

    return v
  }

  const maskData = (v) => {
    v = v.replace(/\D/g, "")

    v = v.replace(/(\d{2})(\d)/,"$1/$2") 
    v = v.replace(/(\d{2})(\d)/,"$1/$2") 

    setDataNascimento(v)
  }

  const maskCep = (v) => {
    v = v.replace(/\D/g, "")

    v = v.replace(/\D/g,'')
    v = v.replace(/(\d{5})(\d)/,'$1-$2')

    setCep(v)

  }

  const maskCpf = (v) => {
    v = v.replace(/\D/g, "")
  
    v = v.replace(/(\d{3})(\d)/, "$1.$2")
    v = v.replace(/(\d{3})(\d)/, "$1.$2")
    v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2")

    setCpf(v)

  }

  const maskCnpj = (v) => {
    v = v.replace(/\D/g, "")

    v = v.replace(/^(\d{2})(\d)/, "$1.$2")
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
    v = v.replace(/\.(\d{3})(\d)/, ".$1/$2")
    v = v.replace(/(\d{4})(\d)/, "$1-$2")
    
    setCnpj(v)

  }

  const sendEmail = async (e) => {
    e.preventDefault();

    if(curso === '' || nome === '' || nomeCracha === '' || endereco === '' || numero === '' || bairro === '' || 
    cep === '' || cidadeEstado === '' || telefone === '' || email === '' || rg === '' || cpf === '' || 
    dataNascimento === '' || escolaridade === '' || formaPagamento === '' || responsavelPagamento === ''){
      const alertMessage = lang ? "Please, fill in all fields with an asterisk" : "Por favor, preencha todos os campos com asterisco";
      alert(alertMessage);
      return;
    }

    var destinatario = "cursos@savassiagronegocio.com.br,comercial@savassicertificadora.com.br,fonteboag@savassicertificadora.com.br,dantasr@savassicertificadora.com.br";

    const templateParams = {
      destinatario: destinatario,
      origemMensagem: "Novo pedido de matrícula no curso Calibração Q-Grader",
      curso: curso,
      nome: nome,
      nomeCracha: nomeCracha,
      endereco: endereco,
      numero: numero,
      bairro: bairro,
      cep: cep,
      cidadeEstado: cidadeEstado,
      telefone: telefone,
      email: email,
      telefone2: telefone2,
      rg: rg,
      cpf: cpf,
      dataNascimento: dataNascimento,
      escolaridade: escolaridade,
      razaoSocial: razaoSocial,
      enderecoCnpj: enderecoCnpj,
      numeroCnpj: numeroCnpj,
      bairroCnpj: bairroCnpj,
      cidadeEstadoCnpj: cidadeEstadoCnpj,
      telefoneCnpj: telefoneCnpj,
      cnpj: cnpj,
      inscricaoEstadual: inscricaoEstadual,
      valor: 'US$550,00',
      formaPagamento: formaPagamento,
      responsavelPagamento: responsavelPagamento
    }

    window.dispatchEvent(new Event("closeModal"));
    setNome('');
    setNomeCracha('');
    setEndereco('');
    setNumero('');
    setBairro('');
    setCep('');
    setCidadeEstado('');
    setTelefone('');
    setEmail('');
    setTelefone2('');
    setRg('');
    setCpf('');
    setDataNascimento('');
    setEscolaridade('');
    setRazaoSocial('');
    setEnderecoCnpj('');
    setNumeroCnpj('');
    setBairroCnpj('');
    setCidadeEstadoCnpj('');
    setTelefoneCnpj('');
    setCnpj('');
    setInscricaoEstadual('');
    setFormaPagamento('');
    setResponsavelPagamento('');

    let response = await fetch("/cursos", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8"
      },
      body: JSON.stringify(templateParams)
    })

    let result = await response.json();

    if(result.status==="200 OK") {
      console.log("EMAIL ENVIADO", response.status, response.text);
      alert(lang ? 'Enrollment sent successfully!' : 'Matrícula enviada com sucesso!')
    } else {
      console.log("ERRO: ", result.status);
    }

  }

  return(
    <div style={{backgroundColor:'#F7FBF5'}}>
      <div className="capacitacaosubpages">
        <img className="capacitacaosubpagesbanner" src={ lang ? require("../../../../assets/images/banner-calibração-qgrader-ing.png") : require("../../../../assets/images/banner-calibração-qgrader.png") } alt="banner"/><br/><br/>
        <h2>{ lang ? "The Course" : "O Curso" }</h2>
        <p>
          {
            lang ?  
            "The Calibration Test is required for those Q Graders whose licenses are about to expire or have already expired. Please refer to the Graduate Q Calibration Schedule 4.2/Q Protocol." : 
            "O Teste de Calibração é necessário para aqueles Q Grader cujas licenças estão prestes a expirar  ou já expiraram. Por favor, consulte a Agenda de Calibração Q da Graduação 4.2/Protocolo Q."
          }<br/><br/>
          {
            lang ?  
            "Q Grader Licenses will be reinstated and reissued for all Q Graders who pass the Calibration test. A Q Grader Calibration course must be completed within 1 year of the certificate expiry (6 months before, and 6 months after, the Q certificate expiry date). Unless written consent is given by CQI, Q Grader may not participate in a Q Grader calibration before or after the permitted period of time. Q Graders who do not pass the Calibration, the Retake of that calibration, or fail all three cupping sessions in a calibration course, will be required to take a Q Grader Recertification Test." : 
            "Licenças Q Grader serão reintegradas e reeditadas para todos os Q Grader que passarem no  teste de Calibração. Um curso de Q Grader Calibração deve ser completado dentro de 1 ano da expiração do certificado (6 meses antes, e 6 meses depois, a data da expiração do certificado  Q). A menos que o consentimento por escrito seja dado pelo CQI, Q Grader pode não participar  de uma calibração de Q Grader antes ou depois do período de tempo permitido. Os Q Grader que não passarem na Calibração, o Retake dessa calibração ou falharem nas três sessões de cupping em um curso de calibração, serão obrigados a fazer um Teste de Recertificação Q  Grader."
          }<br/><br/>
          {
            lang ?  
            "Calibration is necessary first and foremost as an update to all of the improvements that CQI frequently makes to the Q Program. Additionally, when Q Graders are not tasting frequently, and their taste sensitivity and attribute ratings become misaligned relative to to the quality of the coffees they are presented in comparison with other tasters, calibration is necessary." : 
            "A calibração é necessária, em primeiro lugar, como uma atualização de todas as melhorias que  o CQI faz com frequência ao Programa Q. Além disso, quando os Q Grader não estão degustando frequentemente, e sua sensibilidade gustativa e classificação de atributos se desalinham em relação à qualidade dos cafés, eles são apresentados em comparação com  outros degustadores, a calibração é necessária."
          }<br/><br/>
          {
            lang ?  
            "A calibration activity reestablishes the parameters of how to identify various quality levels in coffee, ranging from outstanding to very good (special) and even commercial coffees. The activity will consist of three tasting sessions." : 
            "Uma atividade de calibração restabelece os parâmetros de como identificar vários níveis de qualidade no café, variando de pendentes a muito bons (especial) e até cafés comerciais. A atividade consistirá em três sessões de degustação."
          }<br/><br/>
          {
            lang ?  
            "During the Calibration Test, results from all tasters are entered into a spreadsheet to assess their ability to grade coffee attributes compared to other tasters, similar to the method in which they were evaluated in the Q Grader test, however, the allowed deviation is more restricted, as Q Graders are now considered more experienced. The spreadsheet indicates whether the cup is calibrated or not, based on the quality of each of the coffees on the table." : 
            "Durante o Teste de Calibração, os resultados de todos os degustadores são inseridos em uma planilha para avaliar sua capacidade de classificar os atributos do café em comparação com os outros degustadores, semelhante ao método em que foram avaliados no teste Q Grader, no entanto, o desvio permitido é mais restrito, já que os Q Grader agora são considerados mais experientes. A planilha indica se a xícara está em calibração ou não, com base na qualidade de cada um dos cafés na mesa."
          }<br/><br/>
          {
            lang ?  
            "At the same time, during this calibration session, the tasters will also analyze the cupping protocols / standards for a Q assessment, including: the silent work rule, objective assessment of varying degrees of coffee, tasting protocols, coffee classification standards green and roasting / quaker tests according to SCA protocols." : 
            "Ao mesmo tempo, durante esta sessão de calibração, os degustadores também analisarão os protocolos/padrões de cupping para uma avaliação Q, incluindo: a regra do trabalho silencioso, avaliação objetiva de graus variados de cafés, protocolos de degustação, padrões de classificação de café verde e testes de torrefação/quaker de acordo com os protocolos SCA."
          }
        </p><br/>
        <div className="capacitacaodetalhes">
          <h2>{ lang ? "Investiment" : "Investimento" }</h2>
          <h2 id="money">US$ 550,00</h2><br/>
          <p><b>
            { 
              lang ? 
              "Course price: US$ 550.00 (Tourism Dollar)" : 
              "Preço do curso: US$ 550,00 (Dólar Turismo)"
            }<br/><br/>
            { 
              lang ? 
              "Cash via PIX or Cash: US$ 500.00" : 
              "À vista via PIX ou Dinheiro: US$ 500,00"
            }<br/><br/>
            { 
              lang ? 
              "Up to 3x installments on Credit Card: US$ 500.00" : 
              "Parcelado no Cartão de Crédito em até 3x vezes: US$ 500,00"
            }<br/><br/>
            { 
              lang ? 
              "Installments on the Credit Card in up to 10x (interest on the card itself)" : 
              "Parcelado no Cartão de Crédito em até 10x vezes (juros do próprio cartão)"
            }<br/><br/>
            { 
              lang ? 
              "Note: The dollar value is quoted on the day of payment, the value can be up or down" : 
              "Observação: O valor do dólar é cotado no dia do pagamento, o valor pode ser para mais ou para menos"
            }<br/><br/>
          </b></p>
          <button id="myBtn">{ lang ? "Enroll" : "Matricular" }</button><br/><br/>
        </div><br/>

        <div id="myModal" className="modal">
          <div className="modal-content">
            <div className="modal-header">
              <span className="close">&times;</span>
              <h2>{ lang ? "Enroll now!" : "Matricule-se agora!" }</h2>
            </div>
            <div className="modal-body">
              <form onSubmit={sendEmail}>
                <span><strong>{ lang ? "CONTRACTOR'S Personal Data" : "Dados Pessoais do CONTRATANTE" }</strong></span>
                <input type="text" name="curso" disabled value={curso}></input> <br/>
                <input type="text" name="nome" onChange={(e) => setNome(e.target.value)} value={nome} placeholder={ lang ? "Full name*" : "Nome completo*" }></input> <br/>
                <input type="text" name="nomeCracha" onChange={(e) => setNomeCracha(e.target.value)} value={nomeCracha} placeholder={ lang ? "Name highlighted on badge*" : "Nome em destaque no crachá*" }></input> <br/>
                <input type="text" name="endereco" onChange={(e) => setEndereco(e.target.value)} value={endereco} placeholder={ lang ? "Address*" : "Endereço*" }></input> <br/>
                <input type="text" name="numero" onChange={(e) => setNumero(e.target.value)} value={numero} placeholder={ lang ? "Address number*" : "Número*" }></input> <br/>
                <input type="text" name="bairro" onChange={(e) => setBairro(e.target.value)} value={bairro} placeholder={ lang ? "Neighbourhood*" : "Bairro*" }></input> <br/>
                <input type="text" name="cep" onChange={(e) => maskCep(e.target.value)} value={cep} placeholder={ lang ? "Postal Code*" : "CEP*" }></input> <br/>
                <input type="text" name="cidadeEstado" onChange={(e) => setCidadeEstado(e.target.value)} value={cidadeEstado} placeholder={ lang ? "City and State*" : "Cidade e Estado*" }></input> <br/>
                <input type="tel" name="telefone" onChange={(e) => handleTel1Input(e.target.value)} value={telefone} placeholder={ lang ? "Phone number*" : "Telefone*" }></input> <br/>
                <input type="email" name="email" onChange={(e) => setEmail(e.target.value)} value={email} placeholder={ lang ? "E-mail*" : "E-mail*" }></input> <br/>
                <input type="tel" name="telefone2" onChange={(e) => handleTel2Input(e.target.value)} value={telefone2} placeholder={ lang ? "Phone number of a relative or someone close" : "Telefone de um parente ou alguém próximo" }></input> <br/>
                <input type="text" name="rg" onChange={(e) => setRg(e.target.value)} value={rg} placeholder={ lang ? "RG*" : "RG*" }></input> <br/>
                <input type="text" name="cpf" onChange={(e) => maskCpf(e.target.value)} value={cpf} placeholder={ lang ? "CPF*" : "CPF*" }></input> <br/>
                <input type="text" name="dataNascimento" onChange={(e) => maskData(e.target.value)} value={dataNascimento} placeholder={ lang ? "Birth Date*" : "Data de Nascimento*" }></input> <br/>
                <input type="text" name="escolaridade" onChange={(e) => setEscolaridade(e.target.value)} value={escolaridade} placeholder={ lang ? "Education*" : "Escolaridade*" }></input> <br/>
                <span><strong>{ lang ? "Company Data - for LEGAL ENTITY" : "Dados da Empresa - para PESSOA JURÍDICA" }</strong></span>
                <input type="text" name="razaoSocial" onChange={(e) => setRazaoSocial(e.target.value)} value={razaoSocial} placeholder={ lang ? "Corporate name" : "Razão Social" }></input> <br/>
                <input type="text" name="enderecoCnpj" onChange={(e) => setEnderecoCnpj(e.target.value)} value={enderecoCnpj} placeholder={ lang ? "Address" : "Endereço" }></input> <br/>
                <input type="text" name="numeroCnpj" onChange={(e) => setNumeroCnpj(e.target.value)} value={numeroCnpj} placeholder={ lang ? "Address number" : "Número" }></input> <br/>
                <input type="text" name="bairroCnpj" onChange={(e) => setBairroCnpj(e.target.value)} value={bairroCnpj} placeholder={ lang ? "Neighbourhood" : "Bairro" }></input> <br/>
                <input type="text" name="cidadeEstadoCnpj" onChange={(e) => setCidadeEstadoCnpj(e.target.value)} value={cidadeEstadoCnpj} placeholder={ lang ? "City and State" : "Cidade e Estado" }></input> <br/>
                <input type="tel" name="telefoneCnpj" onChange={(e) => handleTelCnpjInput(e.target.value)} value={telefoneCnpj} placeholder={ lang ? "Phone number" : "Telefone" }></input> <br/>
                <input type="text" name="cnpj" onChange={(e) => maskCnpj(e.target.value)} value={cnpj} placeholder={ lang ? "CNPJ" : "CNPJ" }></input> <br/>
                <input type="text" name="inscricaoEstadual" onChange={(e) => setInscricaoEstadual(e.target.value)} value={inscricaoEstadual} placeholder={ lang ? "State registration" : "Inscrição Estadual" }></input> <br/>
                <span><strong>{ lang ? "Payment" : "Pagamento" }</strong></span>
                <input type="text" name="valor" disabled value='US$550,00'></input> <br/>
                <input type="text" name="formaPagamento" onChange={(e) => setFormaPagamento(e.target.value)} value={formaPagamento} placeholder={ lang ? "Payment Method*" : "Forma de Pagamento*" }></input> <br/>
                <input type="text" name="responsavelPagamento" onChange={(e) => setResponsavelPagamento(e.target.value)} value={responsavelPagamento} placeholder={ lang ? "Responsible for payment*" : "Responsável pelo pagamento*" }></input> <br/>
                <input id="modalBtn" type="submit" name="enviar" value={ lang ? "Send" : "Enviar" }></input> <br/>
              </form>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>

        <Tabs className="capacitacaotabs">
          <TabList>
            <Tab>{ lang ? "Methodology" : "Metodologia" }</Tab>
            <Tab>{ lang ? "Activities" : "Atividades" }</Tab>
            <Tab>{ lang ? "Target Audience" : "Público-Alvo" }</Tab>
            <Tab>{ lang ? "Workload" : "Carga-Horária" }</Tab>
            <Tab>{ lang ? "Where to Stay" : "Onde Hospedar" }</Tab>
          </TabList>

          <TabPanel className="capacitacaotabpanel">
            <br/>
            <h4>{lang ? "Tasting and scoring protocols:" : "Protocolos de degustação e pontuação:"}</h4><br/>
            <p>
              { 
                lang ? 
                "a. Coffees must be brewed in accordance with the SCA Cupping protocol and scores recorded on the SCA cupping form." : 
                "a. Os cafés devem ser colocados de acordo com o protocolo SCA Cupping e as pontuações registadas no formulário de degustação SCA."
              }<br/><br/>
              { 
                lang ? 
                "b. Each Q Grader's completion data is entered into the calibration test score sheet, which automatically calculates the Q grader's pass/fail scores." : 
                "b. Os dados de preenchimento de cada Q Grader são inseridos na folha de pontuação de teste de calibração, que calcula automaticamente as pontuações de aprovação / reprovação dos Q grader."
              }<br/><br/>
              { 
                lang ? 
                "c. Students must receive a passing grade of 86 or higher, and accurately report defects in two (2) of three (3) cupping sessions, as well as show proper use of the cupping form at all times, in order to renew your Q license." : 
                "c. Os alunos devem receber uma nota de aprovação de 86 ou mais, e relatar defeitos com precisão em duas (2) das três (3) sessões de cupping, bem como mostrar o uso adequado do formulário de degustação em todos os momentos, a fim de renovar sua licença Q."
              }<br/><br/>
              { 
                lang ? 
                "d. A valid Q Grader license is required, which is determined by appearing on the Q Grader list in the CQI Database to remain eligible to grade coffees for an established ICP (In-Country-Partner)." : 
                "d. Uma licença válida Q Grader é exigida, a qual é determinada por aparecer na lista do Q Grader no Banco de Dados do CQI para continuar apto a classificar cafés para um ICP (In-Country-Partner) estabelecido."
              }<br/><br/>
              { 
                lang ? 
                "e. Q Graders who do not pass the Calibration test may retake one (1) time within a 6-month period after calibration to renew their Q Grader license." : 
                "e. Os Q Grader que não passarem no teste de Calibração podem retomar uma (1) vez em um período de 6 meses após a calibração para renovar sua licença da Q Grader."
              }<br/><br/>
              { 
                lang ? 
                "f. Q Graders who do not pass the Calibration Test will retake the entire set of calibration tests once, unless they failed all three cupping sessions in a calibration course. If they fail all three cupping sessions in a calibration course or do not pass the Cupping Test Retake, they must complete the Recertification Test." : 
                "f. Os Q Grader que não passarem no Teste de Calibração retomarão todo o conjunto de testes de calibração uma vez, a menos que tenham reprovado todas as três sessões de cupping em um curso de calibração. Se eles falharem em todas as três sessões de cupping em um curso de calibração ou não passarem no Retake dos testes de cupping, eles deverão completar o Teste de Recertificação."
              }<br/><br/>
            </p>
          </TabPanel>
          <TabPanel className="capacitacaotabpanel">
            <br/>
            <p>
              { 
                lang ? 
                "The Calibration Test is a one-day (1 day) exercise conducted by a certified Q Instructor in which coffees are placed in three (3) separate sessions of six (6) samples each (cupping session):" : 
                "O Teste de Calibração é um exercício de um dia (1 dia) conduzido por um Instrutor Q certificado em que os cafés são colocados em três (3) sessões separadas de seis (6) amostras cada (sessão de cupping):"
              }<br/><br/>
              { 
                lang ? 
                "a. One (1) of the sessions must contain an international blend of Washed Milds, primarily from Central and South America. These coffees vary in quality." : 
                "a. Uma (1) das sessões deve conter uma mistura internacional de Suaves Lavados, principalmente da América Central e América do Sul. Esses cafés variam em qualidade."
              }<br/><br/>
              { 
                lang ? 
                "b. One (1) of the sessions must contain coffees from Africa or Asia. These coffees vary in quality." : 
                "b. Uma (1) das sessões deve conter cafés da África ou da Ásia. Estes cafés variam em qualidade."
              }<br/><br/>
              { 
                lang ? 
                "c. One (1) of the sessions must contain an international blend of Natural processed coffees. These coffees vary in quality." : 
                "c. Uma (1) das sessões deve conter uma mistura internacional de cafés processados Natural. Esses cafés variam em qualidade."
              }<br/><br/>
            </p>
          </TabPanel>
          <TabPanel className="capacitacaotabpanel">
            <br/>
            <span>Q Graders</span>
            <br/><br/>
          </TabPanel>
          <TabPanel className="capacitacaotabpanel">
            <br/>
            <span>{ lang ? "08 hours" : "08 horas" }</span>
            <br/><br/>
          </TabPanel>
          <TabPanel className="capacitacaotabpanel">
            <br/>
            <h4>{ lang ? "Partner Hotels:" : "Hotéis Parceiros:" }</h4>
            <Link to="https://www.ourocerradohotel.com.br/" target="_blank">Ouro Cerrado Hotel</Link><br/>
            <Link to="http://www.minashotel.com.br/" target="_blank">Minas Hotel</Link><br/>
            <span>Center Hotel</span><br/>
            <span>Guines Hotel</span><br/>
            <span>Grande Avenida</span><br/><br/>
            <h4>{ lang ? "Other Hotels:" : "Outros Hotéis:" }</h4>
            <Link to="http://bit.ly/2sISAXG" target="_blank">{ lang ? "Patrocínio hotels in Trivago" : "Hotéis de Patrocínio em Trivago" }</Link><br/>
            <br/>
          </TabPanel>
        </Tabs><br/>
      </div>
    </div>
  )
}

export default CalibracaoQGrader;