import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import React from 'react';

function Header(){

  const [displayMobileNav, setDisplayMobileNav] = useState("none");
  const [lang, setLang] = useState();

  const [cpfOuCnpj, setCpfOuCnpj] = useState('');

  const mask = (v) => {
    v = v.replace(/\D/g, "")
  
    if (v.length <= 11) {
      v = v.replace(/(\d{3})(\d)/, "$1.$2")
      v = v.replace(/(\d{3})(\d)/, "$1.$2")
      v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
    } else {
      v = v.replace(/^(\d{2})(\d)/, "$1.$2")
      v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
      v = v.replace(/\.(\d{3})(\d)/, ".$1/$2")
      v = v.replace(/(\d{4})(\d)/, "$1-$2")
    }

    setCpfOuCnpj(v);
  }

  useEffect(() => {
    if(localStorage.getItem('lang')) {
      setLang(JSON.parse(localStorage.getItem('lang')));
    } else {
      setLang(0);
      localStorage.setItem('lang', JSON.stringify(0));
    }
    window.dispatchEvent(new Event("openModalRastreio"));
  }, []);

  const idioma = function(value) {
    setLang(value);
    localStorage.setItem('lang', JSON.stringify(value));
    window.dispatchEvent(new Event("storage"));
  };

  const handleClickDisplay = () => {
    if(displayMobileNav!=="none") {
      setDisplayMobileNav("none");
    } else {
      setDisplayMobileNav("block");
    }
  }

  const resetDisplayMobileNav = () => setDisplayMobileNav("none");
  
  useEffect(() => {
    window.addEventListener('resize', resetDisplayMobileNav);
    window.addEventListener('newRoute', resetDisplayMobileNav);
  
    return () => {
      window.removeEventListener('resize', resetDisplayMobileNav);
      window.removeEventListener('newRoute', resetDisplayMobileNav);
    }
  }, [])

  /* eslint-disable */
  return(
    <header>
			<div style={{position:'relative'}}>
        <nav className="mainnav">
					<ul>
							<li><Link to='/'><img src={require("../../assets/images/logo-t.png")} alt="logo"/></Link></li>
							<li><Link to='/'><h1>HOME</h1></Link></li>
							<li><Link to='/certificadora'><h1>{lang ? "CERTIFICATION" : "CERTIFICADORA"}</h1></Link></li>
							<li><Link to='/capacitacao'><h1>{lang ? "TRAINING" : "CAPACITAÇÃO"}</h1></Link></li>
							<li ><Link to='/analise-tecnica'><h1>{lang ? "TECHNICAL ANALYSIS" : "ANÁLISE TÉCNICA"}</h1></Link></li>
              <li className="rastreio">
                <form action="https://savassicertificadora.com.br/rastreio/login/" method="POST" target="_blank">
                  <table>
                    <tbody>
                      <tr>
                        <td colSpan={3}><label className="rastreio-label">{lang ? "Process Tracking" : "Rastreio de Processo"}</label></td>
                      </tr>
                      <tr>
                        <td>
                          <input style={{width:'calc(70px + 2.4vw)', height:'calc(15px + 1vw)', fontSize:'calc(8px + 0.2vw)'}} type="text" name="cpf" onChange={(e) => mask(e.target.value)} value={cpfOuCnpj} placeholder="CPF/CNPJ"/>
                        </td>
                        <td>
                          <input style={{width:'calc(70px + 2.4vw)', height:'calc(15px + 1vw)', fontSize:'calc(8px + 0.2vw)'}} type="text" name="cod_acesso" placeholder={lang ? "access code" : "código de acesso"}/>
                        </td>
                        <td>
                          <input className="botao-rastreio" type="submit" value={lang ? "LOGIN" : "ENTRAR"}/>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </form>
							</li>
              <li>
                <div className="idioma-wrapper">
                  <img className="idioma-img" onClick={() => idioma(0)} src={require("../../assets/images/br2.png")} alt={ lang ? "Brazil flag" : "Bandeira do Brasil"}></img>
                  <img className="idioma-img" onClick={() => idioma(1)} src={require("../../assets/images/us2.png")} alt={ lang ? "US flag" : "Bandeira dos Estados Unidos"}></img>
                </div>
              </li>
					</ul>
        </nav>
        <div className="topnav">
          <div>
            <nav className="mainnavmobile">
              <div className="logo-wrapper-mobile">
                <li>
                  <Link style={{float:'left'}} to='/'><img style={{width:'calc(10px+15vw)'}} src={require("../../assets/images/logo-t.png")} alt="logo"/></Link>
                </li>
              </div>
              <div className="idioma-wrapper-mobile">
                <li>
                  <div>
                    <img style={{float:'left', marginRight: '3px'}} className="idioma-img" onClick={() => idioma(0)} src={require("../../assets/images/br2.png")} alt={ lang ? "Brazil flag" : "Bandeira do Brasil"}></img>
                    <img style={{float:'right', marginLeft: '3px'}} className="idioma-img" onClick={() => idioma(1)} src={require("../../assets/images/us2.png")} alt={ lang ? "US flag" : "Bandeira dos Estados Unidos"}></img>
                  </div>
                </li>
              </div>
            </nav>
          </div>
          <div id="myLinks" style={{display: displayMobileNav}}>
            <nav>
              <ul><Link to='/certificadora'><h1>{lang ? "CERTIFICATION" : "CERTIFICADORA"}</h1></Link></ul>
              <hr/>
              <ul><Link to='/capacitacao'><h1>{lang ? "TRAINING" : "CAPACITAÇÃO"}</h1></Link></ul>
              <hr/>
              <ul ><Link to='/analise-tecnica'><h1>{lang ? "TECHNICAL ANALYSIS" : "ANÁLISE TÉCNICA"}</h1></Link></ul>
              <hr/>
              <ul className="rastreiomobile">
                <button id="myBtnRastreio" className="botao-rastreio-mobile">{lang ? "Process Tracking" : "Rastreio de Processo"}</button>
              </ul>
            </nav>
          </div>
          <a className="icon" onClick={handleClickDisplay}>
            <i className="fa fa-bars"></i>
          </a>
        </div>
			</div>
			<div className="secondnav">
				<nav>
					<ul>
						<li><Link to='/quem-somos'><h3>{lang ? "About Us" : "Quem Somos"}</h3></Link></li>
						<li><Link to='/documentos'><h3>{lang ? "Documents" : "Documentos"}</h3></Link></li>
						<li><Link to='/ouvidoria'><h3>{lang ? "Ombudsman" : "Ouvidoria"}</h3></Link></li>
						<li><Link to='/fale-conosco'><h3>{lang ? "Contact Us" : "Fale Conosco"}</h3></Link></li>
            <li className="blog"><Link to='https://savassicertificadora.com.br/blog/' target="_blank"><h3>{lang ? "Savassi's Blog" : "Blog da Savassi"}</h3></Link></li>
					</ul>
        </nav>
			</div>

      <div id="myModalRastreio" className="modal">
        <div className="modal-content">
          <div className="modal-header">
            <span className="closeRastreio">&times;</span>
            <h2>{ lang ? "Process Tracking" : "Rastreio de Processo" }</h2>
          </div>
          <div className="modal-body">
            <form id="formRastreio" action="https://savassicertificadora.com.br/rastreio/login/" method="POST" target="_blank">
              <input type="text" name="cpf" placeholder="CPF/CNPJ" onChange={(e) => mask(e.target.value)} value={cpfOuCnpj}/> <br/>
              <input type="text" name="cod_acesso" placeholder={lang ? "access code" : "código de acesso"}/> <br/>
              <input className="botao-rastreio" type="submit" value={lang ? "LOGIN" : "ENTRAR"}/>
            </form>
          </div>
          <div className="modal-footer"></div>
        </div>
      </div>

    </header>
  )
}

export default Header;