import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useState, useEffect } from 'react';

function CDC(){

  const [lang, setLang] = useState();

	useEffect(()  => {
		const checkLang = function() {
			if(localStorage.getItem('lang')) {
				setLang(JSON.parse(localStorage.getItem('lang')));
			} else {
				setLang(0);
			}
		}

		window.addEventListener('storage', checkLang)

		return () => {
			window.removeEventListener('storage', checkLang)
		}
  }, []);

  useEffect(()  => {		
		if(localStorage.getItem('lang')) {
			setLang(JSON.parse(localStorage.getItem('lang')));
		} else {
			setLang(0);
		}
    window.dispatchEvent(new Event("openModalCdcPmt"));
    window.dispatchEvent(new Event("newRoute"));
    window.dispatchEvent(new Event("resize"));
  }, []);

  const [curso] = useState('Classificação e Degustação de Café - CDC');
  const [nome, setNome] = useState('');
  const [nomeCracha, setNomeCracha] = useState('');
  const [endereco, setEndereco] = useState('');
  const [numero, setNumero] = useState('');
  const [bairro, setBairro] = useState('');
  const [cep, setCep] = useState('');
  const [cidadeEstado, setCidadeEstado] = useState('');
  const [telefone, setTelefone] = useState('');
  const [email, setEmail] = useState('');
  const [telefone2, setTelefone2] = useState('');
  const [rg, setRg] = useState('');
  const [cpf, setCpf] = useState('');
  const [dataNascimento, setDataNascimento] = useState('');
  const [escolaridade, setEscolaridade] = useState('');
  const [razaoSocial, setRazaoSocial] = useState('');
  const [enderecoCnpj, setEnderecoCnpj] = useState('');
  const [numeroCnpj, setNumeroCnpj] = useState('');
  const [bairroCnpj, setBairroCnpj] = useState('');
  const [cidadeEstadoCnpj, setCidadeEstadoCnpj] = useState('');
  const [telefoneCnpj, setTelefoneCnpj] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [inscricaoEstadual, setInscricaoEstadual] = useState('');
  const [formaPagamento, setFormaPagamento] = useState('');
  const [responsavelPagamento, setResponsavelPagamento] = useState('');

  const handleTel1Input = (v) => {
    v = maskTel(v)
    setTelefone(v)
  }

  const handleTel2Input = (v) => {
    v = maskTel(v)
    setTelefone2(v)
  }

  const handleTelCnpjInput = (v) => {
    v = maskTel(v)
    setTelefoneCnpj(v)
  }

  const maskTel = (v) => {
    v = v.replace(/\D/g, "")

    v = v.replace(/(\d{2})(\d)/,"($1) $2")
    v = v.replace(/(\d)(\d{4})$/,"$1-$2")

    return v
  }

  const maskData = (v) => {
    v = v.replace(/\D/g, "")

    v = v.replace(/(\d{2})(\d)/,"$1/$2") 
    v = v.replace(/(\d{2})(\d)/,"$1/$2") 

    setDataNascimento(v)
  }

  const maskCep = (v) => {
    v = v.replace(/\D/g, "")

    v = v.replace(/\D/g,'')
    v = v.replace(/(\d{5})(\d)/,'$1-$2')

    setCep(v)

  }

  const maskCpf = (v) => {
    v = v.replace(/\D/g, "")
  
    v = v.replace(/(\d{3})(\d)/, "$1.$2")
    v = v.replace(/(\d{3})(\d)/, "$1.$2")
    v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2")

    setCpf(v)

  }

  const maskCnpj = (v) => {
    v = v.replace(/\D/g, "")

    v = v.replace(/^(\d{2})(\d)/, "$1.$2")
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
    v = v.replace(/\.(\d{3})(\d)/, ".$1/$2")
    v = v.replace(/(\d{4})(\d)/, "$1-$2")
    
    setCnpj(v)

  }

  const sendEmail = async (e) => {
    e.preventDefault();

    if(curso === '' || nome === '' || nomeCracha === '' || endereco === '' || numero === '' || bairro === '' || 
    cep === '' || cidadeEstado === '' || telefone === '' || email === '' || rg === '' || cpf === '' || 
    dataNascimento === '' || escolaridade === '' || formaPagamento === '' || responsavelPagamento === ''){
      const alertMessage = lang ? "Please, fill in all fields with an asterisk" : "Por favor, preencha todos os campos com asterisco";
      alert(alertMessage);
      return;
    }

    var destinatario = "cursos@savassiagronegocio.com.br,comercial@savassicertificadora.com.br,fonteboag@savassicertificadora.com.br,dantasr@savassicertificadora.com.br";

    const templateParams = {
      destinatario: destinatario,
      origemMensagem: "Novo pedido de matrícula no curso Classificação e Degustação de Café - CDC",
      curso: curso,
      nome: nome,
      nomeCracha: nomeCracha,
      endereco: endereco,
      numero: numero,
      bairro: bairro,
      cep: cep,
      cidadeEstado: cidadeEstado,
      telefone: telefone,
      email: email,
      telefone2: telefone2,
      rg: rg,
      cpf: cpf,
      dataNascimento: dataNascimento,
      escolaridade: escolaridade,
      razaoSocial: razaoSocial,
      enderecoCnpj: enderecoCnpj,
      numeroCnpj: numeroCnpj,
      bairroCnpj: bairroCnpj,
      cidadeEstadoCnpj: cidadeEstadoCnpj,
      telefoneCnpj: telefoneCnpj,
      cnpj: cnpj,
      inscricaoEstadual: inscricaoEstadual,
      valor: 'R$2.300,00',
      formaPagamento: formaPagamento,
      responsavelPagamento: responsavelPagamento
    }

    window.dispatchEvent(new Event("closeModal"));
    setNome('');
    setNomeCracha('');
    setEndereco('');
    setNumero('');
    setBairro('');
    setCep('');
    setCidadeEstado('');
    setTelefone('');
    setEmail('');
    setTelefone2('');
    setRg('');
    setCpf('');
    setDataNascimento('');
    setEscolaridade('');
    setRazaoSocial('');
    setEnderecoCnpj('');
    setNumeroCnpj('');
    setBairroCnpj('');
    setCidadeEstadoCnpj('');
    setTelefoneCnpj('');
    setCnpj('');
    setInscricaoEstadual('');
    setFormaPagamento('');
    setResponsavelPagamento('');

    let response = await fetch("/cursos", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8"
      },
      body: JSON.stringify(templateParams)
    })

    let result = await response.json();

    if(result.status==="200 OK") {
      console.log("EMAIL ENVIADO", response.status, response.text);
      alert(lang ? 'Enrollment sent successfully!' : 'Matrícula enviada com sucesso!')
    } else {
      console.log("ERRO: ", result.status);
    }

  }

  return(
    <div style={{backgroundColor:'#F7FBF5'}}>
      <div className="capacitacaosubpages">
        <img className="capacitacaosubpagesbanner" src={ lang ? require("../../../../assets/images/banner-cdc-ing.png") : require("../../../../assets/images/banner-cdc.png") } alt="banner"/><br/><br/>
        <h2>{ lang ? "The Course" : "O Curso" }</h2>
        <h3>{ lang ? "Objectives" : "Objetivos" }</h3>
        <p>
          {
            lang ?  
            "Training Professionals for the coffee market. It is a course to train technical experts in tasting and classifying coffee, according to the COB methodology. Technicians will be qualified and trained to guarantee quality control in the purchase of raw materials, execution of blends, roasting and packaging processes. In addition to analyzing coffee samples for restaurants, hotels, cafes, supermarkets, among others. Disciplines will be taught on coffee classification, tasting and marketing, as well as notions of taste and smell and sample preparation. The course is essentially practical, with technical activities and daily practical classification and tasting." : 
            "Capacitar Profissionais para o mercado de café. É um curso para formar técnicos especialistas em degustação e classificação de café, de acordo com a metodologia COB. Os técnicos serão capacitados e treinados para garantir o controle de qualidade na compra de matéria prima, execução de blends, torrefação e processo de embalagem. Além da análise de amostras de café para restaurantes, hotéis, cafeterias, supermercados, entre outros. Serão ministradas disciplinas sobre classificação, degustação e comercialização de café, bem como noções de paladar e olfato e preparo de amostras. O curso é essencialmente prático, com atividades técnicas e práticas diárias de classificação e degustação."
          }
        </p><br/>
        <h3>{ lang ? "Specific Objectives" : "Objetivos Específicos" }</h3>
        <p>
          {
            lang ?  
            "Enable a broad identification and interpretation of the essential and basic concepts and principles of the role of \"Coffee classifier and taster\". It is necessary that the quality of our coffee is a personal requirement of each person involved in its production, processing, industrialization and export, and that improving our beverage standards is our daily objective, for export and intern market." : 
            "Possibilitar uma ampla identificação e interpretação dos conceitos e princípios essenciais e básicos da função de \"Classificador e degustador de Café\". Faz-se necessário que a qualidade do nosso café seja uma exigência pessoal de cada um dos envolvidos com sua produção, seu beneficiamento, sua industrialização e sua exportação, e que a melhoria de nossos padrões de bebidas seja nosso objetivo cotidiano, para a exportação e mercado interno."
          }
        </p><br/><br/>
        <div className="capacitacaodetalhes">
          <h2>{ lang ? "Investiment" : "Investimento" }</h2>
          <h2 id="money">{ lang ? "R$ 2.300,00" : "R$ 2.300,00" }</h2><br/>
          <p><b>
            { 
              lang ? 
              "Course price: R$ 2.300,00 (The dollar value varies according to the exchange rate of the day)" : 
              "Preço do curso: R$ 2.300,00"
            }<br/><br/>
            { 
              lang ? 
              "Cash via PIX or Cash: R$ 2.150,00" : 
              "À vista via PIX ou Dinheiro: R$ 2.150,00"
            }<br/><br/>
            { 
              lang ? 
              "Installments by Credit Card in up to 3 installments: R$ 2.300,00 WITHOUT INTEREST" : 
              "Parcelado no Cartão de Crédito em até 3x vezes: R$ 2.300,00 SEM JUROS"
            }<br/><br/>
          </b></p>
          <button id="myBtn">{ lang ? "Enroll" : "Matricular" }</button><br/><br/>
        </div>

        <div className="capacitacaosubpages-instrutores">
          <h2>{ lang ? "Instructors" : "Instrutores" }</h2>
          <div className="owl-carousel">
            <div className="carousel-item">
              <img id="foto1" className="direcaofoto" src={require("../../../../assets/images/direcao1.png")} alt="Ronaldo Dantas"/>
            </div>
            <div className="carousel-item">
              <img id="foto3" className="direcaofoto" src={require("../../../../assets/images/direcao3.png")} alt="Glaucio Lima Fonte Boa"/>
            </div>
          </div>
        </div><br/><br/><br/>

        <div className="capacitacaosubpages-instrutores">
          <h2>{ lang ? "Technical Assistants" : "Assistentes Técnicos" }</h2>
          <div className="owl-carousel">
            <div className="carousel-item">
              <img id="foto4" className="direcaofoto" src={require("../../../../assets/images/nivaldo.png")} alt="Nivaldo"/>
            </div>
            <div className="carousel-item">
              <img id="foto5" className="direcaofoto" src={require("../../../../assets/images/luis.png")} alt="Luis"/>
            </div>
          </div>
        </div><br/><br/>

        <div id="myModal" className="modal">
          <div className="modal-content">
            <div className="modal-header">
              <span className="close">&times;</span>
              <h2>{ lang ? "Enroll now!" : "Matricule-se agora!" }</h2>
            </div>
            <div className="modal-body">
              <form onSubmit={sendEmail}>
                <span><strong>{ lang ? "CONTRACTOR'S Personal Data" : "Dados Pessoais do CONTRATANTE" }</strong></span>
                <input type="text" name="curso" disabled value={curso}></input> <br/>
                <input type="text" name="nome" onChange={(e) => setNome(e.target.value)} value={nome} placeholder={ lang ? "Full name*" : "Nome completo*" }></input> <br/>
                <input type="text" name="nomeCracha" onChange={(e) => setNomeCracha(e.target.value)} value={nomeCracha} placeholder={ lang ? "Name highlighted on badge*" : "Nome em destaque no crachá*" }></input> <br/>
                <input type="text" name="endereco" onChange={(e) => setEndereco(e.target.value)} value={endereco} placeholder={ lang ? "Address*" : "Endereço*" }></input> <br/>
                <input type="text" name="numero" onChange={(e) => setNumero(e.target.value)} value={numero} placeholder={ lang ? "Address number*" : "Número*" }></input> <br/>
                <input type="text" name="bairro" onChange={(e) => setBairro(e.target.value)} value={bairro} placeholder={ lang ? "Neighbourhood*" : "Bairro*" }></input> <br/>
                <input type="text" name="cep" onChange={(e) => maskCep(e.target.value)} value={cep} placeholder={ lang ? "Postal Code*" : "CEP*" }></input> <br/>
                <input type="text" name="cidadeEstado" onChange={(e) => setCidadeEstado(e.target.value)} value={cidadeEstado} placeholder={ lang ? "City and State*" : "Cidade e Estado*" }></input> <br/>
                <input type="tel" name="telefone" onChange={(e) => handleTel1Input(e.target.value)} value={telefone} placeholder={ lang ? "Phone number*" : "Telefone*" }></input> <br/>
                <input type="email" name="email" onChange={(e) => setEmail(e.target.value)} value={email} placeholder={ lang ? "E-mail*" : "E-mail*" }></input> <br/>
                <input type="tel" name="telefone2" onChange={(e) => handleTel2Input(e.target.value)} value={telefone2} placeholder={ lang ? "Phone number of a relative or someone close" : "Telefone de um parente ou alguém próximo" }></input> <br/>
                <input type="text" name="rg" onChange={(e) => setRg(e.target.value)} value={rg} placeholder={ lang ? "RG*" : "RG*" }></input> <br/>
                <input type="text" name="cpf" onChange={(e) => maskCpf(e.target.value)} value={cpf} placeholder={ lang ? "CPF*" : "CPF*" }></input> <br/>
                <input type="text" name="dataNascimento" onChange={(e) => maskData(e.target.value)} value={dataNascimento} placeholder={ lang ? "Birth Date*" : "Data de Nascimento*" }></input> <br/>
                <input type="text" name="escolaridade" onChange={(e) => setEscolaridade(e.target.value)} value={escolaridade} placeholder={ lang ? "Education*" : "Escolaridade*" }></input> <br/>
                <span><strong>{ lang ? "Company Data - for LEGAL ENTITY" : "Dados da Empresa - para PESSOA JURÍDICA" }</strong></span>
                <input type="text" name="razaoSocial" onChange={(e) => setRazaoSocial(e.target.value)} value={razaoSocial} placeholder={ lang ? "Corporate name" : "Razão Social" }></input> <br/>
                <input type="text" name="enderecoCnpj" onChange={(e) => setEnderecoCnpj(e.target.value)} value={enderecoCnpj} placeholder={ lang ? "Address" : "Endereço" }></input> <br/>
                <input type="text" name="numeroCnpj" onChange={(e) => setNumeroCnpj(e.target.value)} value={numeroCnpj} placeholder={ lang ? "Address number" : "Número" }></input> <br/>
                <input type="text" name="bairroCnpj" onChange={(e) => setBairroCnpj(e.target.value)} value={bairroCnpj} placeholder={ lang ? "Neighbourhood" : "Bairro" }></input> <br/>
                <input type="text" name="cidadeEstadoCnpj" onChange={(e) => setCidadeEstadoCnpj(e.target.value)} value={cidadeEstadoCnpj} placeholder={ lang ? "City and State" : "Cidade e Estado" }></input> <br/>
                <input type="tel" name="telefoneCnpj" onChange={(e) => handleTelCnpjInput(e.target.value)} value={telefoneCnpj} placeholder={ lang ? "Phone number" : "Telefone" }></input> <br/>
                <input type="text" name="cnpj" onChange={(e) => maskCnpj(e.target.value)} value={cnpj} placeholder={ lang ? "CNPJ" : "CNPJ" }></input> <br/>
                <input type="text" name="inscricaoEstadual" onChange={(e) => setInscricaoEstadual(e.target.value)} value={inscricaoEstadual} placeholder={ lang ? "State registration" : "Inscrição Estadual" }></input> <br/>
                <span><strong>{ lang ? "Payment" : "Pagamento" }</strong></span>
                <input type="text" name="valor" disabled value={lang ? 'US$410,00' : 'R$1.999,00'}></input> <br/>
                <input type="text" name="formaPagamento" onChange={(e) => setFormaPagamento(e.target.value)} value={formaPagamento} placeholder={ lang ? "Payment Method*" : "Forma de Pagamento*" }></input> <br/>
                <input type="text" name="responsavelPagamento" onChange={(e) => setResponsavelPagamento(e.target.value)} value={responsavelPagamento} placeholder={ lang ? "Responsible for payment*" : "Responsável pelo pagamento*" }></input> <br/>
                <input id="modalBtn" type="submit" name="enviar" value={ lang ? "Send" : "Enviar" }></input> <br/>
              </form>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>

        <Tabs className="capacitacaotabs">
          <TabList>
            <Tab>{ lang ? "Methodology" : "Metodologia" }</Tab>
            <Tab>{ lang ? "Activities" : "Atividades" }</Tab>
            <Tab>{ lang ? "Target Audience" : "Público-Alvo" }</Tab>
            <Tab>{ lang ? "Workload" : "Carga-Horária" }</Tab>
            <Tab>{ lang ? "Where to Stay" : "Onde Hospedar" }</Tab>
          </TabList>

          <TabPanel className="capacitacaotabpanel">
            <br/>

            <p>
              <li>
                { lang ? "Technical guidance on identifying intrinsic and extrinsic defects;" : "Orientação técnica de identificação de defeitos intrínsecos e extrínsecos;" }
              </li>
              <li>
                { lang ? "Storage;" : "Armazenagem;" }
              </li>
              <li>
                { lang ? "Coffee processing;" : "Beneficiamento do café;" }
              </li>
              <li>
                { lang ? "Income calculation;" : "Cálculo de renda;" }
              </li>
              <li>
                { lang ? "Sample preparation;" : "Preparo de amostras;" }
              </li>
              <li>
                { lang ? "Determination of humidity;" : "Determinação de umidade;" }
              </li>
              <li>
                { lang ? "Classification reports;" : "Laudos de classificação;" }
              </li>
              <li>
                { lang ? "Legislation (Law 27173/1949 and IN08/2003);" : "Legislação (Lei 27173/1949 e IN08/2003);" }
              </li>
              <li>
                { lang ? "Classification of coffee by type;" : "Classificação do café por tipo;" }
              </li>
              <li>
                { lang ? "Preparation, drying, color, sieve and appearance;" : "Preparo, seca, cor, peneira e aspecto;" }
              </li>
              <li>
                { lang ? "Formation of alloys (blends);" : "Formação de ligas (blends);" }
              </li>
              <li>
                { lang ? "Understanding the mechanism of taste and smell;" : "Noções do mecanismo do paladar e olfato;" }
              </li>
              <li>
                { lang ? "Taster positioning and posture techniques;" : "Técnicas de posicionamento e postura do degustador;" }
              </li>
              <li>
                { lang ? "Sample preparation;" : "Preparo de amostras;" }
              </li>
              <li>
                { lang ? "Definition of standards for classic drinks;" : "Definição de padrões das bebidas clássicas;" }
              </li>
              <li>
                { lang ? "Influence of defects (PVA) on the final drink, conventional and final product tests;" : "Influência dos defeitos (PVA) na bebida final, provas convencionais e de produto final;" }
              </li>
              <li>
                { lang ? "Analysis of roasted and ground coffee;" : "Análise de café torrado e moído;" }
              </li>
              <li>
                { lang ? "Classifications of coffee aroma, body, acidity and bitterness;" : "Classificações de aroma, corpo, acidez e amargo do café;" }
              </li>
              <li>
                { lang ? "Understanding of marketing;" : "Noções de comercialização;" }
              </li>
              <li>
                { lang ? "Behavior of the coffee market;" : "Comportamento do mercado cafeeiro;" }
              </li>
              <li>
                { lang ? "Main types/qualities produced in Brazil;" : "Principais tipos/qualidades produzidas no Brasil;" }
              </li>
              <li>
                { lang ? "Export (logistics)." : "Exportação (logísticas)."}
              </li>
            </p><br/>
          </TabPanel>
          <TabPanel className="capacitacaotabpanel">
            <br/>
            <h3>{ lang ? "Coffee Quality Categories" : "Categorias de Qualidade do Café" }</h3>
            <p style={{textAlign:'left'}}>
              <li>
                { lang ? "Knowledge of coffee species;" : "Conhecimento das espécies do café;" }
              </li>
              <li>
                { lang ? "Origin;" : "Origem;" }
              </li>
              <li>
                { lang ? "Classification according to drink and category;" : "Classificação quanto a bebida e categoria;"}
              </li>
              <li>
                { lang ? "Post-harvest treatments;" : "Tratamentos pós colheita;" }
              </li>
              <li>
                { lang ? "The different qualities of coffee;" : "As diferentes qualidades do café;" }
              </li>
              <li>
                { lang ? "What is traditional, superior and gourmet coffee;" : "O que é café tradicional, superior e gourmet;" }
              </li>
              <li>
                { lang ? "Composition objectives of traditional and gourmet coffee;" : "Objetivos de composição do café tradicional e do gourmet;" }
              </li>
              <li>
                { lang ? "Positioning of each coffee category." : "Posicionamento de cada categoria do café." }
              </li>
            </p><br/>
            <h3>{ lang ? "Olfactory Fixation Exercise" : "Exercício de Fixação Olfativa" }</h3>
            <p style={{textAlign:'left'}}>
              { 
                lang ? 
                "Exercise through olfactory analysis of essence bottles. There will be practical and theoretical tests during the course. The Student will receive a certificate of completion of the course, as long as they obtain the minimum required score." : 
                "Exercício através de análise olfativo de frascos de essências. Haverão provas práticas e teóricas durante o curso. O Aluno receberá certificado de conclusão do curso, desde que obtenha aproveitamento mínimo exigido."
              }<br/><br/>
            </p>
            <h3>{ lang ? "Coffee Tasting" : "Degustação de Café" }</h3>
            <p style={{textAlign:'left'}}>
              { 
                lang ? 
                "Sensory, olfactory and gustatory analysis. Coffee tasting: traditional (hard/riada/rio/rio zona/conilon/café Velho/pva) and superior (strictly soft, soft and just soft) Composition of Coffee Beans (BLEND)." : 
                "Análise sensorial, olfativa e gustativa. Degustação de cafés: tradicionais (dura/riada/rio/rio zona/conilon/café velho/pva) e superiores(estritamente mole,mole e apenas mole) Composição dos Grãos de Cafés (BLEND)."
              }<br/><br/>
            </p>
            <h3>{ lang ? "Coffee Quality Characteristics" : "Características de Qualidade do Café" }</h3>
            <p style={{textAlign:'left'}}>
              { 
                lang ? 
                "Aroma, Defects, Acidity, Bitterness, Flavor, Body, Residual Flavor, Astringency, Overall Quality, etc.: what it is and how to detect it. - Blend (composition) - Grain Roasting Point - Water quality - Water temperature." : 
                "Aroma, Defeitos, Acidez, Amargo, Sabor, Corpo, Sabor Residual, Adstringência, Qualidade Global, etc: o que é e como detectar. - Blend (composição) - Ponto de Torra dos Grãos - Qualidade da água - Temperatura de água."
              }<br/><br/>
            </p>
          </TabPanel>
          <TabPanel className="capacitacaotabpanel">
            <br/>
            <p>
              <li>
                { lang ? "Agricultural cooperatives" : "Cooperativas agrícolas" }
              </li>
              <li>
                { lang ? "General stores" : "Armazéns gerais" }
              </li>
              <li>
                { lang ? "Agricultural technicians" : "Técnicos agrícolas" }
              </li>
              <li>
                { lang ? "Coffee growers" : "Cafeicultores" }
              </li>
              <li>
                { lang ? "Professionals in the agricultural sector" : "Profissionais do setor agrícola" }
              </li>
              <li>
                { lang ? "Students" : "Estudantes" }
              </li>
              <li>
                { lang ? "Hospitality" : "Hotelaria" }
              </li>
              <li>
                { lang ? "Coffee shops" : "Cafeterias" }
              </li>
              <li>
                { lang ? "Supermarket sector" : "Setor supermercadista" }
              </li>
              <li>
                { lang ? "Big consumers" : "Grandes consumidores" }
              </li>
            </p><br/>
          </TabPanel>
          <TabPanel className="capacitacaotabpanel">
            <br/>
            <span>
              { 
                lang ? 
                "The course has a total of 40 hours of classes taught (from 8 am to 5:30 pm, lasting one week). Students will receive all teaching material and a certificate of course completion." : 
                "O curso tem um total de 40 horas aulas lecionadas, (de 8h às 17h30, com duração de uma semana). Os Alunos receberão todo o material didático e certificado de conclusão do curso." 
              }
            </span>
            <br/><br/>
          </TabPanel>
          <TabPanel className="capacitacaotabpanel">
            <br/>
            <h4>{ lang ? "Partner Hotels:" : "Hotéis Parceiros:" }</h4>
            <Link to="https://www.ourocerradohotel.com.br/" target="_blank">Ouro Cerrado Hotel</Link><br/>
            <Link to="http://www.minashotel.com.br/" target="_blank">Minas Hotel</Link><br/>
            <span>Center Hotel</span><br/>
            <span>Guines Hotel</span><br/>
            <span>Grande Avenida</span><br/><br/>
            <h4>{ lang ? "Other Hotels:" : "Outros Hotéis:" }</h4>
            <Link to="http://bit.ly/2sISAXG" target="_blank">{ lang ? "Patrocínio hotels in Trivago" : "Hotéis de Patrocínio em Trivago" }</Link><br/>
            <br/>
          </TabPanel>
        </Tabs><br/>
      </div>

        <div id="myModal1" className="modalquemsomos">
          <div className="modalquemsomos-content">
            <div className="modalquemsomos-header">
              <span className="close">&times;</span>
            </div>
            <div className="modalquemsomos-body">
              <img src={require("../../../../assets/images/direcao1.png")} alt="Glaucio Lima Fonte Boa"/>
              <p><b>{ lang ? "Name: " : "Nome: " }</b>Ronaldo Dantas</p>
              <p>
                <b>{ lang ? "Role: " : "Cargo: " }</b>
                {
                  lang ? 
                  "Executive Director, Lead Auditor, Q-Grader, Course Instructor (Coffee Classification and Tasting and PMT), Financial Manager and Roasting Master" : 
                  "Diretor Executivo, Auditor Líder, Q-Grader, Instrutor de cursos (Classificação e Degustação de Café e PMT), Gestor Financeiro e Mestre de Torra"
                }
              </p>
              <p>
                <b>{ lang ? "Description:" : "Descrição:" }</b>
                <li>{ lang ? "Born in Loanda/PR" : "Natural de Loanda/PR" }</li>
                <li>{ lang ? "Agribusiness Technologist (Instituto de Ensino Superior de Patrocínio - IESP)" : "Tecnólogo do Agronegócio (Instituto de Ensino Superior de Patrocínio - IESP)" }</li>
                <li>{ lang ? "Computer Technician" : "Técnico em Informática" }</li>
                <li>{ lang ? "Fluency in English" : "Fluência em inglês" }</li>
              </p>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
        
        <div id="myModal3" className="modalquemsomos">
          <div className="modalquemsomos-content">
            <div className="modalquemsomos-header">
              <span className="close">&times;</span>
            </div>
            <div className="modalquemsomos-body">
              <img src={require("../../../../assets/images/direcao3.png")} alt="Glaucio Lima Fonte Boa"/>
              <p><b>{ lang ? "Name: " : "Nome: " }</b>Glaucio Lima Fonte Boa</p>
              <p>
                <b>{ lang ? "Role: " : "Cargo: " }</b>
                {
                  lang ? 
                  "Senior Executive, Lead Auditor, Q-Grader, Course Instructor (Coffee Classification and Tasting, Coffee Marketing and PMT), Field Auditor, Works in the UTZ, 4C, Strarbucks C.A.F.E. Practices, IN29/MAPA" : 
                  "Executivo Sênior, Auditor Líder, Q-Grader, Instrutor de cursos (Classificação e Degustação de Café, Comercialização de Café e PMT), Auditor de Campo, Atua nos programas UTZ, 4C, Strarbucks C.A.F.E. Practices, IN29/MAPA"
                }
              </p>
              <p>
                <b>{ lang ? "Description:" : "Descrição: " }</b>
                <li>{ lang ? "Born in Guarapari/ES" : "Natural de Guarapari/ES" }</li>
                <li>{ lang ? "Agricultural Engineer (Universidade Federal do Espirito Santo - UFES)" : "Engenheiro Agrônomo (Universidade Federal do Espirito Santo - UFES)" }</li>
                <li>{ lang ? "MBA in Strategic Agribusiness Management (Fundação Getúlio Vargas - FGV)" : "MBA em Gestão Estratégica do Agronegócio (Fundação Getúlio Vargas - FGV)" }</li>
                <li>{ lang ? "Fluency in English" : "Fluência em inglês" }</li>
                <li>Q-Grader</li>
              </p>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>

        <div id="myModal4" className="modalquemsomos">
          <div className="modalquemsomos-content">
            <div className="modalquemsomos-header">
              <span className="close">&times;</span>
            </div>
            <div className="modalquemsomos-body">
              <img src={require("../../../../assets/images/nivaldo.png")} alt="Nivaldo Henrique das Neves Caixeta"/>
              <p><b>{ lang ? "Name: " : "Nome: " }</b>Nivaldo Henrique das Neves Caixeta</p>
              <p>
                <b>{ lang ? "Role: " : "Cargo: " }</b>
                {
                  lang ? 
                  "Commercial and Courses Coordinator" : 
                  "Coordenador Comercial e Cursos"
                }
              </p>
              <p>
                <b>{ lang ? "Description:" : "Descrição:" }</b>
                <li>{ lang ? "Born in Patrocínio/MG" : "Natural de Patrocínio/MG" }</li>
                <li>{ lang ? "Environmental Manager (Unicesumar University)" : "Gestor Ambiental (Universidade Unicesumar)" }</li>
                <li>{ lang ? "Postgraduate in Digital Marketing (Unicesumar University)" : "Pós em Marketing Digital (Universidade Unicesumar)" }</li>
              </p>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
        
        <div id="myModal5" className="modalquemsomos">
          <div className="modalquemsomos-content">
            <div className="modalquemsomos-header">
              <span className="close">&times;</span>
            </div>
            <div className="modalquemsomos-body">
              <img src={require("../../../../assets/images/luis.png")} alt="Luís Carlos dos Anjos"/>
              <p><b>{ lang ? "Name: " : "Nome: " }</b>Luís Carlos dos Anjos</p>
              <p>
                <b>{ lang ? "Role: " : "Cargo: " }</b>{ lang ? "Classifier" : "Classificador" }
              </p>
              <p>
                <b>{ lang ? "Description:" : "Descrição: " }</b>
                <li>{ lang ? "Born in Patrocínio/MG" : "Natural de Patrocínio/MG" }</li>
              </p>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>

    </div>
  )
}

export default CDC;