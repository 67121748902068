import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function DocumentosRainforestAlliance(){

	const [lang, setLang] = useState();

	useEffect(()  => {
		const checkLang = function() {
			if(localStorage.getItem('lang')) {
				setLang(JSON.parse(localStorage.getItem('lang')));
			} else {
				setLang(0);
			}
		}

		window.addEventListener('storage', checkLang)

		return () => {
			window.removeEventListener('storage', checkLang)
		}
  }, []);

  useEffect(()  => {		
		if(localStorage.getItem('lang')) {
			setLang(JSON.parse(localStorage.getItem('lang')));
		} else {
			setLang(0);
		}	
    window.dispatchEvent(new Event("newRoute"));	
  }, []);

  return(
    <div style={{backgroundColor:'#F7FBF5'}}>
      <div className="documentossubpages">
        <div>
        <Link className="fa fa-chevron-left fa-2x" to="/documentos" state={{from: 0}}/><br/>
        <h1>Rainforest Alliance</h1><br/>
        </div>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "APPENDIX CHAPTER 1 - MANAGEMENT" : "ANEXO CAPÍTULO 1 - GESTÃO" }</b><br/>
          <span>
            { 
              lang ? 
              "This appendix includes additional binding content related to the implementation of the Requirements of Chapter 1 of the Sustainable Agriculture Standard: With reference to requirements 1.2.12, 1.2.13, 1.2.14 and 1.1.15, Certificate Holders (CHs) must provide data geolocation data for the Rainforest Alliance Certification Platform (PCRA)." : 
              "Este anexo inclui conteúdo vinculativo adicional relacionado à implementação dos Requisitos do Capítulo 1 do Padrão de Agricultura Sustentável: Com referência aos requisitos 1.2.12, 1.2.13, 1.2.14 e 1.1.15, os Detentores de Certificado (DCs) devem fornecer dados de geolocalização para a Plataforma de Certificação Rainforest Alliance (PCRA)." 
            }
          </span><br/>
          <Link to="../../../../assets/documents/Anexo_Capítulo_1-Gestão(Anexo_anterior_S17).pdf" download="Anexo_Capítulo_1-Gestão(Anexo_anterior_S17).pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "APPENDIX CHAPTER 2 - TRACEABILITY" : "ANEXO CAPÍTULO 2 - RASTREABILIDADE" }</b><br/>
          <span>
            { 
              lang ? 
              "This appendix includes additional binding content related to the implementation of the Requirements of Chapter 2 of the Sustainable Agriculture Standard: Traceability. The appendix is shorter and easier to use. This document includes: • Traceability (Previous Annex S06) (Related to requirements 2.1, 2.2 and 2.3)" : 
              "Este anexo inclui conteúdo vinculativo adicional relacionado à implementação dos Requisitos do Capítulo 2 do Padrão de Agricultura Sustentável: Rastreabilidade. O Anexo ficou mais curto e mais fácil de usar. Este documento inclui: • Rastreabilidade (Anexo Anterior S06) (Relacionado aos requisitos 2.1, 2.2 e 2.3)" 
            }
          </span><br/>
          <Link to="../../../../assets/documents/Anexo_Capitulo_2-Rastreabilidade_v1.pdf" download="Anexo_Capitulo_2-Rastreabilidade_v1.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "APPENDIX CHAPTER 3 - INCOME AND SHARED RESPONSIBILITY" : "ANEXO CAPÍTULO 3 - RENDA E RESPONSABILIDADE COMPARTILHADA" }</b><br/>
          <span>
            { 
              lang ? 
              "This appendix includes additional binding content related to the implementation of the Requirements in Chapter 3 of the Sustainable Agriculture Standard: Shared Responsibility. The appendix has been made shorter and easier to use. This document includes: • Shared Responsibility (Related to requirements 3.2 and 3.3)." : 
              "Este anexo inclui conteúdo vinculativo adicional relacionado à implementação dos Requisitos no Capítulo 3 do Padrão de Agricultura Sustentável: Responsabilidade Compartilhada. O Anexo foi feito mais curto e mais fácil de usar. Este documento inclui: • Responsabilidade Compartilhada (Relacionado aos requisitos 3.2 e 3.3)." 
            }            
          </span><br/>
          <Link to="../../../../assets/documents/Anexo_Capitulo_3-Renda_e_Resp_Comp_v1.pdf" download="Anexo_Capitulo_3-Renda_e_Resp_Comp_v1.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "APPENDIX CHAPTER 4 - AGRICULTURE" : "ANEXO CAPÍTULO 4 - AGRICULTURA" }</b><br/>
          <span>
            { 
              lang ? 
              "This appendix includes additional binding content related to the implementation of the Requirements of Chapter 4 of the Sustainable Agriculture Standard: Agriculture. The appendix is shorter and easier to use. This document includes: • Pesticide Management (Related to requirements 4.5.7, 4.6.1, 4.6.2, 4.6.7 and 5.6.1)." : 
              "Este anexo inclui conteúdo vinculativo adicional relacionado à implementação dos Requisitos do Capítulo 4 do Padrão de Agricultura Sustentável: Agricultura. O Anexo ficou mais curto e mais fácil de usar. Este documento inclui: • Gestão de Pesticidas (Relacionado aos requisitos 4.5.7, 4.6.1, 4.6.2, 4.6.7 e 5.6.1)." 
            }
          </span><br/>
          <Link to="../../../../assets/documents/Anex_Cap_4-Agri.pdf" download="Anex_Cap_4-Agri.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "APPENDIX CHAPTER 5 - SOCIAL" : "ANEXO CAPÍTULO 5 - SOCIAL" }</b><br/>
          <span>
            { 
              lang ? 
              "This appendix includes additional binding content related to the implementation of the Requirements of Chapter 5 of the Sustainable Agriculture Standard: Social. The appendix is shorter and easier to use. This document includes: • Remediation Protocol (Previous Annex S04) (Related to requirements 1.5.1, 1.6.2, 5.1.3, 5.1.4 and 5.8.2) • Methodology for Measuring Remuneration and Differences with Living Wage (Annex Previous S09: (Related to requirements 5.4) • Minimum wage references by country - Methodology (Previous Annex S10) (Related to requirements 5.4) • Free, Prior and Informed Consent (FPIC) Processes (Previous Annex S11) (Related to requirements 5.8)." : 
              "Este anexo inclui conteúdo vinculativo adicional relacionado à implementação dos Requisitos do Capítulo 5 do Padrão de Agricultura Sustentável: Social. O Anexo ficou mais curto e mais fácil de usar. Este documento inclui: • Protocolo de Remediação (Anexo Anterior S04) (Relacionado aos requisitos 1.5.1, 1.6.2, 5.1.3, 5.1.4 e 5.8.2) • Metodologia para Mensuração de Remuneração e Diferenças com Salário digno (Anexo Anterior S09: (Relacionado aos requisitos 5.4) • Referências de salário mínimo por país - Metodologia (Anexo anterior S10) (Relacionado aos requisitos 5.4) • Processos de Consentimento Livre, Prévio e Informado (CLPI) (Anexo Anterior S11) (Relacionado aos requisitos 5.8)." 
            }            
          </span><br/>
          <Link to="../../../../assets/documents/Anexo_Capitulo_5-Social_v1.pdf" download="Anexo_Capitulo_5-Social_v1.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "APPENDIX CHAPTER 6 - ENVIRONMENT" : "ANEXO CAPÍTULO 6 - MEIO AMBIENTE" }</b><br/>
          <span>
            { 
              lang ? 
              "This appendix includes additional binding content related to the implementation of the Requirements of Chapter 6 of the Sustainable Agriculture Standard: Environment. The appendix is shorter and easier to use. This document includes: • Additional details on requirements for non-conversion (Previous Annex S12) (Related to requirement 6.1.1) • Additional Details on Conservation and Restoration Areas Outside Farm Boundaries (Previous Annex S15) (Related to requirements 6.2. 3 and 6.2.4)." : 
              "Este anexo inclui conteúdo vinculativo adicional relacionado à implementação dos Requisitos do Capítulo 6 do Padrão de Agricultura Sustentável: Meio Ambiente. O Anexo ficou mais curto e mais fácil de usar. Este documento inclui: • Detalhes adicionais sobre requisitos para não conversão (Anexo anterior S12) (Relacionado ao requisito 6.1.1) • Detalhes Adicionais sobre Áreas de Conservação e Restauração Fora dos Limites da Fazenda (Anexo Anterior S15) (Relacionado aos requisitos 6.2.3 e 6.2.4)." 
            }            
          </span><br/>
          <Link to="../../../../assets/documents/Anexo_Capitulo_6-Meio_Ambiente_v1.pdf" download="Anexo_Capitulo_6-Meio_Ambiente_v1.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "APPENDIX S01: GLOSSARY [ENGLISH v.1.3]" : "ANEXO S01: GLOSSÁRIO [INGLÊS v.1.3]" }</b><br/>
          <span>
            { 
              lang ? 
              "The glossary of terms used in the Rainforest Alliance 2020 Certification Program documents. Terms that are highlighted in the Standard are covered in the glossary." : 
              "O glossário dos termos utilizados nos documentos do Programa de Certificação da Rainforest Alliance 2020. Os termos que são sublinhados na Norma, são abordados no glossário." 
            }            
          </span><br/>
          <Link to="../../../../assets/documents/ANEXO_S01-GLOSSARIO_1.pdf" download="ANEXO_S01-GLOSSARIO_1.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "APPENDIX S02 - MANAGEMENT CAPACITY ASSESSMENT TOOL" : "ANEXO S02 - FERRAMENTA AVALIAÇÃO CAPACIDADE GESTÃO" }</b><br/>
          <Link to="../../../../assets/documents/ANEXO_S02-AVALIAÇÃO_CAPACIDADE_GESTÃO.xlsx" download="ANEXO_S02-AVALIAÇÃO_CAPACIDADE_GESTÃO.xlsx" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "APPENDIX S03 - BASIC RISK ANALYSIS TOOL" : "ANEXO S03 - FERRAMENTA BÁSICA DE ANÁLISE DE RISCO" }</b><br/>
          <span>
            { 
              lang ? 
              "As outlined in the 2020 Sustainable Agriculture Standard requirements, certificate holders must perform a risk assessment. The tool that is available for the management of individually and group certified farms, as well as for supply chain actors, supports this process to identify risks that may prevent the certificate holder from complying with applicable requirements and achieving the expected sustainability results." : 
              "Conforme descrito nos requisitos do Padrão de Agricultura Sustentável de 2020, os detentores de certificados devem realizar uma avaliação de risco. A ferramenta que está disponível para a gestão de fazendas certificadas individualmente e em grupo, bem como para os atores da cadeia de suprimentos, apoia esse processo para identificar os riscos que podem impedir o detentor do certificado de cumprir os requisitos aplicáveis ​​e alcançar os resultados de sustentabilidade esperados." 
            }            
          </span><br/>
          <Link to="../../../../assets/documents/ANEXO_S03-FERRAMENTA_BASICA_ANALISE_RISCO.xlsx" download="ANEXO_S03-FERRAMENTA_BASICA_ANALISE_RISCO.xlsx" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "APPENDIX S08 - SALARY MATRIX TOOL" : "ANEXO S08 - FERRAMENTA MATRIZ SALARIAL" }</b><br/>
          <span>
            { 
              lang ? 
              "As outlined in the 2020 Sustainable Agriculture Standard: Farm Requirements, total compensation (salaries plus cash and in-kind benefits) for all types of workers is evaluated annually against the Living Wage benchmark as approved by the Rainforest Alliance and according to the Global Living Wage Coalition (GLWC). The salary matrix tool, developed in partnership with IDH, should be used by management to accurately populate salaries for data workers." : 
              "Conforme descrito no Padrão de Agricultura Sustentável de 2020: Requisitos da Fazenda, a remuneração total (salários mais dinheiro e benefícios em espécie) para todos os tipos de trabalhadores é avaliada anualmente em relação ao benchmark Salário de Vida, conforme aprovado pela Rainforest Alliance e de acordo com o Global Living Wage Coalition (GLWC). A ferramenta de matriz salarial, desenvolvida em parceria com a IDH , deve ser usada pela administração para preencher com precisão os salários dos trabalhadores de dados." 
            }            
          </span><br/>
          <Link to="../../../../assets/documents/ANEXO_S8-FERRAMENTA_MATRIZ_SALARIAL.xlsx" download="ANEXO_S8-FERRAMENTA_MATRIZ_SALARIAL.xlsx" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "APPENDIX S13 - GROUP MEMBER REGISTRATION" : "ANEXO S13 - REGISTRO MEMBRO GRUPO" }</b><br/>
          <span>
            { 
              lang ? 
              "The Group Membership Register is a binding template that allows Farm certificate holders, especially those under group certification, to upload relevant data from their internal inspections around their group members to the RACP in preparation for their audit." : 
              "O Registro de Membro do Grupo é um modelo vinculativo que permite aos detentores de certificados de Fazenda, especialmente aqueles sob certificação de grupo, carregar dados relevantes de suas inspeções internas em torno de seus membros de grupo para o RACP em preparação para sua auditoria." 
            }            
          </span><br/>
          <Link to="../../../../assets/documents/ANEX_S13-REG_MEM_GRUP_V.xlsx" download="ANEX_S13-REG_MEM_GRUP_V.xlsx" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "APPENDIX S16 - SUSTAINABILITY INVESTMENT MODEL PLAN" : "ANEXO S16 - PLANO DE MODELO DE INVESTIMENTOS EM SUSTENTABILIDADE" }</b><br/>
          <span>
            { 
              lang ? 
              "The Sustainability Investment Model Plan aims to identify the investments needed to implement the requirements of the Rainforest Alliance 2020 Sustainable Agriculture Standard." : 
              "O Plano de Modelo de Investimento em Sustentabilidade visa identificar os investimentos necessários para implementar os requisitos do Padrão de Agricultura Sustentável Rainforest Alliance 2020." 
            }            
          </span><br/>
          <Link to="../../../../assets/documents/ANEXO_S16-MOD_PLANO_INVEST_SUSTENTABILIDADE.xlsm" download="ANEXO_S16-MOD_PLANO_INVEST_SUSTENTABILIDADE.xlsm" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "EVALUATION OF APPLICABLE LAWS" : "AVALIAÇÃO LEIS APLICÁVEIS" }</b><br/>
          <span>
            { 
              lang ? 
              "Laws and Regulations applicable within the scope of the Brazilian federation for evaluating the Rainforest Alliance 2020 standard." : 
              "Leis e Regulamentações aplicáveis no âmbito da federação brasileira para avaliação da norma Rainforest Alliance 2020." 
            }            
          </span><br/>
          <Link to="../../../../assets/documents/AVALIAÇÃO_DAS_LEIS_VIGENTES.xlsx" download="AVALIAÇÃO_DAS_LEIS_VIGENTES.xlsx" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "CAF - APPLICATION FORM - SUPPLY CHAIN" : "CAF - FORMULÁRIO DE APLICAÇÃO - CADEIA SUPRIMENTOS" }</b><br/>
          <span>
            { 
              lang ? 
              "Portuguese Version 2.1 - The Certification Request Form (CAF) is a mandatory document to be completed by Certificate Holders and Certification Bodies throughout the 2020 Sustainable Agriculture Standard Certification Process." : 
              "Versão Português 2.1 - O Formulário de Solicitação de Certificação (CAF) é um documento obrigatório de preenchimento pelos Titulares de Certificados e Organismos Certificadores ao longo do Processo de Certificação da Norma de Agricultura Sustentável 2020." 
            }            
          </span><br/>
          <Link to="../../../../assets/documents/FORMULÁRIO_APLICAÇÃO_CADEIA_SUPRIMENTOS.xlsx" download="FORMULÁRIO_APLICAÇÃO_CADEIA_SUPRIMENTOS.xlsx" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "CAF - APPLICATION FORM - AGRICULTURAL PRODUCTION GROUP CERTIFICATION" : "CAF - FORMULÁRIO DE APLICAÇÃO - PRODUÇÃO AGRÍCOLA CERTIFICAÇÃO EM GRUPO" }</b><br/>
          <span>
            { 
              lang ? 
              "Portuguese Version 2.1 - The Certification Request Form (CAF) is a mandatory document to be completed by Certificate Holders and Certification Bodies throughout the 2020 Sustainable Agriculture Standard Certification Process." : 
              "Versão Português 2.1 - O Formulário de Solicitação de Certificação (CAF) é um documento obrigatório de preenchimento pelos Titulares de Certificados e Organismos Certificadores ao longo do Processo de Certificação da Norma de Agricultura Sustentável 2020." 
            }            
          </span><br/>
          <Link to="../../../../assets/documents/FORMULÁRIO_APLICAÇÃO_GRUPO_FAZENDAS.xlsx" download="FORMULÁRIO_APLICAÇÃO_GRUPO_FAZENDAS.xlsx" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "CAF - APPLICATION FORM - INDIVIDUAL AND MULTI-FARM AGRICULTURAL PRODUCTION" : "CAF - FORMULÁRIO DE APLICAÇÃO - PRODUÇÃO AGRÍCOLA INDIVIDUAL E MULTI-FAZENDA" }</b><br/>
          <span>
            { 
              lang ? 
              "Portuguese Version 2.1 - The Certification Request Form (CAF) is a mandatory document to be completed by Certificate Holders and Certification Bodies throughout the 2020 Sustainable Agriculture Standard Certification Process." : 
              "Versão Português 2.1 - O Formulário de Solicitação de Certificação (CAF) é um documento obrigatório de preenchimento pelos Titulares de Certificados e Organismos Certificadores ao longo do Processo de Certificação da Norma de Agricultura Sustentável 2020." 
            }            
          </span><br/>
          <Link to="../../../../assets/documents/FORMULÁRIO_APLICAÇÃO_FAZ_IND_MULT.xlsx" download="FORMULÁRIO_APLICAÇÃO_FAZ_IND_MULT.xlsx" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "CAF - APPLICATION FORM - SUMINISTRATION AGREEMENT" : "CAF - FORMULARIO DE APLICACIÓN - CADENA DE SUMINISTRO" }</b><br/>
          <span>
            { 
              lang ? 
              "Spanish Version 2.1" : 
              "Versão Espanhol 2.1" 
            }            
          </span><br/>
          <Link to="../../../../assets/documents/SA-F-GA-5-V2.xlsx" download="SA-F-GA-5-V2.xlsx" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "CAF - APPLICATION FORM - GROUP CERTIFICATION" : "CAF - FORMULARIO DE APLICACIÓN - CERTIFICACIÓN GRUPAL" }</b><br/>
          <span>
            { 
              lang ? 
              "Spanish Version 2.1" : 
              "Versão Espanhol 2.1" 
            }
          </span><br/>
          <Link to="../../../../assets/documents/SA-F-GA-4-V2.xlsx" download="SA-F-GA-4-V2.xlsx" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "CAF - APPLICATION FORM - INDIVIDUAL AND MULTI-FARM AGRICULTURAL PRODUCTION" : "CAF - FORMULARIO DE APLICACIÓN - FINCA INDIVIDUAL Y MULTIFINCA" }</b><br/>
          <span>
            { 
              lang ? 
              "Spanish Version 2.1" : 
              "Versão Espanhol 2.1" 
            }
          </span><br/>
          <Link to="../../../../assets/documents/SA-F-GA-3-V2.xlsx" download="SA-F-GA-3-V2.xlsx" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "GENERAL GUIDE FOR IMPLEMENTING RA SUSTAINABLE AGRICULTURE STANDARD [ENGLISH v.1.2]" : "GUIA GERAL PARA IMPLEMENTAÇÃO NORMA DE AGRICULTURA SUSTENTÁVEL RA [INGLÊS v.1.2]" }</b><br/>
          <span>
            { 
              lang ? 
              "This General Guide aims to assist Certificate Holders with the implementation of the Rainforest Alliance Sustainable Agriculture Standard by providing clarification to interpret the requirements and evidence necessary for compliance." : 
              "Este Guia Geral visa auxiliar os Detentores de Certificados com a implementação da Norma de Agricultura Sustentável da Rainforest Alliance, fornecendo esclarecimentos para interpretar os requisitos e as evidências necessárias para a conformidade." 
            }            
          </span><br/>
          <Link to="../../../../assets/documents/GUIA_GERAL_PARA_IMPLEMENTAÇAO_NORMA_1.pdf" download="GUIA_GERAL_PARA_IMPLEMENTAÇAO_NORMA_1.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "GUIDELINE A - HOW TO USE THE RAINFOREST ALLIANCE MANAGEMENT CAPACITY ASSESSMENT TOOL" : "ORIENTAÇÃO A - COMO UTILIZAR A FERRAMENTA DE AVALIAÇÃO DE CAPACIDADE DE GESTÃO DA RAINFOREST ALLIANCE" }</b><br/>
          <span>
            { 
              lang ? 
              "The objective of the Management Capability Assessment Tool is for group management to assess the business's capability and systems in several key management areas. This document provides guidance on how to implement Appendix S02." : 
              "O objetivo da Ferramenta de Avaliação da Capacidade de Gestão é que a gerência do grupo avalie a capacidade e os sistemas do negócio em várias áreas-chave de gerenciamento. Este documento fornece orientação sobre como implementar o Anexo S02." 
            }            
          </span><br/>
          <Link to="../../../../assets/documents/ORIENTAÇÃO_A-FERRAMENTA_AVALIAÇÃO_CAP_GESTÃO.pdf" download="ORIENTAÇÃO_A-FERRAMENTA_AVALIAÇÃO_CAP_GESTÃO.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "GUIDELINE B - MANAGEMENT PLAN TEMPLATE" : "ORIENTAÇÃO B - MODELO DE PLANO DE GESTÃO" }</b><br/>
          <span>
            { 
              lang ? 
              "This is a management plan template that you can use to comply with the relevant requirement of the Sustainable Agriculture Standard." : 
              "Este é um modelo de plano de gestão que você pode utilizar para cumprir com o requisito pertinente da Norma de Agricultura Sustentável." 
            }            
          </span><br/>
          <Link to="../../../../assets/documents/ORIENTAÇÃO_B-MODELO_PLANO_DE_GESTÃO.xlsx" download="ORIENTAÇÃO_B-MODELO_PLANO_DE_GESTÃO.xlsx" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "GUIDELINE C - CREATING A FARM MAP" : "ORIENTAÇÃO C - CRIANDO UM MAPA DA FAZENDA" }</b><br/>
          <span>
            { 
              lang ? 
              "This document is step-by-step guidance on how to prepare maps as indicated in Agricultural Production requirements 1.2.10 and 1.2.11 of the Sustainable Agriculture Standard 2020. This guidance document will enable producers, farm managers and group managers to prepare a map for your farm or group of farms. This document will show you how to create such a map and allow the reader to do the same." : 
              "Este documento é uma orientação passo a passo sobre como elaborar mapas conforme indicado nos requisitos de Produção Agrícola 1.2.10 e 1.2.11 da Norma de Agricultura Sustentável 2020. Este documento de orientação permitirá que os produtores, gerentes de fazenda e gerentes de grupo elaborem um mapa para sua fazenda ou grupo de fazendas. Este documento mostrará como criar esse mapa e permitir que o leitor faça o mesmo." 
            }            
          </span><br/>
          <Link to="../../../../assets/documents/ORIENTAÇÃO_C-CRIANDO_UM_MAPA_DA_FAZENDA.pdf" download="ORIENTAÇÃO_C-CRIANDO_UM_MAPA_DA_FAZENDA.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "GUIDELINE D - GEOLOCATION DATA REQUIREMENTS AND RISK MAPS" : "ORIENTAÇÃO D - REQUISITOS DE DADOS DE GEOLOCALIZAÇÃO E MAPAS DE RISCO" }</b><br/>
          <span>
            { 
              lang ? 
              "This document clarifies the requirements for geolocation data and provides a set of guidance to support Certificate Holders (CHs) in recording such information in accordance with the requirements of the Standard. It also provides guidance to Certification Entities (CEs) on how to verify this data during the audit process." : 
              "Este documento esclarece os requisitos para dados de geolocalização e fornece um conjunto de orientações para apoiar os Detentores de Certificado (DCs) em registrar tais informações de acordo com os requisitos da Norma. Também fornece orientações para as Entidades Certificadoras (ECs) sobre como verificar esses dados durante o processo de auditoria." 
            }            
          </span><br/>
          <Link to="../../../../assets/documents/ORIENTAÇÃO_D-GEO_E_MAPAS.pdf" download="ORIENTAÇÃO_D-GEO_E_MAPAS.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "GUIDELINE E - COMPLAINT MECHANISM" : "ORIENTAÇÃO E - MECANISMO DE QUEIXA" }</b><br/>
          <span>
            { 
              lang ? 
              "This guidance is a written advisory document for Management and the Grievance Committee and contains recommendations on how to establish an operational grievance mechanism at Certificate Holder level." : 
              "Essa orientação é um documento de assessoria escrito para a Gerência e ao Comitê de Queixas e contém recomendações sobre como estabelecer um mecanismo de queixa operacional em nível de Detentor de Certificado." 
            }            
          </span><br/>
          <Link to="../../../../assets/documents/ORIENTAÇÃO_E-MECANISMO_DE_QUEIXA.pdf" download="ORIENTAÇÃO_E-MECANISMO_DE_QUEIXA.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "GUIDELINE F - GENDER EQUALITY" : "ORIENTAÇÃO F - IGUALDADE DE GÊNERO" }</b><br/>
          <span>
            { 
              lang ? 
              "This appendix includes additional binding content related to the implementation of the Requirements in Chapter 3 of the Sustainable Agriculture Standard: Shared Responsibility. The Appendix has been made shorter and easier to use. This document includes: • Shared Responsibility (Related to requirements 3.2 and 3.3)." : 
              "Este anexo inclui conteúdo vinculativo adicional relacionado à implementação dos Requisitos no Capítulo 3 do Padrão de Agricultura Sustentável: Responsabilidade Compartilhada. O Anexo foi feito mais curto e mais fácil de usar. Este documento inclui: • Responsabilidade Compartilhada (Relacionado aos requisitos 3.2 e 3.3)." 
            }            
          </span><br/>
          <Link to="../../../../assets/documents/ORIENTAÇÃO_F-IGUALDADE_DE_GÊNERO.pdf" download="ORIENTAÇÃO_F-IGUALDADE_DE_GÊNERO.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "GUIDELINE G - HARVEST ESTIMATION" : "ORIENTAÇÃO G - ESTIMATIVA DE COLHEITA" }</b><br/>
          <span>
            { 
              lang ? 
              "This guidance for harvest estimation refers to the implementation of the requirements of standard 1.2.10, as this indicates production areas and 2.11 and 2.1.2." : 
              "Essa orientação para estimativa de colheita se refere à implementação dos requisitos da norma 1.2.10, uma vez que isso indica áreas de produção e 2.11 e 2.1.2." 
            }            
          </span><br/>
          <Link to="../../../../assets/documents/ORIENTAÇÃO_G-ESTIMATIVA_DE_COLHEITA.pdf" download="ORIENTAÇÃO_G-ESTIMATIVA_DE_COLHEITA.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "GUIDELINE H - INTEGRATED PEST MANAGEMENT (IPM) [ENGLISH V.1.1]" : "ORIENTAÇÃO H - MANEJO INTEGRADO DE PRAGAS (MIP) [INGLÊS V.1.1]" }</b><br/>
          <span>
            { 
              lang ? 
              "This guidance aims to provide information on Integrated Pest Management to help producers understand and implement the standard's requirements for successful and sustainable agriculture." : 
              "Essa orientação visa fornecer informações sobre Manejo Integrado de Pragas para auxiliar os produtores a entenderem e implementarem os requisitos da norma para uma agricultura de sucesso e sustentável." 
            }            
          </span><br/>
          <Link to="../../../../assets/documents/ORIENTAÇÃO_H-MANEJO_INT_PRAGAS_(MIP)_1.pdf" download="ORIENTAÇÃO_H-MANEJO_INT_PRAGAS_(MIP)_1.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "GUIDELINE I - PRUNING" : "ORIENTAÇÃO I - PODA" }</b><br/>
          <span>
            { 
              lang ? 
              "This manual focuses on the “pruning” theme of section 4.2. Pruning and Renewal of Cultivation Trees, particularly coffee, tea and cocoa crops. Covers the following topics: Purposes of pruning, period and frequency of pruning, pruning techniques and tools, and necessary aftercare." : 
              "Esse manual foca no tema de “poda” da seção 4.2. Poda e Renovação de Árvores do Cultivo, em particular aos cultivos de café, chá e cacau. Cobre os seguintes tópicos: Propósitos da poda, período e frequência da poda, técnicas e ferramentas de poda e cuidados posteriores necessários." 
            }            
          </span><br/>
          <Link to="../../../../assets/documents/ORIENTAÇÃO_I-PODA.pdf" download="ORIENTAÇÃO_I-PODA.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "GUIDELINE SMART INDICATORS AND METERS" : "ORIENTAÇÃO INDICADORES E MEDIDORES INTELIGENTES" }</b><br/>
          <span>
            { 
              lang ? 
              "The one-pager on Smart Indicators and Meters explains the order of actions to be implemented by the certificate holder (CH) and the certification body (CB). It indicates what is expected of certificate holders before each audit, as well as the corresponding checks that will be carried out by CBs." : 
              "O one-pager sobre Indicadores e Medidores Inteligentes explica a ordem das ações a serem implementadas pelo detentor do certificado (CH) e pelo organismo de certificação (CB). Indica o que se espera dos detentores de certificados antes de cada auditoria, bem como as verificações correspondentes que serão realizadas pelos OCs." 
            }            
          </span><br/>
          <Link to="../../../../assets/documents/ORIENTACAO_IND_E_MED.pdf" download="ORIENTACAO_IND_E_MED.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "GUIDELINE J - FERTILITY AND SOIL CONSERVATION" : "ORIENTAÇÃO J - FERTILIDADE E CONSERVAÇÃO DO SOLO" }</b><br/>
          <span>
            { 
              lang ? 
              "This guidance focuses on section 4.4 of the Standard, in particular with regards to the different options for soil analysis and their importance in decision-making, as well as the majority of processes that affect soil fertility and soil fertility management." : 
              "Essa orientação foca na seção 4.4 da Norma, em particular com relação às diferentes opções para análise de solo e sua importância na tomada de decisão, bem como a maioria dos processos que afetam a fertilidade do solo e o manejo da fertilidade do solo." 
            }            
          </span><br/>
          <Link to="../../../../assets/documents/ORIENTAÇÃO_J-FERT_CONSERVAÇÃO_SOLO.pdf" download="ORIENTAÇÃO_J-FERT_CONSERVAÇÃO_SOLO.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "GUIDELINE K - HOUSING AND LIVING CONDITIONS" : "ORIENTAÇÃO K - MORADIA E CONDIÇÕES DE VIDA" }</b><br/>
          <span>
            { 
              lang ? 
              "This guidance provides explanations of terms used to support Certificate Holders (CHs) interpret the intent of basic requirements. For some of the terms or aspects of the requirement, the guidance provides references to concretely help with correct implementation and assess compliance." : 
              "Essa orientação fornece explicações dos termos utilizados para apoiar os Detentores de Certificado (DCs) a interpretar o objetivo de requisitos básicos. Para alguns dos termos ou aspectos do requisito, a orientação dá referências para ajudar de forma concreta na implementação correta e avaliar a conformidade." 
            }            
          </span><br/>
          <Link to="../../../../assets/documents/ORIENTAÇÃO_K-MORADIA_E_CONDIÇÕES_DE_VIDA.pdf" download="ORIENTAÇÃO_K-MORADIA_E_CONDIÇÕES_DE_VIDA.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "GUIDELINE L - ASSESS AND ADDRESS" : "ORIENTAÇÃO L - AVALIAR E ABORDAR" }</b><br/>
          <span>
            { 
              lang ? 
              "This document aims to help farms seeking Rainforest Alliance certification, and farms already certified Rainforest Alliance, to understand and implement the assess and address system." : 
              "Esse documento visa auxiliar as fazendas buscando a certificação Rainforest Alliance, e fazendas já certificadas Rainforest Alliance, a entenderem e implementarem o sistema de avaliar e abordar." 
            }            
          </span><br/>
          <Link to="../../../../assets/documents/ORIENTAÇÃO_L-AVALIAR_E_ABORDAR.pdf" download="ORIENTAÇÃO_L-AVALIAR_E_ABORDAR.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "GUIDELINE M - NATIVE VEGETATION AND NATURAL ECOSYSTEMS" : "ORIENTAÇÃO M - VEGETAÇÃO NATIVA E ECOSSISTEMAS NATURAIS" }</b><br/>
          <span>
            { 
              lang ? 
              "This document provides guidance for implementing Sections 6.1, 6.2, and 6.3 of the Rainforest Alliance 2020 Sustainable Agriculture Standard." : 
              "Este documento fornece orientação para implementar as Seções 6.1, 6.2 e 6.3 do Padrão de Agricultura Sustentável Rainforest Alliance 2020." 
            }            
          </span><br/>
          <Link to="../../../../assets/documents/ORIENTACAO_M-VEGETACAO_NATIVA_ECOSSIS_NAT.pdf" download="ORIENTACAO_M-VEGETACAO_NATIVA_ECOSSIS_NAT.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "GUIDELINE N - ENERGY EFFICIENCY" : "ORIENTAÇÃO N - EFICIÊNCIA ENERGÉTICA" }</b><br/>
          <span>
            { 
              lang ? 
              "This guide aims to provide clarification on how to implement the energy efficiency-related requirements of the Sustainable Agriculture Standard." : 
              "Este guia visa fornecer esclarecimentos sobre como implementar os requisitos relacionados com a eficiência energética da Norma de Agricultura Sustentável." 
            }            
          </span><br/>
          <Link to="../../../../assets/documents/ORIENTAÇÃO_N-EFICIÊNCIA_ENERGÉTICA.pdf" download="ORIENTAÇÃO_N-EFICIÊNCIA_ENERGÉTICA.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "GUIDELINE O - REDUCTIONS IN GHG EMISSIONS" : "ORIENTAÇÃO O - REDUÇÕES DE EMISSÕES DE GEE" }</b><br/>
          <span>
            { 
              lang ? 
              "This guide aims to provide clarification on how to implement the requirements related to greenhouse gas reduction of the Sustainable Agriculture Standard." : 
              "Este guia visa fornecer esclarecimentos sobre como implementar os requisitos relacionados com a redução de gases efeito estufa da Norma de Agricultura Sustentável." 
            }            
          </span><br/>
          <Link to="../../../../assets/documents/ORIENTAÇÃO_O-REDUÇÕES_DE_EMISSÕES_DE_GEE.pdf" download="ORIENTAÇÃO_O-REDUÇÕES_DE_EMISSÕES_DE_GEE.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "GUIDELINE R - MONITORING TOOL TO ASSESS AND ADDRESS" : "ORIENTAÇÃO R - FERRAMENTA DE MONITORAMENTO PARA AVALIAR E ABORDAR" }</b><br/>
          <span>
            { 
              lang ? 
              "This is an OPTIONAL tool to help Certificate Holders monitor their risks and their mitigation and remediation measures in line with requirements 5.1.3, 5.1.4 and 5.1.8." : 
              "Esta é uma ferramenta OPCIONAL para ajudar os Detentores de Certificado a monitorarem seus riscos e suas medidas de mitigação e remediação em linha com os requisitos 5.1.3, 5.1.4 e 5.1.8." 
            }            
          </span><br/>
          <Link to="../../../../assets/documents/ORIENTAÇÃO_R-FER_MONIT_AVALIAR_ABORDAR.xlsx" download="ORIENTAÇÃO_R-FER_MONIT_AVALIAR_ABORDAR.xlsx" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "GUIDELINE S - REMEDIATION PROTOCOL" : "ORIENTAÇÃO S - PROTOCOLO DE REMEDIAÇÃO" }</b><br/>
          <span>
            { 
              lang ? 
              "Requirements 1.5.1, 1.6.2, and 5.1.4 of the 2020 Sustainable Agriculture Standard require certificate holders to document and correct instances of child labor, forced labor, discrimination, violence, and harassment in the workplace. following the Remediation Protocol (found in Appendix Chapter 5: Social). This Guide provides additional non-binding information to support implementation of the Remediation Protocol." : 
              "Os requisitos 1.5.1, 1.6.2 e 5.1.4 do Padrão de Agricultura Sustentável de 2020 exigem que os detentores de certificados documentem e corrijam casos de trabalho infantil, trabalho forçado, discriminação, violência e assédio no local de trabalho. seguindo o Protocolo de Remediação (encontrado no Anexo Capítulo 5: Social). Este Guia fornece informações adicionais não vinculativas para apoiar a implementação do Protocolo de Remediação." 
            }            
          </span><br/>
          <Link to="../../../../assets/documents/ORIENTAÇÃO_S-Protoc_Remed.pdf" download="ORIENTAÇÃO_S-Protoc_Remed.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "GUIDELINE T - FREE, PRIOR AND INFORMED CONSENT PROCESSES (FPIC)" : "ORIENTAÇÃO T - PROCESSOS DE CONSENTIMENTO LIVRE, PRÉVIO E INFORMADO (CLPI)" }</b><br/>
          <span>
            { 
              lang ? 
              "Requirements 5.8.1 and 5.8.2 of the 2020 Sustainable Agriculture Standard require certification holders to implement a Free, Prior, and Informed Consent (FPIC) process for any activities that diminish land or resource use rights or collective interests of indigenous peoples and local communities. These mandatory steps of the FPIC process are defined in the Annex, Chapter 5: Social). This guidance provides additional non-binding information to support implementation of the FPIC process." : 
              "Os requisitos 5.8.1 e 5.8.2 da Norma de Agricultura Sustentável de 2020 exigem que os detentores de certificação implementem um processo de Consentimento Livre, Prévio e Informado (FPIC) para quaisquer atividades que diminuam os direitos de uso da terra ou de recursos ou interesses coletivos de povos indígenas e comunidades locais. Essas etapas obrigatórias do processo de CLPI estão definidas no Anexo, Capítulo 5: Social). Esta orientação fornece informações adicionais não vinculativas para apoiar a implementação do processo CLPI." 
            }            
          </span><br/>
          <Link to="../../../../assets/documents/ORIENTAÇÃO_T-Proc_Cons_Livre_Prévio_Infor_(CLPI).pdf" download="ORIENTAÇÃO_T-Proc_Cons_Livre_Prévio_Infor_(CLPI).pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "GUIDELINE U - APPLICABILITY FOR SERVICE PROVIDERS" : "ORIENTAÇÃO U - APLICABILIDADE PARA PRESTADORES DE SERVIÇO" }</b><br/>
          <span>
            { 
              lang ? 
              "Requirement 1.2.2 of the 2020 Sustainable Agriculture Standard requires Service Providers to comply with the relevant requirements of the Standard. This document provides guidance on which requirements apply to different types of service providers." : 
              "O requisito 1.2.2 do Padrão de Agricultura Sustentável de 2020 exige que os Prestadores de Serviços cumpram os requisitos relevantes do Padrão. Este documento fornece orientação sobre quais requisitos são aplicáveis a diferentes tipos de prestadores de serviços." 
            }            
          </span><br/>
          <Link to="../../../../assets/documents/ORIENTAÇÃO_U-Aplic_Prest_Serv.pdf" download="ORIENTAÇÃO_U-Aplic_Prest_Serv.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "AUDIT POLICY FOR CERTIFIED HOLDERS BASED IN BRAZIL" : "POLÍTICA AUDITORIAS PARA DETENTORES CERTIFICADOS SEDIADOS NO BRASIL" }</b><br/>
          <span>
            { 
              lang ? 
              "The objective of this policy is to align the requirements of our Certification Rules and Sustainable Agriculture Standard with the requirements of local legislation in Brazil." : 
              "O objetivo desta política é alinhar as exigências de nossas Regras de Certificação e Norma de Agricultura Sustentável com as exigências da legislação local no Brasil." 
            }            
          </span><br/>
          <Link to="../../../../assets/documents/POLITICA_AUD_DETENTORES_CERT_SEDIADOS_BRASIL_v1.pdf" download="POLITICA_AUD_DETENTORES_CERT_SEDIADOS_BRASIL_v1.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "ADJUSTMENT POLICY IN DEADLINES FOR TRANSITION AND FULL CERTIFICATION CYCLES OF THE RAINFOREST ALLIANCE CERTIFICATION PROGRAM" : "POLÍTICA DE AJUSTE NOS PRAZOS PARA OS CICLOS DE TRANSIÇÃO E DE CERTIFICAÇÃO COMPLETA DO PROGRAMA DE CERTIFICAÇÃO RAINFOREST ALLIANCE" }</b><br/>
          <span>
            { 
              lang ? 
              "The Adjusted Deadlines for the 2020 Rainforest Alliance Certification Program Transition and Full Certification Cycles were introduced to support Certificate Holders transitioning to the 2020 Certification Program." : 
              "Os Prazos Ajustados para os Ciclos de Transição e de Certificação Completa do Programa de Certificação Rainforest Alliance 2020 foram introduzidos para apoiar os Detentores de Certificado a realizarem a transição para o Programa de Certificação 2020." 
            }            
          </span><br/>
          <Link to="../../../../assets/documents/Ajus_Prazos_Ciclos_Tran_Cert_Com_1.pdf" download="Ajus_Prazos_Ciclos_Tran_Cert_Com_1.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "RAINFOREST ALLIANCE 2020 LABELING AND TRADEMARK POLICY" : "POLÍTICA DE ROTULAGEM E MARCAS REGISTRADAS DA RAINFOREST ALLIANCE 2020" }</b><br/>
          <span>
            { 
              lang ? 
              "Use and approval of Rainforest Alliance brands." : 
              "Uso e aprovação das marcas Rainforest Alliance." 
            }            
          </span><br/>
          <Link to="../../../../assets/documents/POLITICA_ROTULAGEM_E_MARCAS_REGISTRADAS_DA_RA.pdf" download="POLITICA_ROTULAGEM_E_MARCAS_REGISTRADAS_DA_RA.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "EXCEPTIONAL USE POLICY: EXCEPTIONS GRANTED AND THEIR CONDITIONS FOR THE USE OF PESTICIDES PROHIBITED BY THE RAINFOREST ALLIANCE v1.4" : "POLÍTICA DE USO EXCEPCIONAL: EXCEÇÕES CONCEDIDAS E SUAS CONDIÇÕES PARA UTILIZAÇÃO DE PESTICIDAS PROIBIDOS PELA RAINFOREST ALLIANCE v1.4" }</b><br/>
          <span>
            { 
              lang ? 
              "The objective of this policy, together with the requirements of the Rainforest Alliance 2020 Sustainable Agriculture Standard, is to support producers on their journey to phase out the use of HHPs. The Exceptional Use Policy grants limited exceptions for the use of certain agrochemicals contained on the Rainforest Alliance list of prohibited pesticides in specific scenarios where alternatives to HHPs are not available. Exceptions are granted with requirements that producers mitigate and compensate for the negative impacts on people and the environment from the use of such pesticides. Exceptions are granted for a specific combination of crop, pest and country and for a specific period of time." : 
              "O objetivo desta política, juntamente com os requisitos da Norma de Agricultura Sustentável da Rainforest Alliance 2020, é apoiar os produtores em sua jornada para eliminar gradualmente o uso de HHPs. A Política de Uso Excepcional concede exceções limitadas para o uso de certos compostos agroquímicos contidos na lista de pesticidas proibidos da Rainforest Alliance em cenários específicos onde não há alternativas para HHPs disponíveis. Exceções são concedidas com exigências de que os produtores mitiguem e compensem os impactos negativos sobre as pessoas e o meio ambiente do uso de tais pesticidas. As exceções são concedidas para uma combinação específica de cultura, praga e país e por um período de tempo específico." 
            }            
          </span><br/>
          <Link to="../../../../assets/documents/POLÍTICA_DE_USO_EXCEPCIONAL_RA_1.pdf" download="POLÍTICA_DE_USO_EXCEPCIONAL_RA_1.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "POLICY - CHANGES TO CERTIFICATION AND AUDIT RULES FOR THE TRANSITIONAL YEAR" : "POLÍTICA - MUDANÇAS NAS REGRAS DE CERTIFICAÇÃO E AUDITORIA PARA O ANO DE TRANSIÇÃO" }</b><br/>
          <span>
            { 
              lang ? 
              "This policy outlines changes to ensure consistent implementation of the Certification and Audit Rules across the Rainforest Alliance Certification Program during the Transition year." : 
              "Esta política descreve as mudanças para garantir a implementação consistente das Regras de Certificação e Auditoria em todo o Programa de Certificação Rainforest Alliance durante o ano de Transição." 
            }            
          </span><br/>
          <Link to="../../../../assets/documents/POLITICA_MUDANÇAS_RCA_PERIODO_TRANSICAO.pdf" download="POLITICA_MUDANÇAS_RCA_PERIODO_TRANSICAO.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "POLICY FOR REMOTE AUDITS RAINFOREST ALLIANCE 2020" : "POLÍTICA PARA AUDITORIAS REMOTAS RAINFOREST ALLIANCE 2020" }</b><br/>
          <span>
            { 
              lang ? 
              "The Rainforest Alliance 2020 Certification Program comes into force, replacing the previous UTZ and RA programs, from July 1, 2021. In order to facilitate the transition to the new program, the Transition Rules allow all audits of supply chain completed in the period from July 1, 2021 to June 30, 2022 are carried out remotely." : 
              "O Programa de Certificação Rainforest Alliance 2020 entra em vigor, substituindo os programas anteriores UTZ e RA, a partir de 1º de julho de 2021. Com o objetivo de facilitar a transição para o novo programa, as Regras de Transição permitem que todas as auditorias de cadeia de suprimentos completadas no período de 1º de julho de 2021 até 30 de junho de 2022 sejam realizadas remotamente." 
            }            
          </span><br/>
          <Link to="../../../../assets/documents/Política_de_Auditorias_Remotas.pdf" download="Política_de_Auditorias_Remotas.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "RAINFOREST ALLIANCE 2020 CERTIFICATION AND AUDIT RULES [ENGLISH V.1.3]" : "REGRAS DE CERTIFICAÇÃO E AUDITORIA RAINFOREST ALLIANCE 2020 [INGLÊS V.1.3]" }</b><br/>
          <span>
            { 
              lang ? 
              "In line with the Rainforest Alliance's long-term vision to 'reimagine certification', the 2020 Certification Program Assurance System features numerous innovations to ensure certification is more context-specific, data-driven and risk-based." : 
              "Em linha com a visão de longo prazo da Rainforest Alliance de 'reimaginar a certificação', o Sistema de Garantia do Programa de Certificação 2020 apresenta inúmeras inovações para garantir que a certificação seja mais específica ao contexto, orientada por dados e baseada em risco." 
            }            
          </span><br/>
          <Link to="../../../../assets/documents/REGRAS_CERTIFICACAO_AUDITORIA_v1.pdf" download="REGRAS_CERTIFICACAO_AUDITORIA_v1.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "SUPPLY CHAIN REQUIREMENTS" : "REQUISITOS CADEIA DE SUPRIMENTOS" }</b><br/>
          <span>
            { 
              lang ? 
              "Sustainability must not cease to be a focus beyond the farm gate, and buyers in certified supply chains must provide greater support to farmers to work more sustainably. With the Rainforest Alliance 2020 Certification Program, we aim to promote not only transparency but also responsible business practices by companies throughout the supply chain." : 
              "A sustentabilidade não deve deixar de ser um foco após o portão da fazenda, e os compradores em cadeias de suprimentos certificadas devem fornecer maior apoio aos agricultores para trabalhar de forma mais sustentável. Com o Programa de Certificação 2020 da Rainforest Alliance, pretendemos promover não apenas a transparência, mas também práticas de negócios responsáveis ​​por empresas em toda a cadeia de suprimentos." 
            }            
          </span><br/>
          <Link to="../../../../assets/documents/REQUISITOS_CADEIA_SUPRIMENTOS_1.pdf" download="REQUISITOS_CADEIA_SUPRIMENTOS_1.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "AGRICULTURAL PRODUCTION REQUIREMENTS" : "REQUISITOS PRODUÇÃO AGRÍCOLA" }</b><br/>
          <span>
            { 
              lang ? 
              "The need for sustainable agriculture has never been greater. By providing a practical framework for sustainable agriculture and a targeted set of innovations, the Agricultural Requirements can help farmers produce better harvests, adapt to climate change, increase their productivity, set goals to achieve their sustainability performance, and direct investment. to face your biggest risks. The Agricultural Requirements are designed to help certificate holders maximize the positive social, environmental and economic impact of agriculture, while providing farmers with an enhanced framework to improve their livelihoods and protect the landscapes where they live and work." : 
              "A necessidade de uma agricultura sustentável nunca foi tão grande. Ao fornecer uma estrutura prática para a agricultura sustentável e um conjunto direcionado de inovações, os Requisitos Agrícolas podem ajudar os agricultores a produzir colheitas melhores, adaptar-se às mudanças climáticas, aumentar sua produtividade, estabelecer metas para alcançar seu desempenho de sustentabilidade e direcionar investimentos para enfrentar seus maiores riscos . Os Requisitos Agrícolas foram elaborados para ajudar os detentores de certificados a maximizar o impacto social, ambiental e econômico positivo da agricultura, ao mesmo tempo em que oferecem aos agricultores uma estrutura aprimorada para melhorar seus meios de subsistência e proteger as paisagens onde vivem e trabalham." 
            }            
          </span><br/>
          <Link to="../../../../assets/documents/REQUISITOS_PRODUÇÃO_AGRÍCOLA_1.pdf" download="REQUISITOS_PRODUÇÃO_AGRÍCOLA_1.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/><br/>
      </div>
    </div>
  )
}

export default DocumentosRainforestAlliance;