import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

function Documentos(){

  const { state } = useLocation();

	const [lang, setLang] = useState();

	useEffect(()  => {
		const checkLang = function() {
			if(localStorage.getItem('lang')) {
				setLang(JSON.parse(localStorage.getItem('lang')));
			} else {
				setLang(0);
			}
		}

		window.addEventListener('storage', checkLang)

		return () => {
			window.removeEventListener('storage', checkLang)
		}
  }, []);

  useEffect(()  => {		
		if(localStorage.getItem('lang')) {
			setLang(JSON.parse(localStorage.getItem('lang')));
		} else {
			setLang(0);
		}
    window.dispatchEvent(new Event("newRoute"));		
  }, []);

  return(
    <div style={{backgroundColor:'#F7FBF5'}}>
      <div className="documentos">
      <img className="documentosbanner" src={ lang ? require("../../assets/images/banner-docs-ingles.png") : require("../../assets/images/banner-docs.png") } alt="banner"/><br/><br/>
        
        <Tabs className="documentostabs" defaultIndex={state ? state.from : 0}>
          <TabList>
            <Tab>{ lang ? "Certification" : "Certificação" }</Tab>
            <Tab>{ lang ? "Training" : "Capacitação" }</Tab>
            <Tab>{ lang ? "Technical Analysis" : "Análise Técnica" }</Tab>
          </TabList>

          <TabPanel>
            <table className="documentostable">
              <tbody>
                <tr>
                  <td><Link to="/documentos/4c"><h3>4C</h3></Link></td>
                  <td><Link to="/documentos/rainforest-alliance"><h3>Rainforest Alliance 2020</h3></Link></td>
                </tr>
                <tr>  
                  <td><Link to="/documentos/cafe-practices"><h3>C.A.F.E. Practices - Starbucks</h3></Link></td>
                  <td><Link to="/documentos/organico-brasil"><h3>{ lang ? "Organic Brazil" : "Orgânico Brasil" }</h3></Link></td>
                </tr>
                <tr>
                  
                  <td><Link to="/documentos/unidade-armazenadora-ambiente-natural"><h3>{ lang ? "Storage Unit in Natural Environment" : "Unidade Armazenadora em Ambiente Natural" }</h3></Link></td>
                  <td><Link to="/documentos/formularios"><h3>{ lang ? "Forms" : "Formulários" }</h3></Link></td>
                </tr>
                <tr>
                  <td><Link to="/documentos/global-gap"><h3>GLOBALG.A.P.</h3></Link></td>
                  <td><Link to="/documentos/politicas"><h3>{ lang ? "Policies" : "Políticas" }</h3></Link></td>
                </tr>
                <tr>
                  <td colSpan={2}><Link to="/documentos/procedimentos"><h3>{ lang ? "Procedures" : "Procedimentos" }</h3></Link></td>
                </tr>
              </tbody>
            </table>
          </TabPanel>
          <TabPanel>
            <table className="documentostable">
              <tbody>
                <tr>
                  <td><Link to="/documentos/cafe-gelado"><h3>{ lang ? "Iced Coffee" : "Café Gelado" }</h3></Link></td>
                </tr>
              </tbody>
            </table>
          </TabPanel>
          <TabPanel>
            <table className="documentostable">
              <tbody>
                <tr>
                  <td><Link to="/documentos/regulamento-classificacao-cafe"><h3>{ lang ? "IN 08 Regulation for Coffee Classification" : "IN 08 Regulamento para Classificação de Café" }</h3></Link></td>
                </tr>
                <tr>
                  <td><Link to="/documentos/protocolo-degustacao-sca"><h3>{ lang ? "SCA Tasting Protocol" : "Protocolo Degustação SCA" }</h3></Link></td>
                </tr>
                <tr>
                  <td><Link to="/documentos/formulario-laboratorio"><h3>{ lang ? "Laboratory Form" : "Formulário Laboratório" }</h3></Link></td>
                </tr>
              </tbody>
            </table>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  )
}

export default Documentos;
