import { useState, useEffect } from 'react';

function EmissaoLaudos(){

	const [lang, setLang] = useState();

	useEffect(()  => {
		const checkLang = function() {
			if(localStorage.getItem('lang')) {
				setLang(JSON.parse(localStorage.getItem('lang')));
			} else {
				setLang(0);
			}
		}

		window.addEventListener('storage', checkLang)

		return () => {
			window.removeEventListener('storage', checkLang)
		}
  }, []);

  useEffect(()  => {		
		if(localStorage.getItem('lang')) {
			setLang(JSON.parse(localStorage.getItem('lang')));
		} else {
			setLang(0);
		}
    window.dispatchEvent(new Event("openModal"));	
    window.dispatchEvent(new Event("newRoute"));		
  }, []);

  const [nome, setNome] = useState('');
  const [telefone, setTelefone] = useState('');
  const [email, setEmail] = useState('');
  const [assunto] = useState('Emissão de Laudos COB e SCA');
  const [mensagem, setMensagem] = useState('');

  const maskTel = (v) => {
    v = v.replace(/\D/g, "")

    v = v.replace(/(\d{2})(\d)/,"($1) $2")
    v = v.replace(/(\d)(\d{4})$/,"$1-$2")

    setTelefone(v)
  }

  const sendEmail = async (e) => {
    e.preventDefault();

    if(nome === '' || telefone === '' || email === '' || assunto === '' || mensagem === ''){
      const alertMessage = lang ? "Please fill in all fields" : "Por favor preencha todos os campos";
      alert(alertMessage);
      return;
    }

    var destinatario = 'comercial4@savassicertificadora.com.br,comercial@savassicertificadora.com.br,fonteboag@savassicertificadora.com.br,dantasr@savassicertificadora.com.br';

    const templateParams = {
      origemMensagem: "Novo pedido de orçamento para Emissão de Laudos COB e SCA",
      nome: nome,
      telefone: telefone,
      email: email,
      assunto: assunto,
      mensagem: mensagem,
      destinatario: destinatario
    }

    window.dispatchEvent(new Event("closeModal"));
    setNome('');
    setTelefone('');
    setEmail('');
    setMensagem('');

    let response = await fetch("/contato", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8"
      },
      body: JSON.stringify(templateParams)
    })

    let result = await response.json();

    if(result.status==="200 OK") {
      console.log("EMAIL ENVIADO", response.status, response.text);
      alert(lang ? 'Budget request sent successfully!' : 'Solicitação de orçamento enviada com sucesso!')
    } else {
      console.log("ERRO: ", result.status);
    }

  }

  return(
    <div style={{backgroundColor:'#F7FBF5'}}>
      <div className="analisesubpages">
        <img className="analisesubpagesbanner" src={lang ? require("../../../../assets/images/emissao-laudos-banner-ingles.png") : require("../../../../assets/images/emissao-laudos-banner.png")} alt="banner"/><br/><br/>
        <p>
          {
            lang ?  
            "For those looking to value their coffees, Savassi Análise Técnica offers the issuance of physical and sensory reports using the COB (Official Brazilian Classification) and SCA (Specialty Coffee Association) methodologies, guaranteeing the authenticity of the information and real characteristics of the products analyzed." : 
            "Para aqueles que buscam valorizar seus cafés, a Savassi Análise Técnica oferece a emissão de laudos físicos e sensoriais utilizando as metodologias COB (Classificação Oficial Brasileira) e SCA (Specialty Coffee Association), garantindo a autenticidade das informações e características reais dos produtos analisados."
          }
        </p><br/>
        <p>
          {
            lang ?  
            "The COB Report is intended for producers who wish to determine the specific category and classification of their coffees. Savassi Análise Técnica provides a detailed report and issues a formalized document to the producer, describing the real characteristics observed in their coffee. This report can be used as a quality instrument in commercial negotiation processes, especially when it highlights favorable quality characteristics." : 
            "O Laudo COB, destina-se a produtores que desejam determinar a categoria e classificação específicas de seus cafés. A Savassi Análise Técnica fornece um laudo detalhado e emite um documento formalizado ao produtor, descrevendo as características reais observadas em seu café. Esse laudo pode ser utilizado como um instrumento de qualidade em processos de negociação comercial, especialmente quando evidencia características favoráveis de qualidade."
          }
        </p><br/>
        <p>
          {
            lang ?  
            "The SCA Report follows the protocol of the Specialty Coffee Association (SCA), largely sharing the objectives of the COB Report, but with different evaluation criteria. The characteristics analyzed are essentially the same in both reports, differing due to the evaluation methodology and the observed equivalences." : 
            "Já o Laudo SCA segue o protocolo da Specialty Coffee Association (SCA), compartilhando em grande parte os objetivos do Laudo COB, mas com critérios avaliativos distintos. As características analisadas são essencialmente as mesmas em ambos os laudos, diferenciando-se pela metodologia de avaliação e as equivalências observadas."
          }
        </p><br/>
        <p>
          {
            lang ?  
            "In the SCA Report, the sensory classification is not based on beverage nomenclatures, as in the COB Report, but rather on a numerical classification on a scale of up to 100 points. This final score reflects the sum of all attributes evaluated by a referee, following the SCA protocol criteria. These attributes indicate the quality indices for each characteristic analyzed, such as fragrance, aroma, flavor, finish, acidity, body, uniformity, balance, sweetness, absence of defects and the overall grade assigned by the Q-Grader expert referee." : 
            "No Laudo SCA, a classificação sensorial não é baseada em nomenclaturas de bebidas, como no Laudo COB, mas sim em uma classificação numérica em uma escala de até 100 pontos. Essa pontuação final reflete a soma de todos os atributos avaliados por um árbitro, seguindo os critérios do protocolo da SCA. Esses atributos indicam os índices de qualidade em cada característica analisada, como fragrância, aroma, sabor, finalização, acidez, corpo, uniformidade, balanço, doçura, ausência de defeitos e a nota geral atribuída pelo árbitro especialista Q-Grader."
          }
        </p><br/>
        <p>
          {
            lang ?  
            "The objective is to formally evaluate and document all observations about the inspected coffee. The SCA Report is regulated by the association itself, recognized as a global authority on specialty coffees, intended for the rigorous evaluation of coffees intended for international marketing of specialty coffees." : 
            "O objetivo é avaliar e documentar formalmente todas as observações sobre o café inspecionado. O Laudo SCA é regulamentado pela própria associação, reconhecida como autoridade mundial em cafés especiais, destinando-se à avaliação rigorosa de cafés destinados à comercialização internacional de cafés especiais."
          }
        </p><br/>
        <p>
          {
            lang ?  
            "Savassi Análise Técnica stands out for its total transparency, impartiality and absence of conflicts of interest, as it does not sell or produce coffee." : 
            "A Savassi Análise Técnica se destaca pela total transparência, imparcialidade e ausência de conflitos de interesses, uma vez que não comercializa nem produz café."
          }
        </p><br/>
        <p>
          {
            lang ?  
            "Generally, these reports can be accompanied by an ART – Technical Responsibility Note, providing legal support for both parties and exercising an arbitration function in cases of non-compliance in coffee marketing processes." : 
            "Geralmente, esses laudos podem ser acompanhados por uma ART – Anotação de Responsabilidade Técnica, proporcionando respaldo jurídico para ambas as partes e exercendo função arbitral em casos de descumprimento em processos de comercialização do café."
          }
        </p><br/>
        <p>
          {
            lang ?  
            "When receiving clients interested in issuing reports for their coffees, Savassi carries out an initial critical analysis to identify the purpose of the evaluation and advises on which report will meet their needs. If you are a coffee producer or trader, especially in the specialty coffee line, contact Savassi Agronegócio through its Technical Analysis team to clarify all doubts and determine the best Technical Report that will confirm the quality of your coffee, aligned with your market and trade objectives." : 
            "Ao receber clientes interessados em emitir laudos para seus cafés, a Savassi realiza uma análise crítica inicial para identificar a finalidade da avaliação e orienta sobre qual laudo atenderá às suas necessidades. Se você é produtor ou comerciante de cafés, especialmente na linha de cafés especiais, entre em contato com a Savassi Agronegócio por meio de sua equipe de Análise Técnica para esclarecer todas as dúvidas e determinar o melhor Laudo Técnico que confirmará a qualidade do seu café, alinhado aos seus objetivos de mercado e comércio."
          }
        </p><br/><br/>

        <div className="capacitacaodetalhes">
          <button id="myBtn">{ lang ? "Request a Quote" : "Solicitar Orçamento" }</button><br/><br/>
        </div>

        <div id="myModal" className="modal">
          <div className="modal-content">
            <div className="modal-header">
              <span className="close">&times;</span>
              <h2>{ lang ? "Request a Quote" : "Solicite um orçamento" }</h2>
            </div>
            <div className="modal-body">
              <form onSubmit={sendEmail}>
                <input type="text" name="nome" onChange={(e) => setNome(e.target.value)} value={nome} placeholder={ lang ? "Enter your name" : "Informe seu nome" }></input> <br/>
                <input type="tel" name="telefone" onChange={(e) => maskTel(e.target.value)} value={telefone} placeholder={ lang ? "Enter your phone" : "Informe seu telefone" }></input> <br/>
                <input type="email" name="email" onChange={(e) => setEmail(e.target.value)} value={email} placeholder={ lang ? "Enter your e-mail" : "Informe seu e-mail" }></input> <br/>
                <textarea name="mensagem" rows="5" onChange={(e) => setMensagem(e.target.value)} value={mensagem} placeholder={ lang ? "Enter your message" : "Informe sua mensagem" }></textarea> <br/>
                <input id="modalBtn" type="submit" name="enviar" value={ lang ? "Send" : "Enviar" }></input> <br/>
              </form>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default EmissaoLaudos;