import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Ouvidoria(){

	const [lang, setLang] = useState();

	useEffect(()  => {
		const checkLang = function() {
			if(localStorage.getItem('lang')) {
				setLang(JSON.parse(localStorage.getItem('lang')));
			} else {
				setLang(0);
			}
		}

		window.addEventListener('storage', checkLang)

		return () => {
			window.removeEventListener('storage', checkLang)
		}
  }, []);

  useEffect(()  => {		
		if(localStorage.getItem('lang')) {
			setLang(JSON.parse(localStorage.getItem('lang')));
		} else {
			setLang(0);
		}
    window.dispatchEvent(new Event("newRoute"));		
  }, []);

  const [escopo, setEscopo] = useState('');
  const [nome, setNome] = useState('');
  const [telefone, setTelefone] = useState('');
  const [email, setEmail] = useState('');
  const [assunto, setAssunto] = useState('');
  const [mensagem, setMensagem] = useState('');

  const maskTel = (v) => {
    v = v.replace(/\D/g, "")

    v = v.replace(/(\d{2})(\d)/,"($1) $2")
    v = v.replace(/(\d)(\d{4})$/,"$1-$2")

    setTelefone(v)
  }

  const sendEmail = async (e) => {
    e.preventDefault();

    if(escopo === '' || assunto === '' || mensagem === ''){
      const alertMessage = lang ? "Please fill in all required fields" : "Por favor preencha todos os campos obrigatórios.";
      alert(alertMessage);
      return;
    }

    var destinatario = 'fonteboag@savassicertificadora.com.br,dantasr@savassicertificadora.com.br,vilhenam@savassicertificadora.com.br';
    setAssunto(assunto + ' no escopo: ' + escopo)

    const templateParams = {
      origemMensagem: "Nova mensagem do portal da Ouvidoria.",
      escopo: escopo,
      nome: nome,
      telefone: telefone,
      email: email,
      assunto: assunto,
      mensagem: mensagem,
      destinatario: destinatario
    }

    setEscopo('');
    setNome('');
    setTelefone('');
    setEmail('');
    setAssunto('');
    setMensagem('');

    let response = await fetch("/contato", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8"
      },
      body: JSON.stringify(templateParams)
    })

    let result = await response.json();

    if(result.status==="200 OK") {
      console.log("EMAIL ENVIADO", response.status, response.text);
      alert(lang ? 'Message sent successfully!' : 'Mensagem enviada com sucesso!')
    } else {
      console.log("ERRO: ", result.status);
    }

  }

  return(
    <div style={{backgroundColor:'#F7FBF5'}}>
      <div className="ouvidoria">
        <img className="ouvidoriabanner" src={ lang ? require("../../assets/images/banner-ouvidoria-ingles.png") : require("../../assets/images/banner-ouvidoria.png") } alt="banner"/><br/><br/>
        <h4>{ lang ? "Complaints, Appeals and Protests? Talk to us!" : "Reclamações, Apelações e Denúncias? Fale com a Gente!" }</h4>
        <h4>{ lang ? "Address: Bernardo Guimarães Alves Street, 428 - 2nd Floor - Downtown, Patrocínio - MG, Zip Code: 38740-028" : "Endereço: R. Bernardo Guimarães Alves, 428 - 2º Andar - Centro, Patrocínio - MG, CEP: 38740-028" }</h4>
        <h4>{ lang ? "Phone: +55 34 3832-0530" : "Telefone: +55 34 3832-0530" }</h4>

        <div className="ouvidoriaform">
          <span><b>{ lang ? "All fields with * are mandatory." : "Todos os campos com * são obrigatórios." }</b></span><br/><br/>
          <form onSubmit={sendEmail}>
            <label>
              <b>{ lang ? "Scope*:" : "Escopo*:" }</b><br/>
              <select className="ouvidoriaformselect" name="escopo" onChange={(e) => setEscopo(e.target.value)} value={escopo}>
                <option value="">{ lang ? "Select a Scope" : "Selecione um Escopo" }</option>
                <option value="Unidade Armazenadora em Ambiente Natural">{ lang ? "Storage Unit in Natural Environment" : "Unidade Armazenadora em Ambiente Natural" }</option>
                <option value="4C">4C</option>
                <option value="UTZ">UTZ</option>
                <option value="C.A.F.E. Practices">C.A.F.E. Practices</option>
                <option value="Orgânico Brasil">{ lang ? "Organic Brazil" : "Orgânico Brasil" }</option>
                <option value="Rainforest Alliance">Rainforest Alliance</option>
                <option value="Departamento Administrativo">{ lang ? "Administrative Department" : "Departamento Administrativo" }</option>
                <option value="Departamento Financeiro">{ lang ? "Finance Department" : "Departamento Financeiro" }</option>
                <option value="Departamento de Qualidade">{ lang ? "Quality Department" : "Departamento de Qualidade" }</option>
                <option value="Departamento Comercial">{ lang ? "Commercial Department" : "Departamento Comercial" }</option>
              </select>
            </label><br/><br/>
            <label>
              <b>{ lang ? "Name:" : "Nome:" }</b><br/>
              <input className="ouvidoriaforminput" type="text" name="nome" onChange={(e) => setNome(e.target.value)} value={nome} placeholder={ lang ? "Enter your name" : "Informe seu nome" }/>
            </label><br/><br/>
            <label>
              <b>{ lang ? "Phone:" : "Celular/Telefone:" }</b><br/>
              <input className="ouvidoriaforminput" type="tel" name="telefone" onChange={(e) => maskTel(e.target.value)} value={telefone} placeholder={ lang ? "Enter your phone number" : "Informe seu telefone" }/>
            </label><br/><br/>
            <label>
              <b>E-mail:</b><br/>
              <input className="ouvidoriaforminput" type="email" name="email" onChange={(e) => setEmail(e.target.value)} value={email} placeholder={ lang ? "Enter your e-mail" : "Informe seu e-mail" }/>
            </label><br/><br/>
            <label>
              <b>{ lang ? "Subject*:" : "Assunto*:" }</b><br/>
              <select className="ouvidoriaformselect" name="assunto" onChange={(e) => setAssunto(e.target.value)} value={assunto}>
                <option value="">{ lang ? "Select the Subject" : "Selecione o Assunto" }</option>
                <option value="Dúvida">{ lang ? "Question" : "Dúvida" }</option>
                <option value="Reclamação">{ lang ? "Complaint" : "Reclamação" }</option>
                <option value="Sugestão">{ lang ? "Suggestion" : "Sugestão" }</option>
                <option value="Apelação">{ lang ? "Appeal" : "Apelação" }</option>
                <option value="Denúncia">{ lang ? "Protest" : "Denúncia" }</option>
              </select>
            </label><br/><br/>
            <label>
              <b>{ lang ? "Message*:" : "Mensagem*:" }</b><br/>
              <textarea className="ouvidoriaformtextarea" rows="5" name="mensagem" onChange={(e) => setMensagem(e.target.value)} value={mensagem} placeholder={ lang ? "Enter your message" : "Informe a mensagem" }></textarea>
            </label><br/>
            <input className="ouvidoriaenviar" type="submit" value={ lang ? "Send Message" : "Enviar Mensagem" }/>
          </form>
        </div><br/>

        <div className="ouvidoriainfo">
          <span>{ lang ? "For complaints or appeals, follow the Procedure and Form links below:" : "Para reclamações ou apelações, segue abaixo os links do Procedimento e Formulário:" }</span><br/>
          <Link to="../../assets/documents/Ouvidoria.pdf" download="Ouvidoria.pdf" target="_blank" rel="noreferrer">
            { lang ? "OMBUDSMAN PROCEDURE" : "PROCEDIMENTO OUVIDORIA" } 
          </Link><br/>
          <Link to="../../assets/documents/Reclamacoes_Apelacoes_Denuncias.pdf" download="Reclamacoes_Apelacoes_Denuncias.pdf" target="_blank" rel="noreferrer">
            { lang ? "COMPLAINT, APPEAL OR PROTEST PROCEDURE" : "PROCEDIMENTO RECLAMAÇÃO APELAÇÃO DENUNCIA" }
          </Link><br/>
          <Link to="../../assets/documents/FORM-17-RECL_APEL_DENU-REV09.docx" download="FORM-17-RECL_APEL_DENU-REV09.docx" target="_blank" rel="noreferrer">
            { lang ? "COMPLAINT, APPEAL OR PROTEST FORM" : "FORMULÁRIO DE RECLAMAÇÃO, APELAÇÃO OU DENÚNCIA" }
          </Link><br/>
        </div>

      </div>
    </div>
  )
}

export default Ouvidoria;