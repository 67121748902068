import { useState, useEffect } from 'react';

function CertificadoGlobalGAP(){

	const [lang, setLang] = useState();

	useEffect(()  => {
		const checkLang = function() {
			if(localStorage.getItem('lang')) {
				setLang(JSON.parse(localStorage.getItem('lang')));
			} else {
				setLang(0);
			}
		}

		window.addEventListener('storage', checkLang)

		return () => {
			window.removeEventListener('storage', checkLang)
		}
  }, []);

  useEffect(()  => {		
		if(localStorage.getItem('lang')) {
			setLang(JSON.parse(localStorage.getItem('lang')));
		} else {
			setLang(0);
		}		
    window.dispatchEvent(new Event("openModal"));	
    window.dispatchEvent(new Event("newRoute"));	
  }, []);

  const [nome, setNome] = useState('');
  const [telefone, setTelefone] = useState('');
  const [email, setEmail] = useState('');
  const [assunto] = useState('Global G.A.P.');
  const [mensagem, setMensagem] = useState('');

  const maskTel = (v) => {
    v = v.replace(/\D/g, "")

    v = v.replace(/(\d{2})(\d)/,"($1) $2")
    v = v.replace(/(\d)(\d{4})$/,"$1-$2")

    setTelefone(v)
  }

  const sendEmail = async (e) => {
    e.preventDefault();

    if(nome === '' || telefone === '' || email === '' || assunto === '' || mensagem === ''){
      const alertMessage = lang ? "Please fill in all fields" : "Por favor preencha todos os campos";
      alert(alertMessage);
      return;
    }

    var destinatario = 'comercial@savassicertificadora.com.br,fonteboag@savassicertificadora.com.br,dantasr@savassicertificadora.com.br';

    const templateParams = {
      origemMensagem: "Novo pedido de orçamento para Global G.A.P.",
      nome: nome,
      telefone: telefone,
      email: email,
      assunto: assunto,
      mensagem: mensagem,
      destinatario: destinatario
    }

    window.dispatchEvent(new Event("closeModal"));
    setNome('');
    setTelefone('');
    setEmail('');
    setMensagem('');

    let response = await fetch("/contato", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8"
      },
      body: JSON.stringify(templateParams)
    })

    let result = await response.json();

    if(result.status==="200 OK") {
      console.log("EMAIL ENVIADO", response.status, response.text);
      alert(lang ? 'Budget request sent successfully!' : 'Solicitação de orçamento enviada com sucesso!')
    } else {
      console.log("ERRO: ", result.status);
    }

  }

  return(
    <div style={{backgroundColor:'#F7FBF5'}}>
      <div className="certificadorasubpages">
        <img className="certificadorasubpagesbanner" src={require("../../../../assets/images/globalgap-banner.png")} alt="banner"/><br/><br/>
        <p>
          {
            lang ?  
            "The Savassi Certification body is accredited by the General Accreditation Coordination (CGCRE) of the National Institute of Metrology, Quality and Technology (INMETRO), in accordance with the requirements established in ABNT NBR ISO/IEC 17065:2013 and the current NIT-DICOR 024, and accredited with the GlobalG.A.P. Certification Program since October 2022 to carry out the GlobalG.A.P conformity assessment. – Integrated Agricultural Guarantee – (IFA) Fruits and Vegetables, version 5.4.1." : 
            "O organismo Savassi Certificadora é acreditado pela Coordenação Geral de Acreditação (CGCRE) do Instituto Nacional de Metrologia, Qualidade e Tecnologia (INMETRO), conforme requisitos estabelecidos na ABNT NBR ISO/IEC  17065:2013 e na NIT-DICOR 024 vigente, e credenciado junto ao Programa de Certificação GlobalG.A.P. desde outubro de 2022 para a realização da avaliação da conformidade da GlobalG.A.P. – Garantia Agrícola Integrada – (IFA) Frutas e Vegetais, versão 5.4.1. "
          }<br/><br/>
          {
            lang ?  
            "The GlobalG.A.P. certification governed by two documents, namely the General Regulation – Rules for Individual Producers and the Certification Body Rules in current version, which establishes the certification rules for any party seeking GlobalG.A.P. certification." : 
            "A certificação GlobalG.A.P. regida por dois documentos, sendo eles Regulamento Geral – Regras para Produtores Individuas e Regras do Organismo de Certificação em versão vigente, a qual estabelece as regras de certificação para qualquer parte que busca certificação GlobalG.A.P."
          }<br/><br/>
          {
            lang ?  
            "GlobalG.A.P. is the internationally recognized standard for agricultural production. Its flagship product is the result of years of intense research and collaboration with industry experts, producers and retailers around the world. Aiming at safe and sustainable agricultural production to benefit farmers, retailers and consumers around the world." : 
            "A GlobalG.A.P. é o padrão reconhecido internacionalmente para produção agrícola. Seu produto principal é o resultado de anos de intensa pesquisa e colaboração com especialistas do setor, produtores e varejistas em todo o mundo. Com objetivo na produção agrícola segura e sustentável para beneficiar agricultores, varejistas e consumidores em todo o mundo."
          }<br/><br/>
          {
            lang ?  
            "The GlobalG.A.P. certification covers:" : 
            "A certificação GlobalG.A.P. abrange:"
          }<br/><br/>
          <ul>
            <li>
              {
                lang ?  
                "Food safety and traceability;" : 
                "Segurança alimentar e rastreabilidade;"
              }
            </li>
            <li>
              {
                lang ?  
                "Environment (including biodiversity);" : 
                "Meio ambiente (incluindo biodiversidade);"
              }
            </li>
            <li>
              {
                lang ?  
                "Health, safety and well-being of workers;" : 
                "Saúde, segurança e bem-estar dos trabalhadores;"
              }
            </li>
            <li>
              {
                lang ?  
                "Animal welfare;" : 
                "Bem estar animal;"
              }
            </li>
            <li>
              {
                lang ?  
                "Includes Integrated Crop Management (ICM), Integrated Pest Control (IPC), Quality Management System (QMS) and Hazard Analysis and Critical Control Points (HACCP)." : 
                "Inclui Manejo Integrado de Culturas (ICM), Controle Integrado de Pragas (IPC), Sistema de Gestão de Qualidade (SGQ) e Análise de Perigos e Pontos Críticos de Controle (HACCP)."
              }
            </li>
          </ul><br/><br/>
          {
            lang ?  
            "The GlobalG.A.P. standard requires, among other things, greater production efficiency. Improves business performance and reduces waste of vital resources. It also requires an overall approach to agriculture that is based on best practices for future generations." : 
            "O padrão GlobalG.A.P. exige, entre outras coisas, maior eficiência na produção. Melhora o desempenho dos negócios e reduz o desperdício de recursos vitais. Requer também uma abordagem geral à agricultura que se baseie nas melhores práticas para as gerações vindouras."
          }<br/><br/>
          {
            lang ?  
            "Reach the highest standard of agricultural quality with GlobalG.A.P. certification!" : 
            "Alcance o mais alto padrão de qualidade agrícola com a certificação GlobalG.A.P.!"
          }
        </p><br/><br/>

        <div className="capacitacaodetalhes">
          <button id="myBtn">{ lang ? "Request a Quote" : "Solicitar Orçamento" }</button><br/><br/>
        </div>

        <div id="myModal" className="modal">
          <div className="modal-content">
            <div className="modal-header">
              <span className="close">&times;</span>
              <h2>{ lang ? "Request a Quote" : "Solicite um orçamento" }</h2>
            </div>
            <div className="modal-body">
              <form onSubmit={sendEmail}>
                <input type="text" name="nome" onChange={(e) => setNome(e.target.value)} value={nome} placeholder={ lang ? "Enter your name" : "Informe seu nome" }></input> <br/>
                <input type="tel" name="telefone" onChange={(e) => maskTel(e.target.value)} value={telefone} placeholder={ lang ? "Enter your phone" : "Informe seu telefone" }></input> <br/>
                <input type="email" name="email" onChange={(e) => setEmail(e.target.value)} value={email} placeholder={ lang ? "Enter your e-mail" : "Informe seu e-mail" }></input> <br/>
                <textarea name="mensagem" rows="5" onChange={(e) => setMensagem(e.target.value)} value={mensagem} placeholder={ lang ? "Enter your message" : "Informe sua mensagem" }></textarea> <br/>
                <input id="modalBtn" type="submit" name="enviar" value={ lang ? "Send" : "Enviar" }></input> <br/>
              </form>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default CertificadoGlobalGAP;