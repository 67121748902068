import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useState, useEffect } from 'react';

function CursoTorra(){

	const [lang, setLang] = useState();

	useEffect(()  => {
		const checkLang = function() {
			if(localStorage.getItem('lang')) {
				setLang(JSON.parse(localStorage.getItem('lang')));
			} else {
				setLang(0);
			}
		}

		window.addEventListener('storage', checkLang)

		return () => {
			window.removeEventListener('storage', checkLang)
		}
  }, []);

  useEffect(()  => {		
		if(localStorage.getItem('lang')) {
			setLang(JSON.parse(localStorage.getItem('lang')));
		} else {
			setLang(0);
		}
    window.dispatchEvent(new Event("openModalQGraderTorra"));	
    window.dispatchEvent(new Event("newRoute"));
    window.dispatchEvent(new Event("resize"));
  }, []);

  const [curso] = useState('Curso de Torra');
  const [nome, setNome] = useState('');
  const [nomeCracha, setNomeCracha] = useState('');
  const [endereco, setEndereco] = useState('');
  const [numero, setNumero] = useState('');
  const [bairro, setBairro] = useState('');
  const [cep, setCep] = useState('');
  const [cidadeEstado, setCidadeEstado] = useState('');
  const [telefone, setTelefone] = useState('');
  const [email, setEmail] = useState('');
  const [telefone2, setTelefone2] = useState('');
  const [rg, setRg] = useState('');
  const [cpf, setCpf] = useState('');
  const [dataNascimento, setDataNascimento] = useState('');
  const [escolaridade, setEscolaridade] = useState('');
  const [razaoSocial, setRazaoSocial] = useState('');
  const [enderecoCnpj, setEnderecoCnpj] = useState('');
  const [numeroCnpj, setNumeroCnpj] = useState('');
  const [bairroCnpj, setBairroCnpj] = useState('');
  const [cidadeEstadoCnpj, setCidadeEstadoCnpj] = useState('');
  const [telefoneCnpj, setTelefoneCnpj] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [inscricaoEstadual, setInscricaoEstadual] = useState('');
  const [formaPagamento, setFormaPagamento] = useState('');
  const [responsavelPagamento, setResponsavelPagamento] = useState('');

  const handleTel1Input = (v) => {
    v = maskTel(v)
    setTelefone(v)
  }

  const handleTel2Input = (v) => {
    v = maskTel(v)
    setTelefone2(v)
  }

  const handleTelCnpjInput = (v) => {
    v = maskTel(v)
    setTelefoneCnpj(v)
  }

  const maskTel = (v) => {
    v = v.replace(/\D/g, "")

    v = v.replace(/(\d{2})(\d)/,"($1) $2")
    v = v.replace(/(\d)(\d{4})$/,"$1-$2")

    return v
  }

  const maskData = (v) => {
    v = v.replace(/\D/g, "")

    v = v.replace(/(\d{2})(\d)/,"$1/$2") 
    v = v.replace(/(\d{2})(\d)/,"$1/$2") 

    setDataNascimento(v)
  }

  const maskCep = (v) => {
    v = v.replace(/\D/g, "")

    v = v.replace(/\D/g,'')
    v = v.replace(/(\d{5})(\d)/,'$1-$2')

    setCep(v)

  }

  const maskCpf = (v) => {
    v = v.replace(/\D/g, "")
  
    v = v.replace(/(\d{3})(\d)/, "$1.$2")
    v = v.replace(/(\d{3})(\d)/, "$1.$2")
    v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2")

    setCpf(v)

  }

  const maskCnpj = (v) => {
    v = v.replace(/\D/g, "")

    v = v.replace(/^(\d{2})(\d)/, "$1.$2")
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
    v = v.replace(/\.(\d{3})(\d)/, ".$1/$2")
    v = v.replace(/(\d{4})(\d)/, "$1-$2")
    
    setCnpj(v)

  }

  const sendEmail = async (e) => {
    e.preventDefault();

    if(curso === '' || nome === '' || nomeCracha === '' || endereco === '' || numero === '' || bairro === '' || 
    cep === '' || cidadeEstado === '' || telefone === '' || email === '' || rg === '' || cpf === '' || 
    dataNascimento === '' || escolaridade === '' || formaPagamento === '' || responsavelPagamento === ''){
      const alertMessage = lang ? "Please, fill in all fields with an asterisk" : "Por favor, preencha todos os campos com asterisco";
      alert(alertMessage);
      return;
    }

    var destinatario = "cursos@savassiagronegocio.com.br,comercial@savassicertificadora.com.br,fonteboag@savassicertificadora.com.br,dantasr@savassicertificadora.com.br";

    const templateParams = {
      destinatario: destinatario,
      origemMensagem: "Novo pedido de matrícula no Curso de Torra",
      curso: curso,
      nome: nome,
      nomeCracha: nomeCracha,
      endereco: endereco,
      numero: numero,
      bairro: bairro,
      cep: cep,
      cidadeEstado: cidadeEstado,
      telefone: telefone,
      email: email,
      telefone2: telefone2,
      rg: rg,
      cpf: cpf,
      dataNascimento: dataNascimento,
      escolaridade: escolaridade,
      razaoSocial: razaoSocial,
      enderecoCnpj: enderecoCnpj,
      numeroCnpj: numeroCnpj,
      bairroCnpj: bairroCnpj,
      cidadeEstadoCnpj: cidadeEstadoCnpj,
      telefoneCnpj: telefoneCnpj,
      cnpj: cnpj,
      inscricaoEstadual: inscricaoEstadual,
      valor: 'US$700,00',
      formaPagamento: formaPagamento,
      responsavelPagamento: responsavelPagamento
    }

    window.dispatchEvent(new Event("closeModal"));
    setNome('');
    setNomeCracha('');
    setEndereco('');
    setNumero('');
    setBairro('');
    setCep('');
    setCidadeEstado('');
    setTelefone('');
    setEmail('');
    setTelefone2('');
    setRg('');
    setCpf('');
    setDataNascimento('');
    setEscolaridade('');
    setRazaoSocial('');
    setEnderecoCnpj('');
    setNumeroCnpj('');
    setBairroCnpj('');
    setCidadeEstadoCnpj('');
    setTelefoneCnpj('');
    setCnpj('');
    setInscricaoEstadual('');
    setFormaPagamento('');
    setResponsavelPagamento('');

    let response = await fetch("/cursos", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8"
      },
      body: JSON.stringify(templateParams)
    })

    let result = await response.json();

    if(result.status==="200 OK") {
      console.log("EMAIL ENVIADO", response.status, response.text);
      alert(lang ? 'Enrollment sent successfully!' : 'Matrícula enviada com sucesso!')
    } else {
      console.log("ERRO: ", result.status);
    }

  }

  return(
    <div style={{backgroundColor:'#F7FBF5'}}>
      <div className="capacitacaosubpages">
        <img className="capacitacaosubpagesbanner" src={ lang ? require("../../../../assets/images/banner-Torra-ing.png") : require("../../../../assets/images/banner-Torra.png") } alt="banner"/><br/><br/>
        <h2>{ lang ? "The Course" : "O Curso" }</h2><br/>
        <p>
          {
            lang ?  
            "All the complexity in the flavors and aromas of coffee is developed during the bean roasting process. Variations in grain production directly impact the final result of the drink and from this it is possible to control and highlight the best that exists for each coffee during roasting." : 
            "Toda a complexidade existente nos sabores e aromas do café é desenvolvida durante o processo de torra dos grãos. As variações na produção dos grãos impactam diretamente no resultado final da bebida e a partir disso é possível controlar e ressaltar o quê de melhor existe para cada café durante a torra."
          }<br/><br/>
          {
            lang ?  
            "A composição química dos grãos crus de café tem papel importante neste processo." : 
            "A composição química dos grãos crus de café tem papel importante neste processo."
          }<br/><br/>
          {
            lang ?  
            "With the advanced roasting course, the student will understand in theory the reactions that occur, the possibilities of each coffee bean and thus build specific roasting profiles and blend composition in practice." : 
            "Com o curso avançado de torra, o aluno entenderá na teoria as reações que ocorrem, as possibilidades de cada grão de café e assim construir na prática perfis de torra específicos e composição de blends."
          }
        </p><br/><br/>
        <div className="capacitacaodetalhes">
          <h2>{ lang ? "Investiment" : "Investimento" }</h2>
          <h2 id="money">US$ 700,00</h2><br/>
          <p><b>
            { 
              lang ? 
              "Course price: US$ 700.00 (Tourism Dollar)" : 
              "Preço do curso: US$ 700,00 (Dólar Turismo)"
            }<br/><br/>
            { 
              lang ? 
              "Cash via PIX or Cash: US$ 650.00" : 
              "À vista via PIX ou Dinheiro: US$ 650,00"
            }<br/><br/>
            { 
              lang ? 
              "Up to 3x installments on Credit Card: US$ 650.00" : 
              "Parcelado no Cartão de Crédito em até 3x vezes: US$ 650,00"
            }<br/><br/>
            { 
              lang ? 
              "Installments on the Credit Card in up to 10x (interest on the card itself)" : 
              "Parcelado no Cartão de Crédito em até 10x vezes (juros do próprio cartão)"
            }<br/><br/>
            { 
              lang ? 
              "Note: The dollar value is quoted on the day of payment, the value can be up or down" : 
              "Observação:  O valor do dólar é cotado no dia do pagamento, o valor pode ser para mais ou para menos"
            }<br/><br/>
          </b></p>
          <button id="myBtn">{ lang ? "Enroll" : "Matricular" }</button><br/><br/>
        </div><br/>

        <div id="myModal" className="modal">
          <div className="modal-content">
            <div className="modal-header">
              <span className="close">&times;</span>
              <h2>{ lang ? "Enroll now!" : "Matricule-se agora!" }</h2>
            </div>
            <div className="modal-body">
              <form onSubmit={sendEmail}>
                <span><strong>{ lang ? "CONTRACTOR'S Personal Data" : "Dados Pessoais do CONTRATANTE" }</strong></span>
                <input type="text" name="curso" disabled value={curso}></input> <br/>
                <input type="text" name="nome" onChange={(e) => setNome(e.target.value)} value={nome} placeholder={ lang ? "Full name*" : "Nome completo*" }></input> <br/>
                <input type="text" name="nomeCracha" onChange={(e) => setNomeCracha(e.target.value)} value={nomeCracha} placeholder={ lang ? "Name highlighted on badge*" : "Nome em destaque no crachá*" }></input> <br/>
                <input type="text" name="endereco" onChange={(e) => setEndereco(e.target.value)} value={endereco} placeholder={ lang ? "Address*" : "Endereço*" }></input> <br/>
                <input type="text" name="numero" onChange={(e) => setNumero(e.target.value)} value={numero} placeholder={ lang ? "Address number*" : "Número*" }></input> <br/>
                <input type="text" name="bairro" onChange={(e) => setBairro(e.target.value)} value={bairro} placeholder={ lang ? "Neighbourhood*" : "Bairro*" }></input> <br/>
                <input type="text" name="cep" onChange={(e) => maskCep(e.target.value)} value={cep} placeholder={ lang ? "Postal Code*" : "CEP*" }></input> <br/>
                <input type="text" name="cidadeEstado" onChange={(e) => setCidadeEstado(e.target.value)} value={cidadeEstado} placeholder={ lang ? "City and State*" : "Cidade e Estado*" }></input> <br/>
                <input type="tel" name="telefone" onChange={(e) => handleTel1Input(e.target.value)} value={telefone} placeholder={ lang ? "Phone number*" : "Telefone*" }></input> <br/>
                <input type="email" name="email" onChange={(e) => setEmail(e.target.value)} value={email} placeholder={ lang ? "E-mail*" : "E-mail*" }></input> <br/>
                <input type="tel" name="telefone2" onChange={(e) => handleTel2Input(e.target.value)} value={telefone2} placeholder={ lang ? "Phone number of a relative or someone close" : "Telefone de um parente ou alguém próximo" }></input> <br/>
                <input type="text" name="rg" onChange={(e) => setRg(e.target.value)} value={rg} placeholder={ lang ? "RG*" : "RG*" }></input> <br/>
                <input type="text" name="cpf" onChange={(e) => maskCpf(e.target.value)} value={cpf} placeholder={ lang ? "CPF*" : "CPF*" }></input> <br/>
                <input type="text" name="dataNascimento" onChange={(e) => maskData(e.target.value)} value={dataNascimento} placeholder={ lang ? "Birth Date*" : "Data de Nascimento*" }></input> <br/>
                <input type="text" name="escolaridade" onChange={(e) => setEscolaridade(e.target.value)} value={escolaridade} placeholder={ lang ? "Education*" : "Escolaridade*" }></input> <br/>
                <span><strong>{ lang ? "Company Data - for LEGAL ENTITY" : "Dados da Empresa - para PESSOA JURÍDICA" }</strong></span>
                <input type="text" name="razaoSocial" onChange={(e) => setRazaoSocial(e.target.value)} value={razaoSocial} placeholder={ lang ? "Corporate name" : "Razão Social" }></input> <br/>
                <input type="text" name="enderecoCnpj" onChange={(e) => setEnderecoCnpj(e.target.value)} value={enderecoCnpj} placeholder={ lang ? "Address" : "Endereço" }></input> <br/>
                <input type="text" name="numeroCnpj" onChange={(e) => setNumeroCnpj(e.target.value)} value={numeroCnpj} placeholder={ lang ? "Address number" : "Número" }></input> <br/>
                <input type="text" name="bairroCnpj" onChange={(e) => setBairroCnpj(e.target.value)} value={bairroCnpj} placeholder={ lang ? "Neighbourhood" : "Bairro" }></input> <br/>
                <input type="text" name="cidadeEstadoCnpj" onChange={(e) => setCidadeEstadoCnpj(e.target.value)} value={cidadeEstadoCnpj} placeholder={ lang ? "City and State" : "Cidade e Estado" }></input> <br/>
                <input type="tel" name="telefoneCnpj" onChange={(e) => handleTelCnpjInput(e.target.value)} value={telefoneCnpj} placeholder={ lang ? "Phone number" : "Telefone" }></input> <br/>
                <input type="text" name="cnpj" onChange={(e) => maskCnpj(e.target.value)} value={cnpj} placeholder={ lang ? "CNPJ" : "CNPJ" }></input> <br/>
                <input type="text" name="inscricaoEstadual" onChange={(e) => setInscricaoEstadual(e.target.value)} value={inscricaoEstadual} placeholder={ lang ? "State registration" : "Inscrição Estadual" }></input> <br/>
                <span><strong>{ lang ? "Payment" : "Pagamento" }</strong></span>
                <input type="text" name="valor" disabled value='US$700,00'></input> <br/>
                <input type="text" name="formaPagamento" onChange={(e) => setFormaPagamento(e.target.value)} value={formaPagamento} placeholder={ lang ? "Payment Method*" : "Forma de Pagamento*" }></input> <br/>
                <input type="text" name="responsavelPagamento" onChange={(e) => setResponsavelPagamento(e.target.value)} value={responsavelPagamento} placeholder={ lang ? "Responsible for payment*" : "Responsável pelo pagamento*" }></input> <br/>
                <input id="modalBtn" type="submit" name="enviar" value={ lang ? "Send" : "Enviar" }></input> <br/>
              </form>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>

        <div className="capacitacaosubpages-instrutores">
          <h2>{ lang ? "Instructor" : "Instrutor" }</h2>
          <div className="owl-carousel" id="owl-carousel1">
            <div className="carousel-item">
              <img id="foto6" className="direcaofoto" src={require("../../../../assets/images/rolando.png")} alt="Rolando Cañas Martinez"/>
            </div>
          </div>
        </div><br/><br/><br/>

        <div className="capacitacaosubpages-instrutores">
          <h2>{ lang ? "Technical Assistants" : "Assistentes Técnicos" }</h2>
          <div className="owl-carousel" id="owl-carousel2">
            <div className="carousel-item">
              <img id="foto4" className="direcaofoto" src={require("../../../../assets/images/nivaldo.png")} alt="Nivaldo"/>
            </div>
            <div className="carousel-item">
              <img id="foto5" className="direcaofoto" src={require("../../../../assets/images/luis.png")} alt="Luis"/>
            </div>
          </div>
        </div><br/><br/>

        <Tabs className="capacitacaotabs">
          <TabList>
            <Tab>{ lang ? "Methodology" : "Metodologia" }</Tab>
            <Tab>{ lang ? "Activities" : "Atividades" }</Tab>
            <Tab>{ lang ? "Target Audience" : "Público-Alvo" }</Tab>
            <Tab>{ lang ? "Workload" : "Carga-Horária" }</Tab>
            <Tab>{ lang ? "Where to Stay" : "Onde Hospedar" }</Tab>
          </TabList>

          <TabPanel className="capacitacaotabpanel">
            <br/>
            <p>
              <li>
                { lang ? "Roasting fundamentals;" : "Fundamentos de torra;" }
              </li>
              <li>
                { lang ? "Roasting styles;" : "Estilos de torra;" }
              </li>
              <li>
                { lang ? "Types of heat and roasting equipment;" : "Tipos de calor e equipamentos de torra;" }
              </li>
              <li>
                { lang ? "Current equipment;" : "Equipamentos atuais;" }
              </li>
              <li>
                { lang ? "Variables that affect the roasting process (the roasting process);" : "Variáveis que afetam o processo de torra (o processo de torrefação);" }
              </li>
              <li>
                { lang ? "The precursors of aroma and flavor in coffee;" : "Os precursores do aroma e do sabor no café;" }
              </li>
              <li>
                { lang ? "Main components of coffee beans;" : "Principais componentes do grão no café;" }
              </li>
              <li>
                { lang ? "Coffee processing;" : "Processamento do café;" }
              </li>
              <li>
                { lang ? "Moisture of green coffee;" : "Umidade do café verde;" }
              </li>
              <li>
                { lang ? "Grain density;" : "Densidade do grão;" }
              </li>
              <li>
                { lang ? "Grain size;" : "Tamanho do grão;" }
              </li>
              <li>
                { lang ? "Quantity of physical defects of the grain;" : "Quantidade de defeitos físicos do grão;" }
              </li>
              <li>
                { lang ? "Variables that affect the aroma and flavor of coffee in the roasting process;" : "Variáveis que afetam o aroma e sabor do café no processo de torra;" }
              </li>
              <li>
                { lang ? "Roasting level - Intensity;" : "Nível de torra - Intensidade;" }
              </li>
              <li>
                { lang ? "General aroma and flavor profiles of the main levels of roasting;" : "Perfis gerais de aroma e sabor dos principais níveis de torra;" }
              </li>
              <li>
                { lang ? "Roasting time;" : "Tempo de torra;" }
              </li>
              <li>
                { lang ? "How to toast coffees from different origins;" : "Como torrar cafés de diferentes origens;" }
              </li>
              <li>
                { lang ? "What happens in the roasting process?;" : "O que acontece no processo de torra?;" }
              </li>
              <li>
                { lang ? "Caramelization stages;" : "Estágios de caramelização;" }
              </li>
              <li>
                { lang ? "Changes in characteristics in the coffee roasting process;" : "Mudanças de características no processo de torra do café;" }
              </li>
              <li>
                { lang ? "Quality problems in the roasting process;" : "Problemas de qualidade no processo de  torra;" }
              </li>
              <li>
                { lang ? "Physical evaluation of roasted and ground coffee;" : "Avaliação física do café torrado e moído;" }
              </li>
              <li>
                { lang ? "Sensation in taste;" : "Sensação no paladar;" }
              </li>
              <li>
                { lang ? "Basic roasting profiles;" : "Perfis básicos de torrefação;" }
              </li>
              <li>
                { lang ? "Accentuating the body and the sweetness of coffee;" : "Acentuamento do corpo e a doçura do café;" }
              </li>
              <li>
                { lang ? "Analysis of Brazilian coffee (tips for roasting it);" : "Análise do café brasileiro (dicas para torra-lo);" }
              </li>
              <li>
                { lang ? "Aspects of good preparation;" : "Aspectos de boa preparação;" }
              </li>
              <li>
                { lang ? "Extraction process;" : "Processo de extração;" }
              </li>
              <li>
                { lang ? "Control chart of coffee extraction (how to use it)." : "Gráfico de controle da extração de café (como usa-lo)." }
              </li>
            </p><br/>
          </TabPanel>
          <TabPanel className="capacitacaotabpanel">
            <br/>
            <p>
              <li>
                { lang ? "Opening;" : "Abertura;" }
              </li>
              <li>
                { lang ? "Introduction to the roasting process and prior presentation of equipment;" : "Introdução sobre o processo de torra e prévia de apresentação de equipamentos;" }
              </li>
              <li>
                { lang ? "Presentation of the theme: FACTORS THAT INFLUENCE IN THE TEST PROCESS;" : "Apresentação do tema: FATORES QUE INFLUENCIAM NO PROCESSO DE TESTE;" }
              </li>
              <li>
                { lang ? "Sensory skills test;" : "Teste de habilidades sensoriais;" }
              </li>
              <li>
                { lang ? "Olfactory groups;" : "Grupos olfativos;" }
              </li>
              <li>
                { lang ? "Sensory triangulation with coffee;" : "Triangulação sensorial com café;" }
              </li>
              <li>
                { lang ? "Acid levels, body and residual taste test;" : "Níveis ácidos, teste de sabor corporal e residual;" }
              </li>
              <li>
                { lang ? "Calibration test 4 coffees (four coffees to toast);" : "Prova de calibração 4 cafés (quatro cafés para brindar);" }
              </li>
              <li>
                { lang ? "Physical evaluation of green coffee;" : "Avaliação física do café verde;" }
              </li>
              <li>
                { lang ? "Four coffees to be roasted;" : "Quatro cafés para serem torrados;" }
              </li>
              <li>
                { lang ? "Coffee test Part 1;" : "Teste de café Parte 1;" }
              </li>
              <li>
                { lang ? "Presentation of the theme: TEST DEVELOPMENT;" : "Apresentação do tema: DESENVOLVIMENTO DO TESTE;" }
              </li>
              <li>
                { lang ? "Organic acid testing;" : "Teste de ácidos orgânicos;" }
              </li>
              <li>
                { lang ? "What kind of acid does your coffee have ?;" : "Que tipo de ácido o seu café tem?;" }
              </li>
              <li>
                { lang ? "Evaluation of roasted samples;" : "Avaliação de amostras torradas;" }
              </li>
              <li>
                { lang ? "Color Agtron and Brix;" : "Cor Agtron e Brix;" }
              </li>
              <li>
                { lang ? "Tasting of roasting types: correct, clear, cooked, fast and dark;" : "Degustação de tipos de torrefação: correta, clara, cozida, rápida e escura;" }
              </li>
              <li>
                { lang ? "Roast development;" : "Desenvolvimento de assados;" }
              </li>
              <li>
                { lang ? "Source profile search;" : "Pesquisa do perfil de origem;" }
              </li>
              <li>
                { lang ? "Tasting of roasted coffees;" : "Degustação de cafés torrados;" }
              </li>
              <li>
                { lang ? "Change assessment: Agtron, Brix, TDS;" : "Avaliação de mudança: Agtron, Brix, TDS;" }
              </li>
              <li>
                { lang ? "Roast development;" : "Desenvolvimento de assados;" }
              </li>
              <li>
                { lang ? "Basic curve, proper curve and a surprise coffee;" : "Curva básica, curva própria e um café surpresa;" }
              </li>
              <li>
                { lang ? "Evaluation of the roasting process;" : "Avaliação do processo de torrefação;" }
              </li>
              <li>
                { lang ? "Replicate curve;" : "Replicar curva;" }
              </li>
              <li>
                { lang ? "Extraction methods (Filtered);" : "Métodos de extração (Filtrado);" }
              </li>
              <li>
                { lang ? "Preparation of coffee drink in different methods (Beer);" : "Elaboração de bebida de café em diferentes métodos (Cerveja);" }
              </li>
              <li>
                { lang ? "Contest;" : "Concurso;" }
              </li>
              <li>
                { lang ? "The perfect mix." : "O mix perfeito." }
              </li>
            </p><br/>
          </TabPanel>
          <TabPanel className="capacitacaotabpanel">
            <br/>
            <p>
              <li>
                { lang ? "Agricultural cooperatives" : "Cooperativas agrícolas" }
              </li>
              <li>
                { lang ? "General stores" : "Armazéns gerais" }
              </li>
              <li>
                { lang ? "Agricultural technicians" : "Técnicos agrícolas" }
              </li>
              <li>
                { lang ? "Coffee growers" : "Cafeicultores" }
              </li>
              <li>
                { lang ? "Professionals in the agricultural sector" : "Profissionais do setor agrícola" }
              </li>
              <li>
                { lang ? "Students" : "Estudantes" }
              </li>
              <li>
                { lang ? "Hospitality" : "Hotelaria" }
              </li>
              <li>
                { lang ? "Coffee shops" : "Cafeterias" }
              </li>
              <li>
                { lang ? "Supermarket sector" : "Setor supermercadista" }
              </li>
              <li>
                { lang ? "Big consumers" : "Grandes consumidores" }
              </li>
              <li>
                { lang ? "General industries in cafes" : "Indústrias gerais em cafés" }
              </li>
            </p><br/>
          </TabPanel>
          <TabPanel className="capacitacaotabpanel">
            <br/>
            <span>{ lang ? "32 hours." : "32 horas." }</span>
            <br/><br/>
          </TabPanel>
          <TabPanel className="capacitacaotabpanel">
            <br/>
            <h4>{ lang ? "Partner Hotels:" : "Hotéis Parceiros:" }</h4>
            <Link to="https://www.ourocerradohotel.com.br/" target="_blank">Ouro Cerrado Hotel</Link><br/>
            <Link to="http://www.minashotel.com.br/" target="_blank">Minas Hotel</Link><br/>
            <span>Center Hotel</span><br/>
            <span>Guines Hotel</span><br/>
            <span>Grande Avenida</span><br/><br/>
            <h4>{ lang ? "Other Hotels:" : "Outros Hotéis:" }</h4>
            <Link to="http://bit.ly/2sISAXG" target="_blank">{ lang ? "Patrocínio hotels in Trivago" : "Hotéis de Patrocínio em Trivago" }</Link><br/>
            <br/>
          </TabPanel>
        </Tabs><br/>

        <div id="myModal6" className="modalquemsomos">
          <div className="modalquemsomos-content">
            <div className="modalquemsomos-header">
              <span className="close">&times;</span>
            </div>
            <div className="modalquemsomos-body">
              <img src={require("../../../../assets/images/rolando.png")} alt="Rolando Cañas Martinez"/>
              <p><b>{ lang ? "Name: " : "Nome: " }</b>Rolando Cañas Martinez</p>
              <p>
                <b>{ lang ? "Role: " : "Cargo: " }</b>
                {
                  lang ? 
                  "Q-Grader and Course Instructor" : 
                  "Q-Grader e Instrutor de cursos"
                }
              </p>
              <p>
                <b>{ lang ? "Description:" : "Descrição:" }</b>
                <li>{ lang ? "Born in El Salvador" : "Natural de El Salvador" }</li>
                <li>{ lang ? "Currently living in Honduras" : "Atualmente vivendo em Honduras" }</li>
                <li>{ lang ? "Coffee Quality Control Technician" : "Técnico em Controle de Qualidade do Café" }</li>
                <li>{ lang ? "Q Arabica Grader Instructor certified by the Coffee Quality Institute" : "Instrutor Q Arábica Grader certificado pelo Instituto de Qualidade do Café" }</li>
                <li>{ lang ? "Extensive experience in sensory evaluation of coffee and training of producers and tasters" : "Ampla experiência na avaliação sensorial do café e treinamento de produtores e provadores" }</li>
                <li>{ lang ? "Over fifty Q Arabic courses developed around the world, including Asia, Europe, Central and South America" : "Mais de cinquenta cursos Q Arábica desenvolvidos em todo o mundo, incluindo Ásia, Europa, América Central e do Sul" }</li>
              </p>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>      

        <div id="myModal4" className="modalquemsomos">
          <div className="modalquemsomos-content">
            <div className="modalquemsomos-header">
              <span className="close">&times;</span>
            </div>
            <div className="modalquemsomos-body">
              <img src={require("../../../../assets/images/nivaldo.png")} alt="Nivaldo Henrique das Neves Caixeta"/>
              <p><b>{ lang ? "Name: " : "Nome: " }</b>Nivaldo Henrique das Neves Caixeta</p>
              <p>
                <b>{ lang ? "Role: " : "Cargo: " }</b>
                {
                  lang ? 
                  "Commercial and Courses Coordinator" : 
                  "Coordenador Comercial e Cursos"
                }
              </p>
              <p>
                <b>{ lang ? "Description:" : "Descrição:" }</b>
                <li>{ lang ? "Born in Patrocínio/MG" : "Natural de Patrocínio/MG" }</li>
                <li>{ lang ? "Environmental Manager (Unicesumar University)" : "Gestor Ambiental (Universidade Unicesumar)" }</li>
                <li>{ lang ? "Postgraduate in Digital Marketing (Unicesumar University)" : "Pós em Marketing Digital (Universidade Unicesumar)" }</li>
              </p>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
        
        <div id="myModal5" className="modalquemsomos">
          <div className="modalquemsomos-content">
            <div className="modalquemsomos-header">
              <span className="close">&times;</span>
            </div>
            <div className="modalquemsomos-body">
              <img src={require("../../../../assets/images/luis.png")} alt="Luís Carlos dos Anjos"/>
              <p><b>{ lang ? "Name: " : "Nome: " }</b>Luís Carlos dos Anjos</p>
              <p>
                <b>{ lang ? "Role: " : "Cargo: " }</b>{ lang ? "Classifier" : "Classificador" }
              </p>
              <p>
                <b>{ lang ? "Description:" : "Descrição: " }</b>
                <li>{ lang ? "Born in Patrocínio/MG" : "Natural de Patrocínio/MG" }</li>
              </p>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default CursoTorra;