import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';

function Home() {

	useEffect(() => {
		window.dispatchEvent(new Event('resize'));
	}, []);

	const [lang, setLang] = useState();
	const [modalVisited, setModalVisited] = useState();

	useEffect(() => {
		const checkLang = function () {
			if (localStorage.getItem('lang')) {
				setLang(JSON.parse(localStorage.getItem('lang')));
			} else {
				setLang(0);
			}
		}

		const modalVisited = sessionStorage.getItem('modalVisited')

		if (!modalVisited) {
			sessionStorage.setItem('modalVisited', JSON.stringify(1))
			setModalVisited('block')
		} else {
			setModalVisited('none')
		}

		window.addEventListener('storage', checkLang)

		return () => {
			window.removeEventListener('storage', checkLang)
		}
	}, []);

	useEffect(() => {
		if (localStorage.getItem('lang')) {
			setLang(JSON.parse(localStorage.getItem('lang')));
		} else {
			setLang(0);
		}
		window.dispatchEvent(new Event("openModalHome"));
	}, []);

	return (
		<main>
			<section className="mainbanner1">
				<div className="main-banner">
					<div className="banner"><Link to="/certificadora"><img src={require("../../assets/images/savassi_certificadora.jpg")} alt="Savassi Certificadora" /></Link></div>
					<div className="overbanner"><Link to="/certificadora"><img src={require("../../assets/images/Certificadora.png")} alt="Savassi Certificadora" /></Link></div>
				</div>
				<div className="main-banner">
					<div className="banner"><Link to="/capacitacao"><img src={require("../../assets/images/savassi_capacitacao.jpg")} alt="Savassi Capacitação" /></Link></div>
					<div className="overbanner"><Link to="/certificadora"><img src={require("../../assets/images/Capacitação.png")} alt="Savassi Certificadora" /></Link></div>
				</div>
				<div className="main-banner">
					<div className="banner"><Link to="/analise-tecnica"><img src={require("../../assets/images/analise_tecnica.jpg")} alt="Savassi Análise Técnica" /></Link></div>
					<div className="overbanner"><Link to="/certificadora"><img src={require("../../assets/images/Análise.png")} alt="Savassi Certificadora" /></Link></div>
				</div>
			</section>

			<div id="myModalHome" className="modalhome" style={{ display: `${modalVisited}` }}>
				<div className="modalhome-content">
					<div className="modalhome-header">
						<span className="close">&times;</span>
					</div>
					<div className="modal-body">
						<Link to="/fale-conosco"><img style={{ width: 'calc(100px + 30vw)' }} src={lang ? require("../../assets/images/campanha-fale-conosco-new-site-ing.png") : require("../../assets/images/campanha-fale-conosco-new-site.png")} alt="popup fale conosco" /></Link>
					</div>
					<div className="modalhome-footer"></div>
				</div>
			</div>

			<section id="credenciais">
				<span className="cred-text">{lang ? "WE ARE ACCREDITED TO ISSUE THE BEST CERTIFICATES IN THE WORLD" : "SOMOS CREDENCIADOS A EMITIR OS MELHORES CERTIFICADOS DO MUNDO"}</span>
				<div className="owl-carousel">
					<div className="carousel-item">
						<Link to="https://www.4c-services.org/" target="_blank" rel="noopener noreferrer">
							<img className="carousel-credenciais" src={require("../../assets/images/4c.png")} alt="Logo 4C Certification" />
						</Link>
					</div>
					<div className="carousel-item">
						<Link to="https://www.rainforest-alliance.org/pt-br/" target="_blank" rel="noopener noreferrer">
							<img src={require("../../assets/images/rainforest-alliance.png")} alt="Logo Rainforest Alliance" />
						</Link>
					</div>
					<div className="carousel-item">
						<Link to="http://www.conab.gov.br/" target="_blank" rel="noopener noreferrer">
							<img src={require("../../assets/images/savassi-agronegocio-certificado.png")} alt="Logo Savassi Agronegócio Certificado" />
						</Link>
					</div>
					<div className="carousel-item">
						<Link to="https://www.coffeeinstitute.org/" target="_blank" rel="noopener noreferrer">
							<img src={require("../../assets/images/arabica-grader.png")} alt="Logo Arabica Grader" />
						</Link>
					</div>
					<div className="carousel-item">
						<Link to="https://www.organicosdobrasil.com/" target="_blank" rel="noopener noreferrer">
							<img src={require("../../assets/images/produto-organico-brasil.png")} alt="Logo Produto Orgânico Brasil" />
						</Link>
					</div>
					<div className="carousel-item">
						<Link to="https://www.globalgap.org/" target="_blank" rel="noopener noreferrer">
							<img src={require("../../assets/images/global-gap.png")} alt="Logo Global G.A.P." />
						</Link>
					</div>
					<div className="carousel-item">
						<Link to="https://www.starbucksathome.com/br/blog/cafe-practices.html" target="_blank" rel="noopener noreferrer">
							<img src={require("../../assets/images/cafe-practices.png")} alt="Logo Cafe Practices" />
						</Link>
					</div>
				</div>
			</section>

		</main>
	)
}

export default Home;