import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Documentos4C(){

	const [lang, setLang] = useState();

	useEffect(()  => {
		const checkLang = function() {
			if(localStorage.getItem('lang')) {
				setLang(JSON.parse(localStorage.getItem('lang')));
			} else {
				setLang(0);
			}
		}

		window.addEventListener('storage', checkLang)

		return () => {
			window.removeEventListener('storage', checkLang)
		}
  }, []);

  useEffect(()  => {		
		if(localStorage.getItem('lang')) {
			setLang(JSON.parse(localStorage.getItem('lang')));
		} else {
			setLang(0);
		}	
    window.dispatchEvent(new Event("newRoute"));	
  }, []);

  return(
    <div style={{backgroundColor:'#F7FBF5'}}>
      <div className="documentossubpages">
        <div>
        <Link className="fa fa-chevron-left fa-2x" to="/documentos" state={{from: 0}}/><br/>
        <h1>4C</h1><br/>
        </div>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "BPM 4C CHAIN OF CUSTODY v4.0" : "BPM 4C CADEIA DE CUSTÓDIA v4.0" }</b><br/>
          <Link to="../../../../assets/documents/4C_Business-Partner-Map_Cadeia_v4.xlsx" download="4C_Business-Partner-Map_Cadeia_v4.xlsx" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "BPM 4C COMMON CODE v4.0" : "BPM 4C CÓDIGO COMUM v4.0" }</b><br/>
          <Link to="../../../../assets/documents/4C_Business-Partner-Map_Código_v4.xlsx" download="4C_Business-Partner-Map_Código_v4.xlsx" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "CHECKLIST 4C CHAIN OF CUSTODY v4.0" : "CHECKLIST 4C CADEIA DE CUSTÓDIA v4.0" }</b><br/>
          <Link to="../../../../assets/documents/4C_Audit-Checklist_CadeiaCustódia_v4.xlsx" download="4C_Audit-Checklist_CadeiaCustódia_v4.xlsx" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "CHECKLIST 4C COMMON CODE v4.0" : "CHECKLIST 4C CÓDIGO COMUM v4.0" }</b><br/>
          <Link to="../../../../assets/documents/4C_Audit-Checklist_CódigoComum_v4.xlsx" download="4C_Audit-Checklist_CódigoComum_v4.xlsx" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "4C CODE OF CONDUCT v4.0" : "CÓDIGO DE CONDUTA 4C v4.0" }</b><br/>
          <Link to="../../../../assets/documents/4C-Code-of-Conduct_v4.pdf" download="4C-Code-of-Conduct_v4.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "PESTICIDES LIST 4C v4.0" : "LISTA PESTICIDAS 4C v4.0" }</b><br/>
          <Link to="../../../../assets/documents/4C-Pesticide-Lists_v4.pdf" download="4C-Pesticide-Lists_v4.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "STEP BY STEP 4C CERTIFICATION CHAIN OF CUSTODY" : "PASSO A PASSO CERTIFICAÇÃO 4C CADEIA DE CUSTÓDIA" }</b><br/>
          <Link to="../../../../assets/documents/4C-Chain-of-Custody_Step-by-Step_PT.pdf" download="4C-Chain-of-Custody_Step-by-Step_PT.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "STEP BY STEP 4C CERTIFICATION COMMON CODE" : "PASSO A PASSO CERTIFICAÇÃO 4C CÓDIGO COMUM" }</b><br/>
          <Link to="../../../../assets/documents/4C-Code-Certification_Step-by-Step_PT.pdf" download="4C-Code-Certification_Step-by-Step_PT.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "4C SYSTEM REGULATION" : "REGULAMENTO DO SISTEMA 4C" }</b><br/>
          <Link to="../../../../assets/documents/4C-System-Regulations_v4.pdf" download="4C-System-Regulations_v4.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "4C CERTIFICATION BODY REGULATIONS v4.0" : "REGULAMENTOS CORPO DE CERTIFICAÇÃO 4C v4.0" }</b><br/>
          <Link to="../../../../assets/documents/4C-Certification-Body-Regulations_v4.pdf" download="4C-Certification-Body-Regulations_v4.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "CERTIFICATION REQUEST - 4C" : "SOLICITAÇÃO DE CERTIFICAÇÃO - 4C" }</b><br/>
          <Link to="../../../../assets/documents/FORM-61-SOL_CERT_4C-REV-06.docx" download="FORM-61-SOL_CERT_4C-REV-06.docx" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/><br/>
      </div>
    </div>
  )
}

export default Documentos4C;