import { useState, useEffect } from 'react';

function OrganicoBrasil(){

	const [lang, setLang] = useState();

	useEffect(()  => {
		const checkLang = function() {
			if(localStorage.getItem('lang')) {
				setLang(JSON.parse(localStorage.getItem('lang')));
			} else {
				setLang(0);
			}
		}

		window.addEventListener('storage', checkLang)

		return () => {
			window.removeEventListener('storage', checkLang)
		}
  }, []);

  useEffect(()  => {		
		if(localStorage.getItem('lang')) {
			setLang(JSON.parse(localStorage.getItem('lang')));
		} else {
			setLang(0);
		}
    window.dispatchEvent(new Event("openModal"));	
    window.dispatchEvent(new Event("newRoute"));			
  }, []);

  const [nome, setNome] = useState('');
  const [telefone, setTelefone] = useState('');
  const [email, setEmail] = useState('');
  const [assunto] = useState('Orgânico Brasil');
  const [mensagem, setMensagem] = useState('');

  const maskTel = (v) => {
    v = v.replace(/\D/g, "")

    v = v.replace(/(\d{2})(\d)/,"($1) $2")
    v = v.replace(/(\d)(\d{4})$/,"$1-$2")

    setTelefone(v)
  }

  const sendEmail = async (e) => {
    e.preventDefault();

    if(nome === '' || telefone === '' || email === '' || assunto === '' || mensagem === ''){
      const alertMessage = lang ? "Please fill in all fields" : "Por favor preencha todos os campos";
      alert(alertMessage);
      return;
    }

    var destinatario = 'comercial@savassicertificadora.com.br,fonteboag@savassicertificadora.com.br,dantasr@savassicertificadora.com.br';

    const templateParams = {
      origemMensagem: "Novo pedido de orçamento para Orgânico Brasil",
      nome: nome,
      telefone: telefone,
      email: email,
      assunto: assunto,
      mensagem: mensagem,
      destinatario: destinatario
    }

    window.dispatchEvent(new Event("closeModal"));
    setNome('');
    setTelefone('');
    setEmail('');
    setMensagem('');

    let response = await fetch("/contato", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8"
      },
      body: JSON.stringify(templateParams)
    })

    let result = await response.json();

    if(result.status==="200 OK") {
      console.log("EMAIL ENVIADO", response.status, response.text);
      alert(lang ? 'Budget request sent successfully!' : 'Solicitação de orçamento enviada com sucesso!')
    } else {
      console.log("ERRO: ", result.status);
    }

  }

  return(
    <div style={{backgroundColor:'#F7FBF5'}}>
      <div className="certificadorasubpages">
        <img className="certificadorasubpagesbanner" src={require("../../../../assets/images/organico-brasil-banner.png")} alt="banner"/><br/><br/>
        <p>
          {
            lang ?  
            "The Savassi Certification body is accredited by the Ministry of Agriculture, Livestock and Supply (MAPA) and accredited by the General Accreditation Coordination (CGCRE) of the National Institute of Metrology, Quality and Technology (INMETRO), in accordance with the requirements established in ABNT NBR ISO/IEC 17065:2013 and in the current NIT-DICOR 024, authorized since May 17, 2017 to carry out conformity assessment in the scope of Organic Products through audit (CERTIFICATION BY AUDIT)." : 
            "O organismo Savassi Certificadora é credenciado pelo Ministério da Agricultura, Pecuária e Abastecimento (MAPA) e acreditado pela Coordenação Geral de Acreditação (CGCRE) do Instituto Nacional de Metrologia, Qualidade e Tecnologia (INMETRO), conforme requisitos estabelecidos na ABNT NBR ISO/IEC  17065:2013 e na NIT-DICOR 024 vigente, autorizada desde 17 de maio de 2017 para a realização da avaliação da conformidade no escopo de Produtos Orgânicos por meio de auditoria (CERTIFICAÇÃO POR AUDITORIA)."
          }<br/><br/>
          {
            lang ?  
            "The certification of Organic Products (CPO) is governed by Normative Instruction No. 19, of May 28, 2009, which establishes organic quality control and information mechanisms to be followed by individuals or legal entities, under public or private law, that produce, transport, sell or store organic products, or that are responsible for assessing organic compliance." : 
            "A certificação de Produtos Orgânicos (CPO) é regida pela Instrução Normativa Nº 19, de 28 de maio de 2009, a qual estabelece os mecanismos de controle e informação da qualidade orgânicos a serem seguidos pelas pessoas físicas ou jurídicas, de direito público ou privado, que produzam, transportem, comercializem ou armazenem produtos orgânicos, ou que sejam responsáveis pela avaliação da conformidade orgânica."
          }<br/><br/>
          {
            lang ?  
            "The certification of Organic Products is compulsory and was established by Law 10,831/2003 and regulated by Decree 6,323/2007. For a product to be labeled and sold in Brazil as \"organic\", it is mandatory for the production unit to go through one of the 3 (three) organic quality assurance mechanisms – audit certification, participatory certification or be linked to a control organization Social. This obligation is based on risks to consumer safety or the environment." : 
            "A certificação de Produtos Orgânicos é compulsória e foi estabelecida pela Lei 10.831/2003 e regulamentada pelo Decreto 6.323/2007. Para que um produto seja rotulado e vendido no Brasil como \"orgânico\" é obrigatório que a unidade de produção passe por um dos 3 (três) mecanismos de garantia da qualidade orgânica – certificação por auditoria, certificação participativa ou estar vinculada à uma organização de controle social. Esta obrigatoriedade está baseada nos riscos à segurança do consumidor ou ao meio ambiente."
          }<br/><br/>
          {
            lang ?  
            "The AUDIT CERTIFICATION mechanism consists of an assessment of compliance with organic production requirements, where they must be fully complied with. This assessment includes periodic inspections or audits at the organic production unit, as well as soil or product tests, if necessary." : 
            "O mecanismo de CERTIFICAÇÃO POR AUDITORIA consiste numa avaliação da conformidade para com os requisitos da produção orgânica, onde os mesmos devem ser integralmente cumpridos. Esta avaliação engloba inspeções ou auditorias periódicas na unidade de produção orgânica, bem como ensaios de solo ou de produto, caso seja necessário."
          }<br/><br/>
          {
            lang ?  
            "The certification process for Organic Products in Brazil involves several rigorous steps to guarantee the quality and authenticity of these foods." : 
            "O processo de certificação de Produtos Orgânicos no Brasil envolve diversas etapas rigorosas para garantir a qualidade e a autenticidade desses alimentos."
          }<br/><br/>
          {
            lang ?  
            "Certification Steps:" : 
            "Etapas da Certificação:"
          }<br/><br/>
          <ol>
            <li>
              {
                lang ?  
                "Requirements: The producer must meet the criteria established by the Ministry of Agriculture, Livestock and Supply (MAPA) for organic production, such as the use of natural methods and the prohibition of synthetic pesticides." : 
                "Requisitos: O produtor deve atender aos critérios estabelecidos pelo Ministério da Agricultura, Pecuária e Abastecimento (MAPA) para produção orgânica, como o uso de métodos naturais e a proibição de agrotóxicos sintéticos."
              }
            </li>
            <li>
              {
                lang ?  
                "Registration: The producer must register with an Organic Conformity Assessment Body (OAC), which will be responsible for auditing and certifying its production." : 
                "Inscrição: O produtor deve se registrar em um Organismo de Avaliação da Conformidade Orgânica (OAC), que será responsável por auditar e certificar sua produção."
              }
            </li>
            <li>
              {
                lang ?  
                "Audit: The OAC carries out regular visits to the property to check that all standards are being followed correctly." : 
                "Auditoria: O OAC realiza visitas regulares à propriedade para verificar se todas as normas estão sendo seguidas corretamente."
              }
            </li>
            <li>
              {
                lang ?  
                "Certification: After passing the audit, the producer receives the certification seal, which proves compliance with organic standards." : 
                "Certificação: Após a aprovação da auditoria, o produtor recebe o selo de certificação, que comprova a conformidade com as normas orgânicas."
              }
            </li>
          </ol><br/><br/>
          {
            lang ?  
            "Advantages to the Producer:" : 
            "Vantagens ao Produtor:"
          }<br/><br/>
          <ol>
            <li>
              {
                lang ?  
                "Sales Market: The organic products market in Brazil is constantly growing. In addition to the domestic market, there are also export opportunities to countries that value certified organic products. Supermarkets, organic fairs, restaurants and healthy eating programs are some of the sales channels where organic products are well accepted." : 
                "Mercado de Venda: O mercado de produtos orgânicos no Brasil está em crescimento constante. Além do mercado interno, há também oportunidades de exportação para países que valorizam produtos orgânicos certificados. Supermercados, feiras orgânicas, restaurantes e programas de alimentação saudável são alguns dos canais de venda onde os produtos orgânicos têm boa aceitação."
              }
            </li>
            <li>
              {
                lang ?  
                "Value addition: Certified organic products have a higher market value, which can result in better profit margins for producers." : 
                "Agregação de valor: Os produtos orgânicos certificados têm um valor de mercado mais elevado, o que pode resultar em melhores margens de lucro para os produtores."
              }
            </li>
            <li>
              {
                lang ?  
                "Recognition and trust: The certification seal is a recognized symbol that conveys confidence to consumers, strengthening the producer's reputation." : 
                "Reconhecimento e confiança: O selo de certificação é um símbolo reconhecido que transmite confiança aos consumidores, fortalecendo a reputação do produtor."
              }
            </li>
            <li>
              {
                lang ?  
                "Financial Return: Although the initial investment to obtain certification can be challenging, certified organic producers have the potential to achieve a significant financial return in the long term. Organic products are in high demand and consumers are willing to pay more for healthy, sustainable foods." : 
                "Retorno Financeiro: Embora o investimento inicial para obter a certificação possa ser um desafio, os produtores orgânicos certificados têm potencial para obter um retorno financeiro significativo a longo prazo. Os produtos orgânicos estão em alta demanda e os consumidores estão dispostos a pagar mais por alimentos saudáveis e sustentáveis."
              }
            </li>
          </ol><br/><br/>
          {
            lang ?  
            "Certifying your products as organic can open doors to the market, bring recognition and add value to your business. Invest in certification and take advantage of the opportunities offered by Organic Products in Brazil." : 
            "Certificar seus produtos como orgânicos pode abrir portas para o mercado, trazer reconhecimento e agregar valor ao seu negócio. Invista na certificação e aproveite as oportunidades oferecidas pelos Produtos Orgânicos no Brasil."
          }<br/><br/>
          {
            lang ?  
            "Under Brazilian legislation, an organic product, whether fresh or processed, is considered to be one that is obtained in an organic agricultural production system or comes from a sustainable extractive process that is not harmful to the local ecosystem." : 
            "Pela legislação brasileira, considera-se produto orgânico, seja ele in natura ou processado, aquele que é obtido em um sistema orgânico de produção agropecuária ou oriundo de processo extrativista sustentável e não prejudicial ao ecossistema local."
          }<br/><br/>
          {
            lang ?  
            "What is the difference between having and not having the certification?" : 
            "Qual a diferença entre ter e não ter a certificação?"
          }<br/><br/>
          {
            lang ?  
            "When the producer registers only for direct sales without certification, he cannot sell to third parties, only at the fair (or direct to the consumer) and for government purchases (school lunch and CONAB). When the product is certified, you can sell your product at fairs, but also to supermarkets, stores, restaurants, hotels, industries, internet, etc." : 
            "Quando o produtor se cadastrou apenas para venda direta sem certificação, não pode vender para terceiros, só na feira (ou direto ao consumidor) e para as compras do governo (merenda e CONAB). Quando o produto é certificado, pode vender seu produto em feiras, mas, também, para supermercados, lojas, restaurantes, hotéis, indústrias, internet etc."
          }
        </p><br/><br/>

        <div className="capacitacaodetalhes">
          <button id="myBtn">{ lang ? "Request a Quote" : "Solicitar Orçamento" }</button><br/><br/>
        </div>

        <div id="myModal" className="modal">
          <div className="modal-content">
            <div className="modal-header">
              <span className="close">&times;</span>
              <h2>{ lang ? "Request a Quote" : "Solicite um orçamento" }</h2>
            </div>
            <div className="modal-body">
              <form onSubmit={sendEmail}>
                <input type="text" name="nome" onChange={(e) => setNome(e.target.value)} value={nome} placeholder={ lang ? "Enter your name" : "Informe seu nome" }></input> <br/>
                <input type="tel" name="telefone" onChange={(e) => maskTel(e.target.value)} value={telefone} placeholder={ lang ? "Enter your phone" : "Informe seu telefone" }></input> <br/>
                <input type="email" name="email" onChange={(e) => setEmail(e.target.value)} value={email} placeholder={ lang ? "Enter your e-mail" : "Informe seu e-mail" }></input> <br/>
                <textarea name="mensagem" rows="5" onChange={(e) => setMensagem(e.target.value)} value={mensagem} placeholder={ lang ? "Enter your message" : "Informe sua mensagem" }></textarea> <br/>
                <input id="modalBtn" type="submit" name="enviar" value={ lang ? "Send" : "Enviar" }></input> <br/>
              </form>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default OrganicoBrasil;