import { useState, useEffect } from 'react';

function UnidadeArmazenadora(){

	const [lang, setLang] = useState();

	useEffect(()  => {
		const checkLang = function() {
			if(localStorage.getItem('lang')) {
				setLang(JSON.parse(localStorage.getItem('lang')));
			} else {
				setLang(0);
			}
		}

		window.addEventListener('storage', checkLang)

		return () => {
			window.removeEventListener('storage', checkLang)
		}
  }, []);

  useEffect(()  => {		
		if(localStorage.getItem('lang')) {
			setLang(JSON.parse(localStorage.getItem('lang')));
		} else {
			setLang(0);
		}		
    window.dispatchEvent(new Event("openModal"));	
    window.dispatchEvent(new Event("newRoute"));
  }, []);

  const [nome, setNome] = useState('');
  const [telefone, setTelefone] = useState('');
  const [email, setEmail] = useState('');
  const [assunto] = useState('C.A.F.E. Practices');
  const [mensagem, setMensagem] = useState('');

  const maskTel = (v) => {
    v = v.replace(/\D/g, "")

    v = v.replace(/(\d{2})(\d)/,"($1) $2")
    v = v.replace(/(\d)(\d{4})$/,"$1-$2")

    setTelefone(v)
  }

  const sendEmail = async (e) => {
    e.preventDefault();

    if(nome === '' || telefone === '' || email === '' || assunto === '' || mensagem === ''){
      const alertMessage = lang ? "Please fill in all fields" : "Por favor preencha todos os campos";
      alert(alertMessage);
      return;
    }

    var destinatario = 'comercial@savassicertificadora.com.br,fonteboag@savassicertificadora.com.br,dantasr@savassicertificadora.com.br';

    const templateParams = {
      origemMensagem: "Novo pedido de orçamento para C.A.F.E. Practices",
      nome: nome,
      telefone: telefone,
      email: email,
      assunto: assunto,
      mensagem: mensagem,
      destinatario: destinatario
    }

    window.dispatchEvent(new Event("closeModal"));
    setNome('');
    setTelefone('');
    setEmail('');
    setMensagem('');

    let response = await fetch("/contato", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8"
      },
      body: JSON.stringify(templateParams)
    })

    let result = await response.json();

    if(result.status==="200 OK") {
      console.log("EMAIL ENVIADO", response.status, response.text);
      alert(lang ? 'Budget request sent successfully!' : 'Solicitação de orçamento enviada com sucesso!')
    } else {
      console.log("ERRO: ", result.status);
    }

  }

  return(
    <div style={{backgroundColor:'#F7FBF5'}}>
      <div className="certificadorasubpages">
        <img className="certificadorasubpagesbanner" src={require("../../../../assets/images/unidade-armazenadora-banner.png")} alt="banner"/><br/><br/>
        <p>
          {
            lang ?  
            "The Savassi Certification body is accredited by the General Accreditation Coordination (CGCRE) of the National Institute of Metrology, Quality and Technology (INMETRO), in accordance with the requirements established in ABNT NBR ISO/IEC 17065:2013 and in the current NIT-DICOR 024, authorized since the 1st of March, 2013 to carry out the conformity assessment in the scope of Storage Units in a Natural Environment." : 
            "O organismo Savassi Certificadora é acreditado pela Coordenação Geral de Acreditação (CGCRE) do Instituto Nacional de Metrologia, Qualidade e Tecnologia (INMETRO), conforme requisitos estabelecidos na ABNT NBR ISO/IEC  17065:2013 e na NIT-DICOR 024 vigente, autorizada desde 1º de março de 2013 para a realização da avaliação da conformidade no escopo de Unidades Armazenadoras em Ambiente Natural."
          }<br/><br/>
          {
            lang ?  
            "The certification of Storage Units in a Natural Environment (UAAN) is governed by Normative Instruction No. 29, of June 8th, 2011, which consolidates all standards and procedures to be adopted by Storage Units." : 
            "A certificação de Unidades Armazenadoras em Ambiente Natural (UAAN) é regida pela Instrução Normativa Nº 29, de 08 de junho de 2011, a qual consolida todas as normas e procedimentos a serem adotados pelas Unidades Armazenadoras."
          }<br/><br/>
          {
            lang ?  
            "According to the legislation, certification is mandatory for legal entities that provide remunerated storage services, to third parties, of agricultural products, their derivatives, by-products and residues of economic values, including public stocks, and the Ministry of Agriculture, Livestock and Supply (MAPA) expand the requirement to other storage units." : 
            "De acordo com a legislação, a certificação é obrigatória para as pessoas jurídicas que prestam serviços remunerados de armazenagem, a terceiros, de produtos agropecuários, seus derivados, subprodutos e resíduos de valores econômicos, inclusive de estoques públicos, podendo o Ministério da Agricultura, Pecuária e Abastecimento (MAPA) ampliar a exigência para outras unidades armazenadoras."
          }<br/><br/>
          {
            lang ?  
            "The Conformity Identification Seal within the scope of SBAC (Certification Seal) indicates that the Storage Unit complies with the technical and regulatory requirements established by MAPA." : 
            "O Selo de Identificação da Conformidade no âmbito do SBAC (Selo de Certificação) indica que a Unidade Armazenadora está em conformidade com os requisitos técnicos e normativos estabelecidos pelo MAPA."
          }<br/><br/>
          {
            lang ?  
            "This program aims to guarantee the quality and safety of stored agricultural products, in addition to promoting good storage practices." : 
            "Esse programa tem como objetivo garantir a qualidade e segurança dos produtos agrícolas armazenados, além de promover boas práticas de armazenagem."
          }<br/><br/>
          {
            lang ?  
            "Some important aspects of this certification include:" : 
            "Alguns aspectos importantes dessa certificação incluem:"
          }<br/><br/>
          <ol>
            <li>
              {
                lang ?  
                "Adequate infrastructure: The storage unit must have an adequate physical structure, including silos, warehouses or other facilities, which guarantee the protection of agricultural products against bad weather, pests, diseases and contamination." : 
                "Infraestrutura adequada: A unidade armazenadora deve possuir uma estrutura física adequada, incluindo silos, armazéns ou outras instalações, que garantam a proteção dos produtos agrícolas contra intempéries, pragas, doenças e contaminação."
              }
            </li>
            <li>
              {
                lang ?  
                "Pest and disease control: Certification requires the storage unit to have an integrated pest and disease management plan, with preventive and corrective measures to avoid losses caused by these problems. This includes adopting good hygiene, cleaning and disinfection practices." : 
                "Controle de pragas e doenças: A certificação exige que a unidade armazenadora tenha um plano de manejo integrado de pragas e doenças, com medidas preventivas e corretivas para evitar perdas causadas por esses problemas. Isso inclui a adoção de boas práticas de higiene, limpeza e desinfecção."
              }
            </li>
            <li>
              {
                lang ?  
                "Good storage practices: Certification also covers aspects related to reception, classification, drying, aeration, temperature control, humidity and quality of agricultural products during storage. It is important to ensure that products are handled correctly to avoid loss of quality and nutritional value." : 
                "Boas práticas de armazenagem: A certificação também abrange aspectos relacionados à recepção, classificação, secagem, aeração, controle de temperatura, umidade e qualidade dos produtos agrícolas durante o armazenamento. É importante garantir que os produtos sejam manuseados corretamente para evitar perdas de qualidade e valor nutricional."
              }
            </li>
            <li>
              {
                lang ?  
                "Documentation and records: The certified storage unit must maintain adequate records of all stages of the storage process, including pest control records, cleaning operations, infrastructure maintenance and product quality." : 
                "Documentação e registros: A unidade armazenadora certificada deve manter registros adequados de todas as etapas do processo de armazenamento, incluindo registros de controle de pragas, operações de limpeza, manutenção da infraestrutura e qualidade dos produtos."
              }
            </li>
          </ol><br/><br/>
          {
            lang ?  
            "The certification of storage units in a natural environment in Brazil brings benefits to both producers and consumers. For producers, it provides greater safety and quality of stored products, reducing losses and adding value to the products sold. For consumers, it represents the guarantee that the products purchased were stored appropriately, preserving their quality and food safety." : 
            "A certificação de unidades armazenadoras em ambiente natural no Brasil traz benefícios tanto para os produtores quanto para os consumidores. Para os produtores, ela proporciona maior segurança e qualidade dos produtos armazenados, reduzindo as perdas e agregando valor aos produtos comercializados. Para os consumidores, representa a garantia de que os produtos adquiridos foram armazenados de forma adequada, preservando sua qualidade e segurança alimentar."
          }
        </p><br/><br/>

        <div className="capacitacaodetalhes">
          <button id="myBtn">{ lang ? "Request a Quote" : "Solicitar Orçamento" }</button><br/><br/>
        </div>

        <div id="myModal" className="modal">
          <div className="modal-content">
            <div className="modal-header">
              <span className="close">&times;</span>
              <h2>{ lang ? "Request a Quote" : "Solicite um orçamento" }</h2>
            </div>
            <div className="modal-body">
              <form onSubmit={sendEmail}>
                <input type="text" name="nome" onChange={(e) => setNome(e.target.value)} value={nome} placeholder={ lang ? "Enter your name" : "Informe seu nome" }></input> <br/>
                <input type="tel" name="telefone" onChange={(e) => maskTel(e.target.value)} value={telefone} placeholder={ lang ? "Enter your phone" : "Informe seu telefone" }></input> <br/>
                <input type="email" name="email" onChange={(e) => setEmail(e.target.value)} value={email} placeholder={ lang ? "Enter your e-mail" : "Informe seu e-mail" }></input> <br/>
                <textarea name="mensagem" rows="5" onChange={(e) => setMensagem(e.target.value)} value={mensagem} placeholder={ lang ? "Enter your message" : "Informe sua mensagem" }></textarea> <br/>
                <input id="modalBtn" type="submit" name="enviar" value={ lang ? "Send" : "Enviar" }></input> <br/>
              </form>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default UnidadeArmazenadora;