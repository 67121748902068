import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';

function QuemSomos(){

	const [lang, setLang] = useState();

	useEffect(()  => {
		const checkLang = function() {
			if(localStorage.getItem('lang')) {
				setLang(JSON.parse(localStorage.getItem('lang')));
			} else {
				setLang(0);
			}
		}

		window.addEventListener('storage', checkLang)

		return () => {
			window.removeEventListener('storage', checkLang)
		}
  }, []);

  useEffect(()  => {		
		if(localStorage.getItem('lang')) {
			setLang(JSON.parse(localStorage.getItem('lang')));
		} else {
			setLang(0);
		}
    window.dispatchEvent(new Event("openModalQuemSomos"));
    window.dispatchEvent(new Event('resize'));
    window.dispatchEvent(new Event("newRoute"));		
  }, []);

  return(
    <div style={{backgroundColor:'#F7FBF5'}}>
      <div className="quemsomos">
        <img className="quemsomosbanner" src={ lang ? require("../../assets/images/quem-somos-banner-ingles.png") : require("../../assets/images/quem-somos-banner.png") } alt="banner"/><br/><br/>
        <div>
          <img className="quemsomoslogo" src={require("../../assets/images/logo-t-1.png")} alt="logo savassi pequena"/>
          <h3>{lang ? "Company" : "Empresa"}</h3><br/>
        </div>
        <p>
          {
            lang ? 
            "SAVASSI AGRONEGÓCIO started its activities in 2006 with the aim of bringing the necessary technology to the producers to obtain the best results in quality, traceability (certification), productivity and economic return in the coffee sector. Throughout these years, there were dozens of farms, warehouses and agribusiness companies served throughout the national territory, always with the promptness and transparency of those who are effectively committed to results." : 
            "A SAVASSI AGRONEGÓCIO iniciou suas atividades no ano de 2006 com o objetivo de levar aos produtores a tecnologia necessária à obtenção dos melhores resultados em qualidade, rastreabilidade (certificação), produtividade e retorno econômico no setor cafeeiro. Ao longo desses anos, foram dezenas de fazendas, armazéns e empresas ligadas ao agronegócio atendidas em todo território nacional, sempre com a prontidão, presteza e transparência de quem efetivamente tem compromisso com resultados."
          } <br/><br/>
          {
            lang ? 
            "Currently, SAVASSI AGRONEGÓCIO is a Product Certification Body, accredited by the General Coordination of Accreditation of INMETRO (CGCRE, according to the requirements established in ISO / IEC 17065: 2013 and in current NIT-DICOR 024), in order to certify Products, in the scope of Storage Units in Natural Environment and Evaluation of Organic Conformity." : 
            "Atualmente a SAVASSI AGRONEGÓCIO é um Organismo de Certificação de Produto, acreditado pela Coordenação Geral de Acreditação do INMETRO (CGCRE, conforme requisitos estabelecidos na ABNT NBR ISO/IEC  17065:2013 e na NIT-DICOR 024 vigente), para realizar certificação de Produtos, no escopo de Unidades Armazenadoras em Ambiente Natural e Avaliação da Conformidade Orgânica."
          }
        </p><br/><br/>
        <img className="quemsomoslogo" src={require("../../assets/images/logo-t-1.png")} alt="logo savassi pequena"/>
        <h3>{lang ? "Quality Policy" : "Política de Gestão da Qualidade"}</h3><br/>
        <p>  
          {
            lang ? 
            "\"The body Savassi Certificação Serviços Administrativos Ltda. SCSAL, when evaluating the conformity of products, services, processes and systems, is committed to complying with applicable regulatory standards and to the continuous improvement of the effectiveness of its quality management system, seeking with that to always be doing better. Working with total freedom from conflict of interest, responsibility, impartiality, honesty and cooperation, we always work with the best professionals in order to demonstrate our respect, transparency and trust to all involved and interested parties. Quality of life, security and biodiversity, as sustainable aspects, continue as purposes to stimulate and develop the entire agribusiness chain.\"" : 
            "\"O organismo Savassi Certificação Serviços Administrativos Ltda. SCSAL, na avaliação da conformidade de produtos, serviços, processos e sistemas, se compromete com o atendimento das normas regulamentares aplicáveis e com a melhoria contínua da eficácia do seu sistema de gestão da qualidade, buscando com isto, fazer melhor sempre. Trabalhando com total isenção de conflito de interesse, responsabilidade, imparcialidade, honestidade e cooperação, atuamos sempre com os melhores profissionais a fim de demonstrar nosso respeito, transparência e confiança à todos os envolvidos e partes interessadas. Qualidade de vida, segurança e biodiversidade, enquanto aspectos sustentáveis, seguem como propósitos para o estimular e o desenvolver de toda a cadeia do agronegócio.\""
          }<br/><br/>
          {
            lang ? 
            "For more information regarding our Quality Management Policies, " :
            "Para maiores informações à respeito da nossa Políticas de Gestão da Qualidade, "
          }
          <Link to="../../assets/documents/Politica_Gestão_da_Qualidade.pdf" download="Politica_Gestão_da_Qualidade.pdf" target="_blank" rel="noreferrer">
            { lang ? "click here." : "clique aqui."}
          </Link>
        </p><br/><br/>
        <img className="quemsomoslogo" src={require("../../assets/images/logo-t-1.png")} alt="logo savassi pequena"/>
        <h3>{lang ? "Anti-Fraud and Anti-Corruption Policy" : "Política Antifraude e Anticorrupção"}</h3><br/>
        <p>
          {
            lang ? 
            "\"The organization Savassi Certificação Serviços Administrativos Ltda. SCSAL's policy is zero tolerance to acts of fraud and corruption. The exercise of the organization's professional activities and commercial matters must always comply with current legislation, standards and regulations, as well as the organization's internal policies.\"" : 
            "\"O organismo Savassi Certificação Serviços Administrativos Ltda. SCSAL tem como política, a tolerância zero à atos de fraude e corrupção. O exercício das atividades profissionais do organismo e os assuntos comerciais devem sempre respeitar as legislações, as normas e as regulamentações vigentes, bem como as políticas internas da organização.\""
          }<br/><br/>
          {
            lang ? 
            "For more information regarding our Anti-Fraud and Anti-Corruption Policy, " : 
            "Para maiores informações à respeito da nossa Política Antifraude e Anticorrupção, "
          }
          <Link to="../../assets/documents/Politica_Antifraude_Anticorrupção.pdf" download="Politica_Antifraude_Anticorrupção.pdf" target="_blank" rel="noreferrer">
            { lang ? "click here." : "clique aqui."}
          </Link>
        </p><br/><br/>
        <img className="quemsomoslogo" src={require("../../assets/images/logo-t-1.png")} alt="logo savassi pequena"/>
        <h3>{lang ? "Privacy and Data Protection Policy" : "Política de Privacidade e Proteção de Dados"}</h3><br/>
        <p>
          {
            lang ? 
            "\"The organization Savassi Certificação Serviços Administrativos Ltda. SCSAL is committed to safeguarding data and information by ensuring that all the organization's employees, users and potential users of the services offered by the organization, including users of websites or other means operated by the organization, understand how they can collect, produce, receive, classify, use, access, reproduce, transmit, distribute, process, archive, store, eliminate, evaluate or control information, modify, communicate, transfer, disseminate or extract collected data, including personally identifiable information, in accordance with the applicable legal bases and all privacy and data protection laws in force.\"" : 
            "\"O organismo Savassi Certificação Serviços Administrativos Ltda. SCSAL compromete-se em resguardar dados e informações através da garantia de que todos os colaboradores do organismo, usuários e potenciais usuários dos serviços oferecidos pelo organismo, incluindo usuários dos sites ou outros meios operados pelo organismo, entendem como poderão coletar, produzir, receptar, classificar, utilizar, acessar, reproduzir, transmitir, distribuir, processar, arquivar, armazenar, eliminar, avaliar ou controlar a informação, modificar, comunicar, transferir, difundir ou extrair os dados coletados, incluindo as informações de identificação pessoal, de acordo com as bases legais aplicáveis e todas as leis de privacidade e proteção de dados em vigor.\""
          }<br/><br/>
          {
            lang ? 
            "For more information regarding our Privacy and Data Protection Policies, " : 
            "Para maiores informações à respeito da nossa Políticas de Privacidade e Proteção de Dados, "
          }
          <Link to="../../assets/documents/Politica_Privacidade_e_Proteção_Dados.pdf" download="Politica_Privacidade_e_Proteção_Dados.pdf" target="_blank" rel="noreferrer">
            { lang ? "click here." : "clique aqui."}
          </Link>
        </p><br/><br/>
        <img className="quemsomoslogo" src={require("../../assets/images/logo-t-1.png")} alt="logo savassi pequena"/>
        <h3>{lang ? "Our Mission" : "Nossa Missão"}</h3><br/>
        <p>
          {
            lang ? 
            "Ensure our national and international customers are committed to the sustainable development of their business, based on ensuring complete, safe and traceable information." : 
            "Assegurar aos nossos clientes nacionais e internacionais, o compromisso com o desenvolvimento sustentável do seu negócio, alicerçado na garantia da informação íntegra, segura e rastreável."
          }
        </p><br/><br/>
        <img className="quemsomoslogo" src={require("../../assets/images/logo-t-1.png")} alt="logo savassi pequena"/>
        <h3>{lang ? "Our Vision" : "Nossa Visão"}</h3><br/>
        <p>
          {
            lang ? 
            "Be recognized as the best and most respected Brazilian Agricultural Certification Company." : 
            "Ser reconhecido como a melhor e mais respeitada Empresa de Certificação Agrícola Brasileira."
          }
        </p><br/><br/>
        <img className="quemsomoslogo" src={require("../../assets/images/logo-t-1.png")} alt="logo savassi pequena"/>
        <h3>{lang ? "Our Values" : "Nossos Valores"}</h3><br/>
        <p>
          1. {lang ? "Respect and value people: always treat others the same way you would like to be treated." : "Respeito e valorização às pessoas: sempre tratar o outro, da mesma forma que você gostaria de ser tratado."} <br/><br/>

          2. {lang ? "Cooperation and solidarity: always practice cooperation and solidarity, so that everyone can grow equally." : "Cooperação e solidariedade: sempre praticar cooperação e solidariedade, para que todos possam crescer de forma igualitária."} <br/><br/>

          3. {lang ? "Safeguard professional ethics: act concisely and respectfully, always safeguarding the steps to be followed along the way." : "Salvaguardar a ética profissional: atuar de forma concisa e respeitosa, sempre salvaguardando as etapas a serem seguidas no  decorrer do percurso."} <br/><br/>

          4. {lang ? "Compliance with applicable laws and regulatory requirements: strictly comply with the laws applicable to our business." : "Atendimento às leis e aos requisitos normativos aplicáveis: cumprir rigorosamente ás leis aplicáveis ao nosso negócio."} <br/><br/>

          5. {lang ? "Sincerity and Partnership: be sincere and partner with each other, whatever the situation being faced." : "Sinceridade e Parceria: ser sincero e parceiro uns aos outros, seja qual for a situação a ser enfrentada."} <br/><br/>

          6. {lang ? "Responsibility: be faithful and responsible in the tasks to be performed, always with responsibility and professionalism." : "Responsabilidade: ser fiel e responsável nas tarefas a serem executadas, sempre com responsabilidade e profissionalismo."} <br/><br/>

          7. {lang ? "Quality: seek to meet goals and executions efficiently, always seeking total customer satisfaction." : "Qualidade: buscar cumprir as metas e execuções de forma eficiente, sempre buscando a satisfação total do cliente."} <br/><br/>

          8. {lang ? "Commitment to sustainable development: being committed to maintaining our business, so that it is sustainable today, tomorrow and forever, respecting and conserving existing renewable natural resources." : "Compromisso com o desenvolvimento sustentável: ser compromissado com a manutenção do nosso negócio, para que o mesmo seja sustentável hoje, amanhã e sempre, respeitando e conservando os recursos naturais renováveis existentes."} <br/><br/>

          9. {lang ? "Honesty and Transparency in our actions and relationships: being honest and transparent in the stages and execution of our relevant day-to-day responsibilities." : "Honestidade e Transparência em nossas ações e relações: ser honesto e transparente nas etapas e execuções das nossas responsabilidades pertinentes do dia a dia."} <br/><br/>

          10. {lang ? "Confidentiality and Impartiality in all our work: maintaining and preserving the information of our clients and the company in an unrestricted manner, always taking into account impartiality at all times." : "Confidencialidade e Imparcialidade perante todo o nosso trabalho: manter e conservar de forma irrestrita as informações de nossos clientes e da empresa, sempre levando em conta a imparcialidade a qualquer momento."} <br/><br/>
        </p><br/>

        <h2>{lang ? "Board of Directors" : "Direção"}</h2><br/>
        <div className="owl-carousel">
					<div className="carousel-item">
            <img id="foto1" className="direcaofoto" src={require("../../assets/images/direcao1.png")} alt="Ronaldo Dantas"/>
          </div>
          <div className="carousel-item">
            <img id="foto2" className="direcaofoto" src={require("../../assets/images/direcao2.png")} alt="Marcus Vinicius Vilhena"/>
          </div>
          <div className="carousel-item">
            <img id="foto3" className="direcaofoto" src={require("../../assets/images/direcao3.png")} alt="Glaucio Lima Fonte Boa"/>
          </div>
        </div>
        <br/><br/>
      </div>

        <div id="myModal1" className="modalquemsomos">
          <div className="modalquemsomos-content">
            <div className="modalquemsomos-header">
              <span className="close">&times;</span>
            </div>
            <div className="modalquemsomos-body">
              <img src={require("../../assets/images/direcao1.png")} alt="Glaucio Lima Fonte Boa"/>
              <p><b>{ lang ? "Name: " : "Nome: " }</b>Ronaldo Dantas</p>
              <p>
                <b>{ lang ? "Role: " : "Cargo: " }</b>
                {
                  lang ? 
                  "Executive Director, Lead Auditor, Q-Grader, Course Instructor (Coffee Classification and Tasting and PMT), Financial Manager and Roasting Master" : 
                  "Diretor Executivo, Auditor Líder, Q-Grader, Instrutor de cursos (Classificação e Degustação de Café e PMT), Gestor Financeiro e Mestre de Torra"
                }
              </p>
              <p>
                <b>{ lang ? "Description:" : "Descrição:" }</b>
                <li>{ lang ? "Born in Loanda/PR" : "Natural de Loanda/PR" }</li>
                <li>{ lang ? "Agribusiness Technologist (Instituto de Ensino Superior de Patrocínio - IESP)" : "Tecnólogo do Agronegócio (Instituto de Ensino Superior de Patrocínio - IESP)" }</li>
                <li>{ lang ? "Computer Technician" : "Técnico em Informática" }</li>
                <li>{ lang ? "Fluency in English" : "Fluência em inglês" }</li>
              </p>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
        <div id="myModal2" className="modalquemsomos">
          <div className="modalquemsomos-content">
            <div className="modalquemsomos-header">
              <span className="close">&times;</span>
            </div>
            <div className="modalquemsomos-body">
              <img src={require("../../assets/images/direcao2.png")} alt="Glaucio Lima Fonte Boa"/>
              <p><b>{ lang ? "Name: " : "Nome: " }</b>Marcus Vinicius Vilhena</p>
              <p>
                <b>{ lang ? "Role: " : "Cargo: " }</b>
                {
                  lang ? 
                  "Administrative Director, Lead Auditor and Field Auditor, works in the UTZ, 4C and Organic Production (MAPA) programs" : 
                  "Diretor Administrativo, Auditor Líder e Auditor de Campo, atua nos programas UTZ, 4C e Produção Orgânica (MAPA)"
                }
              </p>
              <p>
                <b>{ lang ? "Description:" : "Descrição: " }</b>
                <li>{ lang ? "Born in Campinas/SP" : "Natural de Campinas/SP" }</li>
                <li>{ lang ? "Agricultural Engineer (Universidade Federal de Lavras - UFLA)" : "Engenheiro Agrônomo (Universidade Federal de Lavras - UFLA)" }</li>
                <li>{ lang ? "Specialization in Biodynamic Agriculture (Instituto Biodinâmico)" : "Especialização em Agricultura Biodinâmica (Instituto Biodinâmico)" }</li>
                <li>{ lang ? "Fluency in Spanish" : "Fluência em Espanhol" }</li>
              </p>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
        <div id="myModal3" className="modalquemsomos">
          <div className="modalquemsomos-content">
            <div className="modalquemsomos-header">
              <span className="close">&times;</span>
            </div>
            <div className="modalquemsomos-body">
              <img src={require("../../assets/images/direcao3.png")} alt="Glaucio Lima Fonte Boa"/>
              <p><b>{ lang ? "Name: " : "Nome: " }</b>Glaucio Lima Fonte Boa</p>
              <p>
                <b>{ lang ? "Role: " : "Cargo: " }</b>
                {
                  lang ? 
                  "Senior Executive, Lead Auditor, Q-Grader, Course Instructor (Coffee Classification and Tasting, Coffee Marketing and PMT), Field Auditor, Works in the UTZ, 4C, Strarbucks C.A.F.E. Practices, IN29/MAPA" : 
                  "Executivo Sênior, Auditor Líder, Q-Grader, Instrutor de cursos (Classificação e Degustação de Café, Comercialização de Café e PMT), Auditor de Campo, Atua nos programas UTZ, 4C, Strarbucks C.A.F.E. Practices, IN29/MAPA"
                }
              </p>
              <p>
                <b>{ lang ? "Description:" : "Descrição: " }</b>
                <li>{ lang ? "Born in Guarapari/ES" : "Natural de Guarapari/ES" }</li>
                <li>{ lang ? "Agricultural Engineer (Universidade Federal do Espirito Santo - UFES)" : "Engenheiro Agrônomo (Universidade Federal do Espirito Santo - UFES)" }</li>
                <li>{ lang ? "MBA in Strategic Agribusiness Management (Fundação Getúlio Vargas - FGV)" : "MBA em Gestão Estratégica do Agronegócio (Fundação Getúlio Vargas - FGV)" }</li>
                <li>{ lang ? "Fluency in English" : "Fluência em inglês" }</li>
                <li>Q-Grader</li>
              </p>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>

    </div>
  )
}

export default QuemSomos;