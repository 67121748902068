import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Politicas(){

	const [lang, setLang] = useState();

	useEffect(()  => {
		const checkLang = function() {
			if(localStorage.getItem('lang')) {
				setLang(JSON.parse(localStorage.getItem('lang')));
			} else {
				setLang(0);
			}
		}

		window.addEventListener('storage', checkLang)

		return () => {
			window.removeEventListener('storage', checkLang)
		}
  }, []);

  useEffect(()  => {		
		if(localStorage.getItem('lang')) {
			setLang(JSON.parse(localStorage.getItem('lang')));
		} else {
			setLang(0);
		}
    window.dispatchEvent(new Event("newRoute"));		
  }, []);

  return(
    <div style={{backgroundColor:'#F7FBF5'}}>
      <div className="documentossubpages">
        <div>
        <Link className="fa fa-chevron-left fa-2x" to="/documentos" state={{from: 0}}/><br/>
        <h1>{ lang ? "Policies" : "Políticas" }</h1><br/>
        </div>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "Anti-Fraud and Anti-Corruption Policy" : "Política Antifraude e Anticorrupção" }</b><br/>
          <Link to="../../../../assets/documents/Politica_Antifraude_Anticorrupção.pdf" download="Politica_Antifraude_Anticorrupção.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "Quality Management Policy" : "Política de Gestão da Qualidade" }</b><br/>
          <Link to="../../../../assets/documents/Politica_Gestão_da_Qualidade.pdf" download="Politica_Gestão_da_Qualidade.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "Privacy and Data Protection Policy" : "Política de Privacidade e Proteção de Dados" }</b><br/>
          <Link to="../../../../assets/documents/FORM-17-RECL_APEL_DENU-REV09.docx" download="FORM-17-RECL_APEL_DENU-REV09.docx" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/>
        <h3>
          <i className="fas fa-angle-double-right"/> <b>{ lang ?  "FORM.17 - COMPLAINT APPEAL AND PROTEST" : "FORM.17 - RECLAMAÇÃO APELAÇÃO E DENÚNCIA" }</b><br/>
          <Link to="../../../../assets/documents/Politica_Privacidade_Protecao_Dados.pdf" download="Politica_Privacidade_Protecao_Dados.pdf" target="_blank" rel="noreferrer"><button>{ lang ?  "Download" : "Baixar" }</button></Link>
        </h3>
        <hr/><br/>
      </div>
    </div>
  )
}

export default Politicas;