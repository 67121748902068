import { useState, useEffect } from 'react';

function CAFEPractices(){

	const [lang, setLang] = useState();

	useEffect(()  => {
		const checkLang = function() {
			if(localStorage.getItem('lang')) {
				setLang(JSON.parse(localStorage.getItem('lang')));
			} else {
				setLang(0);
			}
		}

		window.addEventListener('storage', checkLang)

		return () => {
			window.removeEventListener('storage', checkLang)
		}
  }, []);

  useEffect(()  => {		
		if(localStorage.getItem('lang')) {
			setLang(JSON.parse(localStorage.getItem('lang')));
		} else {
			setLang(0);
		}
    window.dispatchEvent(new Event("openModal"));	
    window.dispatchEvent(new Event("newRoute"));		
  }, []);

  const [nome, setNome] = useState('');
  const [telefone, setTelefone] = useState('');
  const [email, setEmail] = useState('');
  const [assunto] = useState('C.A.F.E. Practices');
  const [mensagem, setMensagem] = useState('');

  const maskTel = (v) => {
    v = v.replace(/\D/g, "")

    v = v.replace(/(\d{2})(\d)/,"($1) $2")
    v = v.replace(/(\d)(\d{4})$/,"$1-$2")

    setTelefone(v)
  }

  const sendEmail = async (e) => {
    e.preventDefault();

    if(nome === '' || telefone === '' || email === '' || assunto === '' || mensagem === ''){
      const alertMessage = lang ? "Please fill in all fields" : "Por favor preencha todos os campos";
      alert(alertMessage);
      return;
    }

    var destinatario = 'comercial@savassicertificadora.com.br,fonteboag@savassicertificadora.com.br,dantasr@savassicertificadora.com.br';

    const templateParams = {
      origemMensagem: "Novo pedido de orçamento para C.A.F.E. Practices",
      nome: nome,
      telefone: telefone,
      email: email,
      assunto: assunto,
      mensagem: mensagem,
      destinatario: destinatario
    }

    window.dispatchEvent(new Event("closeModal"));
    setNome('');
    setTelefone('');
    setEmail('');
    setMensagem('');

    let response = await fetch("/contato", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8"
      },
      body: JSON.stringify(templateParams)
    })

    let result = await response.json();

    if(result.status==="200 OK") {
      console.log("EMAIL ENVIADO", response.status, response.text);
      alert(lang ? 'Budget request sent successfully!' : 'Solicitação de orçamento enviada com sucesso!')
    } else {
      console.log("ERRO: ", result.status);
    }

  }

  return(
    <div style={{backgroundColor:'#F7FBF5'}}>
      <div className="certificadorasubpages">
        <img className="certificadorasubpagesbanner" src={require("../../../../assets/images/cafe-practices-banner.png")} alt="banner"/><br/><br/>
        <p>
          {
            lang ?  
            "The Savassi Certification body is accredited with SCS Global Services to carry out verification of the Starbucks C.A.F.E. Practices program." : 
            "O organismo Savassi Certificadora é credenciado junto a SCS Global Services para a realização de verificação do programa Starbucks C.A.F.E. Practices."
          }<br/><br/>
          {
            lang ?  
            "C.A.F.E. Practices verification is governed by the C.A.F.E. Practices Verifier and Inspector Operations Manual in current version, which establishes standardization in the universal and transparent approach to verification and interpretation of program evaluation criteria, in addition to providing inspectors with the methodology necessary to apply criteria across coffee farms, processing and grading facilities, and warehouses in a consistent manner." : 
            "A verificação C.A.F.E. Practices é regida pelo Manual de Operações do Verificador e Inspetor C.A.F.E. Practices em versão vigente, a qual estabelece padronização na abordagem universal e transparente para verificação e interpretação dos critérios de avaliação do programa, além de fornecer aos inspetores a metodologia necessária para aplicar os critérios nas fazendas de café, instalações de processamento e classificação e armazéns de maneira consistente."
          }<br/><br/>
          {
            lang ?  
            "The C.A.F.E. Practices certification seal is an initiative of the Sustainable Agriculture Network (SAN) and the Rainforest Alliance, two renowned organizations in the area of agricultural sustainability. This seal is specific to coffee production and seeks to promote sustainable, socially fair and economically viable agricultural practices in the coffee industry." : 
            "O selo de certificação C.A.F.E. Practices é uma iniciativa da Sustainable Agriculture Network (SAN) e da Rainforest Alliance, duas organizações renomadas na área de sustentabilidade agrícola. Esse selo é específico para a produção de café e busca promover práticas agrícolas sustentáveis, socialmente justas e economicamente viáveis na indústria cafeeira."
          }<br/><br/>
          {
            lang ?  
            "The C.A.F.E. Practices certification program's main objective is to improve the quality of life of coffee producers, protect the environment and ensure the sustainability of production. Some important features of this seal include:" : 
            "O programa de certificação C.A.F.E. Practices tem como objetivo principal melhorar a qualidade de vida dos produtores de café, proteger o meio ambiente e garantir a sustentabilidade da produção. Algumas características importantes desse selo incluem:"
          }<br/><br/>
          <ol>
            <li>
              {
                lang ?  
                "Environmental conservation: C.A.F.E. Practices certification encourages agricultural practices that protect the environment, such as sustainable management of natural resources, conservation of biodiversity and reducing the use of harmful agrochemicals. Reforestation and water conservation practices are also encouraged." : 
                "Conservação ambiental: A certificação C.A.F.E. Practices incentiva práticas agrícolas que protegem o meio ambiente, como o manejo sustentável dos recursos naturais, a conservação da biodiversidade e a redução do uso de agroquímicos prejudiciais. Também são encorajadas práticas de reflorestamento e conservação da água."
              }
            </li>
            <li>
              {
                lang ?  
                "Social responsibility: The C.A.F.E. Practices seal seeks to improve the working conditions, health and safety of workers involved in coffee production. This includes ensuring respect for human rights, combating child labor, promoting gender equality and offering good working conditions and fair pay." : 
                "Responsabilidade social: O selo C.A.F.E. Practices busca melhorar as condições de trabalho, a saúde e a segurança dos trabalhadores envolvidos na produção de café. Isso inclui garantir o respeito aos direitos humanos, combater o trabalho infantil, promover a igualdade de gênero e oferecer boas condições de trabalho e remuneração justa."
              }
            </li>
            <li>
              {
                lang ?  
                "Good agricultural practices: Certification promotes the adoption of good agricultural practices, including integrated pest and disease management, improving coffee quality, crop diversification and soil preservation." : 
                "Boas práticas agrícolas: A certificação promove a adoção de boas práticas agrícolas, incluindo a gestão integrada de pragas e doenças, a melhoria da qualidade do café, a diversificação de culturas e a preservação do solo."
              }
            </li>
            <li>
              {
                lang ?  
                "Traceability and transparency: The C.A.F.E. Practices seal requires that the entire coffee production process be tracked, from the farm to the final consumer. This allows for greater transparency and ensures that certified coffee comes from producers committed to sustainable practices." : 
                "Rastreabilidade e transparência: O selo C.A.F.E. Practices exige que todo o processo de produção de café seja rastreado, desde a fazenda até o consumidor final. Isso permite maior transparência e garante que o café certificado seja proveniente de produtores comprometidos com as práticas sustentáveis."
              }
            </li>
          </ol><br/><br/>
          {
            lang ?  
            "By choosing coffee with the C.A.F.E. Practices certification seal, consumers contribute to a more sustainable and responsible coffee industry, supporting producers who adopt environmentally conscious practices and promoting social justice. Furthermore, certification also encourages the continuous improvement of production practices, aiming for a more sustainable coffee industry in the long term." : 
            "Ao escolher café com o selo de certificação C.A.F.E. Practices, os consumidores contribuem para uma indústria cafeeira mais sustentável e responsável, apoiando produtores que adotam práticas ambientalmente conscientes e promovendo a justiça social. Além disso, a certificação também incentiva a melhoria contínua das práticas de produção, visando uma indústria cafeeira mais sustentável a longo prazo."
          }
        </p><br/><br/>

        <div className="capacitacaodetalhes">
          <button id="myBtn">{ lang ? "Request a Quote" : "Solicitar Orçamento" }</button><br/><br/>
        </div>

        <div id="myModal" className="modal">
          <div className="modal-content">
            <div className="modal-header">
              <span className="close">&times;</span>
              <h2>{ lang ? "Request a Quote" : "Solicite um orçamento" }</h2>
            </div>
            <div className="modal-body">
              <form onSubmit={sendEmail}>
                <input type="text" name="nome" onChange={(e) => setNome(e.target.value)} value={nome} placeholder={ lang ? "Enter your name" : "Informe seu nome" }></input> <br/>
                <input type="tel" name="telefone" onChange={(e) => maskTel(e.target.value)} value={telefone} placeholder={ lang ? "Enter your phone" : "Informe seu telefone" }></input> <br/>
                <input type="email" name="email" onChange={(e) => setEmail(e.target.value)} value={email} placeholder={ lang ? "Enter your e-mail" : "Informe seu e-mail" }></input> <br/>
                <textarea name="mensagem" rows="5" onChange={(e) => setMensagem(e.target.value)} value={mensagem} placeholder={ lang ? "Enter your message" : "Informe sua mensagem" }></textarea> <br/>
                <input id="modalBtn" type="submit" name="enviar" value={ lang ? "Send" : "Enviar" }></input> <br/>
              </form>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default CAFEPractices;