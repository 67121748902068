import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Footer(){

	const [lang, setLang] = useState();

	useEffect(()  => {
		const checkLang = function() {
			if(localStorage.getItem('lang')) {
				setLang(JSON.parse(localStorage.getItem('lang')));
			} else {
				setLang(0);
			}
		}

		window.addEventListener('storage', checkLang)

		return () => {
			window.removeEventListener('storage', checkLang)
		}
  }, []);

  return(
		<footer>
			<div className="footer-container">
				<span className="footer-text-1">
					{lang ? "CONNECT AND STAY UP TO DATE WITH EVERYTHING " : "CONECTE-SE E FIQUE POR DENTRO DE TUDO "}
				</span>
				<Link to="https://www.instagram.com/savassiagronegocio/" target="_blank" rel="noopener noreferrer" className="fa fa-brands fa-instagram"></Link>
				<Link to="https://www.facebook.com/SavassiCertificacaoAgronegocio/?locale=pt_BR" target="_blank" rel="noopener noreferrer" className="fa fa-brands fa-facebook"></Link>
				<Link to="https://www.youtube.com/channel/UCj-FdvEZ0z8DB3hRAxvBQ8w" target="_blank" rel="noopener noreferrer" className="fa fa-brands fa-youtube"></Link>
				<Link to="https://www.linkedin.com/company/savassi-agronegocio/" target="_blank" rel="noopener noreferrer" className="fa fa-brands fa-linkedin-in"></Link>
				<Link to="https://x.com/savassicafe?s=20" target="_blank" rel="noopener noreferrer" className="fa fa-brands fa-twitter"></Link>
				<Link to="https://www.threads.net/@savassiagronegocio" target="_blank" rel="noopener noreferrer" className="fa fa-brands fa-threads"></Link>
				<Link to="https://linktr.ee/savassiagro" target="_blank" rel="noopener noreferrer" className="fa fa-brands fa-whatsapp"></Link>
			</div>
			<span className="footer-text-2">
				&copy; <i>2023 Copyright. {lang ? "All rights reserved to Savassi Agronegócio | Developed by " : "Todos os direitos reservados à Savassi Agronegócio | Desenvolvido por "}
				<Link to="https://www.linkedin.com/in/luiz-paulo-carvalho/" target="_blank" rel="noopener noreferrer"> Luiz Paulo Nascimento Carvalho </Link>
				{ lang ? "and" : "e" }
				<Link to="https://www.instagram.com/agenciamobile4/" target="_blank" rel="noopener noreferrer"> Agência Móbile 4</Link></i>
			</span>
			
		</footer>
  )
}

export default Footer;