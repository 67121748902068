import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Certificadora(){

	var clients = ['agropesg.png','alto_paranaiba.png','appcer.png','bdc_cafe.png','brascafe.png','cafebras.png','canastra.png','carapina.png','cooxupe.png','estrella_lourdes.png','f_pache.png','galvao.png','gra.png','ibanez.png','jc_grossi.png','leste_minas.png','liv.png','mariano.png','nicchio.png','nova_colonia.png','ocoffee.png','pole.png','pole_coffee.png','procafe.png','santa_rita.png','sitio_california.png','sustentare.png','tpj.png','varjao.png','vixport.png'];

  useEffect(() => {
		window.dispatchEvent(new Event('resize'));
	}, []);

	const [lang, setLang] = useState();

	useEffect(()  => {
		const checkLang = function() {
			if(localStorage.getItem('lang')) {
				setLang(JSON.parse(localStorage.getItem('lang')));
			} else {
				setLang(0);
			}
		}

		window.addEventListener('storage', checkLang)

		return () => {
			window.removeEventListener('storage', checkLang)
		}
  }, []);

  useEffect(()  => {		
		if(localStorage.getItem('lang')) {
			setLang(JSON.parse(localStorage.getItem('lang')));
		} else {
			setLang(0);
		}
    window.dispatchEvent(new Event("newRoute"));
  }, []);

	function shuffle () {
    var j, x, index;
    for (index = clients.length - 1; index > 0; index--) {
        j = Math.floor(Math.random() * (index + 1));
        x = clients[index];
        clients[index] = clients[j];
        clients[j] = x;
    }
	}

	shuffle();

  return(
    <div style={{backgroundColor:'#F7FBF5'}}>
      <div className="certificadora">
				<img style={{borderRadius:'0px 0px 25px 25px'}} className="certificadorabanner" src={lang ? require("../../assets/images/b-certificacao-i.png") : require("../../assets/images/b-certificacao.png")} alt="banner"/><br/><br/>
        <div className="certificadoracontainer">
          <Link to="/certificadora/4c">
            <img className="certificadorafoto" src={require("../../assets/images/certificadora-4c.png")} alt="4C"/>
            <div className="certificadoramiddle1">
              <div className="certificadoratext">4C</div>
            </div>
          </Link>
          <Link to="/certificadora/cafe-practices">
            <img className="certificadorafoto" src={require("../../assets/images/certificadora-cafe-practices.png")} alt="C.A.F.E. Practices"/>
            <div className="certificadoramiddle1">
              <div className="certificadoratext">C.A.F.E. Practices</div>
            </div>
          </Link>
        </div>
        <br/>
        <div className="certificadoracontainer">
          <Link to="/certificadora/organico-brasil">
            <img className="certificadorafoto" src={require("../../assets/images/certificadora-organico-brasil.png")} alt={ lang ? "Organic Brazil" : "Orgânico Brasil"}/>
            <div className="certificadoramiddle1">
              <div className="certificadoratext">{ lang ? "Organic Brazil" : "Orgânico Brasil"}</div>
            </div>
          </Link>
          <Link to="/certificadora/rainforest-alliance">
            <img className="certificadorafoto" src={require("../../assets/images/certificadora-rainforest.png")} alt="Rainforest Alliance"/>
            <div className="certificadoramiddle1">
              <div className="certificadoratext">Rainforest Alliance</div>
            </div>
          </Link>
        </div>
        <br/>
        <div className="certificadoracontainer">
          <Link to="/certificadora/unidade-armazenadora-ambiente-natural">
            <img className="certificadorafoto" src={require("../../assets/images/certificadora-ambiente-natural.png")} alt={ lang ? "Storage Unit in Natural Environment" : "Unidade Amarzenadora em Ambiente Natural"}/>
            <div className="certificadoramiddle1">
              <div className="certificadoratext">{ lang ? "Storage Unit in Natural Environment" : "Unidade Amarzenadora em Ambiente Natural"}</div>
            </div>
          </Link>
          <Link to="/certificadora/global-gap">
            <img className="certificadorafoto" src={require("../../assets/images/certificadora-global-gap.png")} alt="GLOBAL G.A.P."/>
            <div className="certificadoramiddle1">
              <div className="certificadoratext">GLOBAL G.A.P.</div>
            </div>
          </Link>
        </div><br/><br/><br/>

        <div style={{backgroundColor:'#ddd8d7'}}>
					<span className="cred-text">{lang ? "OUR CERTIFIED CUSTOMERS" : "NOSSOS CLIENTES CERTIFICADOS"}</span>
					<div className="owl-carousel">
						<div className="carousel-item">
							<img className="logo-cliente" src={require("../../assets/images/clientes/" + clients[0])} alt="Logo Cliente"/>
						</div>
						<div className="carousel-item">
							<img className="logo-cliente" src={require("../../assets/images/clientes/" + clients[1])} alt="Logo Cliente"/>
						</div>
						<div className="carousel-item">
							<img className="logo-cliente" src={require("../../assets/images/clientes/" + clients[2])} alt="Logo Cliente"/>
						</div>
						<div className="carousel-item">
							<img className="logo-cliente" src={require("../../assets/images/clientes/" + clients[3])} alt="Logo Cliente"/>
						</div>
						<div className="carousel-item">
							<img className="logo-cliente" src={require("../../assets/images/clientes/" + clients[4])} alt="Logo Cliente"/>
						</div>
						<div className="carousel-item">
							<img className="logo-cliente" src={require("../../assets/images/clientes/" + clients[5])} alt="Logo Cliente"/>
						</div>
						<div className="carousel-item">
							<img className="logo-cliente" src={require("../../assets/images/clientes/" + clients[6])} alt="Logo Cliente"/>
						</div>
						<div className="carousel-item">
							<img className="logo-cliente" src={require("../../assets/images/clientes/" + clients[7])} alt="Logo Cliente"/>
						</div>
						<div className="carousel-item">
							<img className="logo-cliente" src={require("../../assets/images/clientes/" + clients[8])} alt="Logo Cliente"/>
						</div>
						<div className="carousel-item">
							<img className="logo-cliente" src={require("../../assets/images/clientes/" + clients[9])} alt="Logo Cliente"/>
						</div>
						<div className="carousel-item">
							<img className="logo-cliente" src={require("../../assets/images/clientes/" + clients[10])} alt="Logo Cliente"/>
						</div>
						<div className="carousel-item">
							<img className="logo-cliente" src={require("../../assets/images/clientes/" + clients[11])} alt="Logo Cliente"/>
						</div>
						<div className="carousel-item">
							<img className="logo-cliente" src={require("../../assets/images/clientes/" + clients[12])} alt="Logo Cliente"/>
						</div>
						<div className="carousel-item">
							<img className="logo-cliente" src={require("../../assets/images/clientes/" + clients[13])} alt="Logo Cliente"/>
						</div>
						<div className="carousel-item">
							<img className="logo-cliente" src={require("../../assets/images/clientes/" + clients[14])} alt="Logo Cliente"/>
						</div>
						<div className="carousel-item">
							<img className="logo-cliente" src={require("../../assets/images/clientes/" + clients[15])} alt="Logo Cliente"/>
						</div>
						<div className="carousel-item">
							<img className="logo-cliente" src={require("../../assets/images/clientes/" + clients[16])} alt="Logo Cliente"/>
						</div>
						<div className="carousel-item">
							<img className="logo-cliente" src={require("../../assets/images/clientes/" + clients[17])} alt="Logo Cliente"/>
						</div>
						<div className="carousel-item">
							<img className="logo-cliente" src={require("../../assets/images/clientes/" + clients[18])} alt="Logo Cliente"/>
						</div>
						<div className="carousel-item">
							<img className="logo-cliente" src={require("../../assets/images/clientes/" + clients[19])} alt="Logo Cliente"/>
						</div>
						<div className="carousel-item">
							<img className="logo-cliente" src={require("../../assets/images/clientes/" + clients[20])} alt="Logo Cliente"/>
						</div>
						<div className="carousel-item">
							<img className="logo-cliente" src={require("../../assets/images/clientes/" + clients[21])} alt="Logo Cliente"/>
						</div>
						<div className="carousel-item">
							<img className="logo-cliente" src={require("../../assets/images/clientes/" + clients[22])} alt="Logo Cliente"/>
						</div>
						<div className="carousel-item">
							<img className="logo-cliente" src={require("../../assets/images/clientes/" + clients[23])} alt="Logo Cliente"/>
						</div>
						<div className="carousel-item">
							<img className="logo-cliente" src={require("../../assets/images/clientes/" + clients[24])} alt="Logo Cliente"/>
						</div>
						<div className="carousel-item">
							<img className="logo-cliente" src={require("../../assets/images/clientes/" + clients[25])} alt="Logo Cliente"/>
						</div>
						<div className="carousel-item">
							<img className="logo-cliente" src={require("../../assets/images/clientes/" + clients[26])} alt="Logo Cliente"/>
						</div>
						<div className="carousel-item">
							<img className="logo-cliente" src={require("../../assets/images/clientes/" + clients[27])} alt="Logo Cliente"/>
						</div>
						<div className="carousel-item">
							<img className="logo-cliente" src={require("../../assets/images/clientes/" + clients[28])} alt="Logo Cliente"/>
						</div>
						<div className="carousel-item">
							<img className="logo-cliente" src={require("../../assets/images/clientes/" + clients[29])} alt="Logo Cliente"/>
						</div>
					</div>
				</div>
      </div>
    </div>
  )
}

export default Certificadora;