import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function FaleConosco(){

	const [lang, setLang] = useState();

	useEffect(()  => {
		const checkLang = function() {
			if(localStorage.getItem('lang')) {
				setLang(JSON.parse(localStorage.getItem('lang')));
			} else {
				setLang(0);
			}
		}

		window.addEventListener('storage', checkLang)

		return () => {
			window.removeEventListener('storage', checkLang)
		}
  }, []);

  useEffect(()  => {		
		if(localStorage.getItem('lang')) {
			setLang(JSON.parse(localStorage.getItem('lang')));
		} else {
			setLang(0);
		}
    window.dispatchEvent(new Event("newRoute"));		
  }, []);

  const [nome, setNome] = useState('');
  const [telefone, setTelefone] = useState('');
  const [email, setEmail] = useState('');
  const [assunto, setAssunto] = useState('');
  const [mensagem, setMensagem] = useState('');

  const maskTel = (v) => {
    v = v.replace(/\D/g, "")

    v = v.replace(/(\d{2})(\d)/,"($1) $2")
    v = v.replace(/(\d)(\d{4})$/,"$1-$2")

    setTelefone(v)
  }

  const sendEmail = async (e) => {
    e.preventDefault();

    if(nome === '' || telefone === '' || email === '' || assunto === '' || mensagem === ''){
      const alertMessage = lang ? "Please fill in all fields" : "Por favor preencha todos os campos";
      alert(alertMessage);
      return;
    }

    var destinatario;

    switch(assunto) {
      case 'Dúvidas, Sugestões, Críticas e Elogios':
        destinatario = 'fonteboag@savassicertificadora.com.br,dantasr@savassicertificadora.com.br,vilhenam@savassicertificadora.com.br';
        break;
      case 'Financeiro':
        destinatario = 'dantasr@savassicertificadora.com.br,financeiro@savassicertificadora.com.br';
        break;
      case 'Certificação':
        destinatario = 'comercial@savassicertificadora.com.br,fonteboag@savassicertificadora.com.br,dantasr@savassicertificadora.com.br';
        break;
      case 'Análise Técnica':
        destinatario = 'comercial4@savassicertificadora.com.br,comercial@savassicertificadora.com.br,fonteboag@savassicertificadora.com.br,dantasr@savassicertificadora.com.br';
        break;
      case 'Contribuição de Partes Interessadas':
        destinatario = 'vilhenam@savassicertificadora.com.br,qualidade4@savassicertificadora.com.br,qualidade@savassicertificadora.com.br';
        break;
      default:
        destinatario = 'cursos@savassiagronegocio.com.br,comercial@savassicertificadora.com.br,fonteboag@savassicertificadora.com.br,dantasr@savassicertificadora.com.br';
        break;
    }

    const templateParams = {
      origemMensagem: "Nova mensagem do portal Fale Conosco.",
      nome: nome,
      telefone: telefone,
      email: email,
      assunto: assunto,
      mensagem: mensagem,
      destinatario: destinatario
    }
    
    setNome('');
    setTelefone('');
    setEmail('');
    setAssunto('');
    setMensagem('');

    let response = await fetch("/contato", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8"
      },
      body: JSON.stringify(templateParams)
    })

    let result = await response.json();

    if(result.status==="200 OK") {
      console.log("EMAIL ENVIADO", response.status, response.text);
      alert(lang ? 'Message sent successfully!' : 'Mensagem enviada com sucesso!')
    } else {
      console.log("ERRO: ", result.status);
    }

  }

  return(
    <div style={{backgroundColor:'#F7FBF5'}}>
      <div className="faleconosco">
        <img className="faleconoscobanner" src={ lang ? require("../../assets/images/fale-conosco-banner-ingles.png") : require("../../assets/images/fale-conosco-banner.png") } alt="banner"/><br/><br/>
        
        <h3>{ lang ? "A channel open to you" : "Um canal aberto à você" }</h3><br/>
        <h4>
          { 
            lang ? 
            "We recognize the importance of this communication channel to ensure due consideration to all audiences and stakeholders with whom we relate, as well as we are aware that the success of the Savassi Agronegócio organization is a consequence of the responsibility and willingness to assume commitments with integrity and transparency." : 
            "Reconhecemos a importância deste canal de comunicação para assegurar a devida consideração à todos os públicos e partes interessadas com os quais nos relacionamos, bem como estamos conscientes de que o êxito da organização Savassi Agronegócio é consequência da responsabilidade e disposição para assumir compromissos de forma íntegra e transparente." 
          }
        </h4> <br/>
        <h4>
          { 
            lang ? 
            "Your contribution is very important to us. Select the desired subject and comment. Your participation reflects Savassi Agronegócio's way of being, a solid, reliable company that is aware of its responsibilities." : 
            "Sua contribuição é muito importante para nós. Selecione o assunto desejado e comente. Sua participação reflete o jeito de ser da Savassi Agronegócio, empresa sólida, confiável e consciente de suas responsabilidades." 
          }
        </h4> <br/>

        <div className="faleconoscoform">
          <form className="faleconoscof" onSubmit={sendEmail}>
            <table className="faleconoscotable">
              <tbody>
                <tr>
                  <td>
                    <label>
                      <input className="faleconoscoforminput" type="text" name="nome" onChange={(e) => setNome(e.target.value)} value={nome} placeholder={ lang ? "Enter your name" : "Informe seu nome" }/>
                    </label><br/>
                    <label>
                      <input className="faleconoscoforminput" type="tel" name="telefone" onChange={(e) => maskTel(e.target.value)} value={telefone} placeholder={ lang ? "Enter your phone number" : "Informe seu telefone" }/>
                    </label><br/>
                    <label>
                      <input className="faleconoscoforminput" type="email" name="email" onChange={(e) => setEmail(e.target.value)} value={email} placeholder={ lang ? "Enter your e-mail" : "Informe seu e-mail" }/>
                    </label><br/>
                    <label>
                      <select className="faleconoscoformselect" name="assunto" onChange={(e) => setAssunto(e.target.value)} value={assunto}>
                        <option value="">{ lang ? "Select the Subject" : "Selecione o Assunto" }</option>
                        <option value="Dúvidas, Sugestões, Críticas e Elogios">{ lang ? "Questions, Suggestions, Opinions and Compliments" : "Dúvidas, Sugestões, Críticas e Elogios" }</option>
                        <option value="Financeiro">{ lang ? "Financial" : "Financeiro" }</option>
                        <option value="Certificação">{ lang ? "Certification" : "Certificação" }</option>
                        <option value="Análise Técnica">{ lang ? "Technical Analysis" : "Análise Técnica" }</option>
                        <option value="Contribuição de Partes Interessadas">{ lang ? "Interested Party Contribution" : "Contribuição de Partes Interessadas" }</option>
                        <option value="Curso Classificação e Degustação de Café - CDC">{ lang ? "Coffee Classification and Tasting - CCT Course" : "Curso Classificação e Degustação de Café - CDC" }</option>
                        <option value="Curso Comercialização de Café - COM">{ lang ? "Marketing of Coffee - MOC Course" : "Curso Comercialização de Café - COM" }</option>
                        <option value="Curso Professional Master in Tasting - PMT">{ lang ? "Professional Master in Tasting - PMT Course" : "Curso Professional Master in Tasting - PMT" }</option>
                        <option value="Curso Q-Grader">{ lang ? "Q-Grader Course" : "Curso Q-Grader" }</option>
                        <option value="Curso Calibração Q-Grader">{ lang ? "Q-Grader Calibration Course" : "Curso Calibração Q-Grader" }</option>
                        <option value="Curso Retake Q-Grader">{ lang ? "Q-Grader Retake Course" : "Curso Retake Q-Grader" }</option>
                        <option value="Curso de Torra">{ lang ? "Torra Course" : "Curso de Torra" }</option>
                        <option value="Curso Recertificação Q-Grader">{ lang ? "Q-Grader Recertification Course" : "Curso Recertificação Q-Grader" }</option>
                        <option value="Curso Retake CDC">{ lang ? "CCT Retake Course" : "Curso Retake CDC" }</option>
                        <option value="Curso Retake PMT">{ lang ? "PMT Retake Course" : "Curso Retake PMT" }</option>
                      </select>
                    </label><br/><br/>
                    <label>
                      <textarea className="faleconoscoformtextarea" rows="5" name="mensagem" onChange={(e) => setMensagem(e.target.value)} value={mensagem} placeholder={ lang ? "Enter your message" : "Informe a mensagem" }></textarea>
                    </label><br/>
                    <input className="faleconoscoenviar" type="submit" value={ lang ? "Send Message" : "Enviar Mensagem" }/>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>

        <div className="faleconoscoinfo">
          <span>{ lang ? "Below are the links to the Procedure and Form:" : "Segue abaixo os links do Procedimento e Formulário:" }</span><br/>
          <Link to="../../assets/documents/Contribuicao-Partes-Interessadas.pdf" download="Contribuicao-Partes-Interessadas.pdf" target="_blank" rel="noreferrer">
            { lang ? "INTERESTED PARTY CONTRIBUTION PROCEDURE" : "PROCEDIMENTO DE CONTRIBUIÇÃO DE PARTES INTERESSADAS" }
          </Link><br/>
          <Link to="../../assets/documents/FORM.17.2-CONTRIBUIÇÃO_DE_PARTES_INTERESSADAS-REV-02.docx" download="FORM.17.2-CONTRIBUIÇÃO_DE_PARTES_INTERESSADAS-REV-02.docx" target="_blank" rel="noreferrer">
            { lang ? "INTERESTED PARTY CONTRIBUTION FORM" : "FORMULÁRIO CONTRIBUIÇÃO DE PARTES INTERESSADAS" }
          </Link><br/>
        </div>

        <iframe title="google-map" className="faleconoscomap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d333.5572258352114!2d-46.99351808535007!3d-18.94160646495914!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94afba89db2a9c93%3A0x8d44aab5f4f1991!2sSavassi%20Agroneg%C3%B3cio!5e0!3m2!1spt-BR!2sbr!4v1694732622426!5m2!1spt-BR!2sbr" style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

        <div className="faleconoscoendereco">
          <span>{ lang ? "Address: Bernardo Guimarães Alves Street, 428 - 2nd Floor - Downtown, Patrocínio - MG, Zip Code: 38740-028" : "Endereço: R. Bernardo Guimarães Alves, 428 - 2º Andar - Centro, Patrocínio - MG, CEP: 38740-028" }</span><br/>
          <span>{ lang ? "Phone: +55 34 3832-0530" : "Telefone: +55 34 3832-0530" }</span>
        </div>

      </div>
    </div>
  )
}

export default FaleConosco;