import { useState, useEffect } from 'react';

function AuditoriaEmbarques(){

  const [lang, setLang] = useState();

	useEffect(()  => {
		const checkLang = function() {
			if(localStorage.getItem('lang')) {
				setLang(JSON.parse(localStorage.getItem('lang')));
			} else {
				setLang(0);
			}
		}

		window.addEventListener('storage', checkLang)

		return () => {
			window.removeEventListener('storage', checkLang)
		}
  }, []);

  useEffect(()  => {		
		if(localStorage.getItem('lang')) {
			setLang(JSON.parse(localStorage.getItem('lang')));
		} else {
			setLang(0);
		}
    window.dispatchEvent(new Event("openModal"));	
    window.dispatchEvent(new Event("newRoute"));	
  }, []);

  const [nome, setNome] = useState('');
  const [telefone, setTelefone] = useState('');
  const [email, setEmail] = useState('');
  const [assunto] = useState('Auditoria de Embarque de Grãos e Produtos');
  const [mensagem, setMensagem] = useState('');

  const maskTel = (v) => {
    v = v.replace(/\D/g, "")

    v = v.replace(/(\d{2})(\d)/,"($1) $2")
    v = v.replace(/(\d)(\d{4})$/,"$1-$2")

    setTelefone(v)
  }

  const sendEmail = async (e) => {
    e.preventDefault();

    if(nome === '' || telefone === '' || email === '' || assunto === '' || mensagem === ''){
      const alertMessage = lang ? "Please fill in all fields" : "Por favor preencha todos os campos";
      alert(alertMessage);
      return;
    }

    var destinatario = 'comercial4@savassicertificadora.com.br,comercial@savassicertificadora.com.br,fonteboag@savassicertificadora.com.br,dantasr@savassicertificadora.com.br';

    const templateParams = {
      origemMensagem: "Novo pedido de orçamento para Auditoria de Embarque de Grãos e Produtos",
      nome: nome,
      telefone: telefone,
      email: email,
      assunto: assunto,
      mensagem: mensagem,
      destinatario: destinatario
    }

    window.dispatchEvent(new Event("closeModal"));
    setNome('');
    setTelefone('');
    setEmail('');
    setMensagem('');

    let response = await fetch("/contato", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8"
      },
      body: JSON.stringify(templateParams)
    })

    let result = await response.json();

    if(result.status==="200 OK") {
      console.log("EMAIL ENVIADO", response.status, response.text);
      alert(lang ? 'Budget request sent successfully!' : 'Solicitação de orçamento enviada com sucesso!')
    } else {
      console.log("ERRO: ", result.status);
    }

  }

  return(
    <div style={{backgroundColor:'#F7FBF5'}}>
      <div className="analisesubpages">
        <img className="analisesubpagesbanner" src={ lang ? require("../../../../assets/images/auditoria-embarques-banner-ingles.png") : require("../../../../assets/images/auditoria-embarques-banner.png") } alt="banner"/><br/><br/>
        <p>
          {
            lang ?  
            "Savassi Análise Técnica stands out for having a highly qualified team specialized in auditing shipments of various agricultural products. Our commitment is to ensure that the shipped product precisely corresponds to the documentary specifications, fully meeting legal and commercial requirements." : 
            "A Savassi Análise Técnica destaca-se por contar com uma equipe altamente qualificada e especializada na auditoria de embarques de diversos produtos agrícolas. Nosso compromisso é assegurar que o produto embarcado corresponda precisamente às especificações documentais, atendendo integralmente às exigências legais e comerciais."
          }
        </p><br/>
        <p>
          {
            lang ?  
            "Through our Technical Analysis Department, Savassi Agronegócio is able to carry out specialized audits on different types of agricultural products, with a special focus on grains such as coffee, corn and soybeans." : 
            "Por meio do nosso Departamento de Análise Técnica, a Savassi Agronegócio está capacitada para realizar auditorias especializadas em diversos tipos de produtos agrícolas, com foco especial em grãos como café, milho e soja."
          }
        </p><br/>
        <p>
          {
            lang ?  
            "We invest in technical expertise to ensure the compliance and authenticity of shipments, providing peace of mind to our customers." : 
            "Investimos em expertise técnica para garantir a conformidade e autenticidade dos embarques, proporcionando tranquilidade aos nossos clientes."
          }
        </p><br/>
        <p>
          {
            lang ?  
            "We recognize that the original quality of a natural product can be compromised throughout the commercial process, influenced by factors such as:" : 
            "Reconhecemos que a qualidade original de um produto natural pode ser comprometida ao longo do processo comercial, influenciada por fatores como:"
          }
        </p><br/>
        <p>
          {
            lang ?  
            "Storage, storage conditions, transportation and even possible adulteration." : 
            "Armazenamento, condições de armazenagem, transporte e até mesmo possíveis adulterações."
          }
        </p><br/>
        <p>
          {
            lang ?  
            "We seek to act preventively to overcome these challenges, ensuring that products maintain their quality from the beginning until they reach their final destination." : 
            "Buscamos agir de forma preventiva para superar esses desafios, garantindo que os produtos mantenham sua qualidade desde o início até chegar ao destino final."
          }
        </p><br/><br/>
        <div className="capacitacaodetalhes">
          <button id="myBtn">{ lang ? "Request a Quote" : "Solicitar Orçamento" }</button><br/><br/>
        </div>

        <div id="myModal" className="modal">
          <div className="modal-content">
            <div className="modal-header">
              <span className="close">&times;</span>
              <h2>{ lang ? "Request a Quote" : "Solicite um orçamento" }</h2>
            </div>
            <div className="modal-body">
              <form onSubmit={sendEmail}>
                <input type="text" name="nome" onChange={(e) => setNome(e.target.value)} value={nome} placeholder={ lang ? "Enter your name" : "Informe seu nome" }></input> <br/>
                <input type="tel" name="telefone" onChange={(e) => maskTel(e.target.value)} value={telefone} placeholder={ lang ? "Enter your phone" : "Informe seu telefone" }></input> <br/>
                <input type="email" name="email" onChange={(e) => setEmail(e.target.value)} value={email} placeholder={ lang ? "Enter your e-mail" : "Informe seu e-mail" }></input> <br/>
                <textarea name="mensagem" rows="5" onChange={(e) => setMensagem(e.target.value)} value={mensagem} placeholder={ lang ? "Enter your message" : "Informe sua mensagem" }></textarea> <br/>
                <input id="modalBtn" type="submit" name="enviar" value={ lang ? "Send" : "Enviar" }></input> <br/>
              </form>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default AuditoriaEmbarques;