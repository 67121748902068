import { Link, BrowserRouter, Routes, Route } from 'react-router-dom';
import { useState, useEffect } from 'react';

import AnaliseTecnica from './pages/AnaliseTecnica';
import AuditoriaEmbarques from './pages/AnaliseTecnica/sub-pages/AuditoriaEmbarques';
import AuditoriaEstoqueQualidade from './pages/AnaliseTecnica/sub-pages/AuditoriaEstoqueQualidade';
import CoordQualidade from './pages/AnaliseTecnica/sub-pages/CoordQualidade';
import EmissaoLaudos from './pages/AnaliseTecnica/sub-pages/EmissaoLaudos';
import Capacitacao from './pages/Capacitacao';
import CalibracaoQGrader from './pages/Capacitacao/sub-pages/CalibracaoQGrader';
import CDC from './pages/Capacitacao/sub-pages/CDC';
import COM from './pages/Capacitacao/sub-pages/COM';
import CursoTorra from './pages/Capacitacao/sub-pages/CursoTorra';
import PMT from './pages/Capacitacao/sub-pages/PMT';
import QGrader from './pages/Capacitacao/sub-pages/QGrader';
import RecertificacaoQGrader from './pages/Capacitacao/sub-pages/RecertificacaoQGrader';
import RetakeCDC from './pages/Capacitacao/sub-pages/RetakeCDC';
import RetakePMT from './pages/Capacitacao/sub-pages/RetakePMT';
import RetakeQGrader from './pages/Capacitacao/sub-pages/RetakeQGrader';
import Certificadora from './pages/Certificadora';
import Certificado4C from './pages/Certificadora/sub-pages/Certificado4C';
import CAFEPractices from './pages/Certificadora/sub-pages/CAFEPractices';
import OrganicoBrasil from './pages/Certificadora/sub-pages/OrganicoBrasil';
import RainforestAlliance from './pages/Certificadora/sub-pages/RainforestAlliance';
import UnidadeArmazenadora from './pages/Certificadora/sub-pages/UnidadeArmazenadora';
import CertificadoGlobalGAP from './pages/Certificadora/sub-pages/GlobalGAP';
import Documentos from './pages/Documentos';
import CafeGelado from './pages/Documentos/sub-pages/CafeGelado';
import Documentos4C from './pages/Documentos/sub-pages/Documentos4C';
import RegulamentoClassificacaoCade from './pages/Documentos/sub-pages/RegulamentoClassificacaoCafe';
import ProtocoloDegustacaoSCA from './pages/Documentos/sub-pages/ProtocoloDegustacaoSCA';
import FormularioLaboratorio from './pages/Documentos/sub-pages/FormularioLaboratorio';
import DocumentosRainforestAlliance from './pages/Documentos/sub-pages/DocumentosRainforestAlliance';
import DocumentosCAFEPractices from './pages/Documentos/sub-pages/DocumentosCAFEPractices';
import DocumentosOrganicoBrasil from './pages/Documentos/sub-pages/DocumentosOrganicoBrasil';
import DocumentosUnidadeArmazenadora from './pages/Documentos/sub-pages/DocumentosUnidadeArmazenadora';
import Formularios from './pages/Documentos/sub-pages/Formularios';
import GlobalGAP from './pages/Documentos/sub-pages/GlobalGAP';
import Politicas from './pages/Documentos/sub-pages/Politicas';
import Procedimentos from './pages/Documentos/sub-pages/Procedimentos';
import FaleConosco from './pages/FaleConosco';
import Home from './pages/Home';
import Ouvidoria from './pages/Ouvidoria';
import QuemSomos from './pages/QuemSomos';

import Header from './Components/Header';
import Footer from './Components/Footer';
import ScrollToTop from './Components/utils/ScrollToTop';

function RoutesApp(){

  const [lang, setLang] = useState();

  useEffect(()  => {
		const checkLang = function() {
			if(localStorage.getItem('lang')) {
				setLang(JSON.parse(localStorage.getItem('lang')));
			} else {
				setLang(0);
			}
		}

		window.addEventListener('storage', checkLang)

		return () => {
			window.removeEventListener('storage', checkLang)
		}
  }, []);

  useEffect(()  => {	
    if(localStorage.getItem('lang')) {
			setLang(JSON.parse(localStorage.getItem('lang')));
		} else {
			setLang(0);
		}
    window.dispatchEvent(new Event("newRoute"));	
    window.dispatchEvent(new Event("tooltip"));		
  }, []);

  return(
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <ScrollToTop />
      <div className='main'>
        <Header/>
        <Routes>
          <Route path='/analise-tecnica' element={<AnaliseTecnica/>}/>
          <Route path='/analise-tecnica/auditoria-de-embarques-de-produtos' element={<AuditoriaEmbarques/>}/>
          <Route path='/analise-tecnica/auditoria-de-estoque-e-qualidade' element={<AuditoriaEstoqueQualidade/>}/>
          <Route path='/analise-tecnica/coordenacao-de-concursos-de-qualidade' element={<CoordQualidade/>}/>
          <Route path='/analise-tecnica/emissao-de-laudos-cob-e-scaa' element={<EmissaoLaudos/>}/>
          <Route path='/capacitacao' element={<Capacitacao/>}/>
          <Route path='/capacitacao/calibracao-q-grader' element={<CalibracaoQGrader/>}/>
          <Route path='/capacitacao/cdc' element={<CDC/>}/>
          <Route path='/capacitacao/com' element={<COM/>}/>
          <Route path='/capacitacao/curso-torra' element={<CursoTorra/>}/>
          <Route path='/capacitacao/pmt' element={<PMT/>}/>
          <Route path='/capacitacao/q-grader' element={<QGrader/>}/>
          <Route path='/capacitacao/recertificacao-q-grader' element={<RecertificacaoQGrader/>}/>
          <Route path='/capacitacao/retake-cdc' element={<RetakeCDC/>}/>
          <Route path='/capacitacao/retake-pmt' element={<RetakePMT/>}/>
          <Route path='/capacitacao/retake-q-grader' element={<RetakeQGrader/>}/>
          <Route path='/certificadora' element={<Certificadora/>}/>
          <Route path='/certificadora/4c' element={<Certificado4C/>}/>
          <Route path='/certificadora/cafe-practices' element={<CAFEPractices/>}/>
          <Route path='/certificadora/organico-brasil' element={<OrganicoBrasil/>}/>
          <Route path='/certificadora/rainforest-alliance' element={<RainforestAlliance/>}/>
          <Route path='/certificadora/unidade-armazenadora-ambiente-natural' element={<UnidadeArmazenadora/>}/>
          <Route path='/certificadora/global-gap' element={<CertificadoGlobalGAP/>}/>
          <Route path='/documentos' element={<Documentos/>}/>
          <Route path='/documentos/cafe-gelado' element={<CafeGelado/>}/>
          <Route path='/documentos/4c' element={<Documentos4C/>}/>
          <Route path='/documentos/regulamento-classificacao-cafe' element={<RegulamentoClassificacaoCade/>}/>
          <Route path='/documentos/protocolo-degustacao-sca' element={<ProtocoloDegustacaoSCA/>}/>
          <Route path='/documentos/formulario-laboratorio' element={<FormularioLaboratorio/>}/>
          <Route path='/documentos/rainforest-alliance' element={<DocumentosRainforestAlliance/>}/>
          <Route path='/documentos/cafe-practices' element={<DocumentosCAFEPractices/>}/>
          <Route path='/documentos/organico-brasil' element={<DocumentosOrganicoBrasil/>}/>
          <Route path='/documentos/unidade-armazenadora-ambiente-natural' element={<DocumentosUnidadeArmazenadora/>}/>
          <Route path='/documentos/formularios' element={<Formularios/>}/>
          <Route path='/documentos/global-gap' element={<GlobalGAP/>}/>
          <Route path='/documentos/politicas' element={<Politicas/>}/>
          <Route path='/documentos/procedimentos' element={<Procedimentos/>}/>
          <Route path='/fale-conosco' element={<FaleConosco/>}/>
          <Route path='/' element={<Home/>}/>
          <Route path='/ouvidoria' element={<Ouvidoria/>}/>
          <Route path='/quem-somos' element={<QuemSomos/>}/>
        </Routes>
        <div className="float" >
          <div id="tooltipWpp" className="tooltip">
            <span className="tooltiptext"> { lang ? "Need any help?" : "Precisa de ajuda?" } <br/> { lang ? "Talk to us on WhatsApp!" : "Fale conosco pelo WhatsApp!" }</span>
          </div>
          <Link to="https://linktr.ee/savassiagro" className="wpp" target="_blank">
            <i className="fa fa-brands fa-whatsapp my-float"></i>
          </Link>
        </div>
      </div>
      <Footer/>
    </BrowserRouter>
  )
}

export default RoutesApp;