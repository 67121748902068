import { useState, useEffect } from 'react';

function CoordQualidade(){

	const [lang, setLang] = useState();

	useEffect(()  => {
		const checkLang = function() {
			if(localStorage.getItem('lang')) {
				setLang(JSON.parse(localStorage.getItem('lang')));
			} else {
				setLang(0);
			}
		}

		window.addEventListener('storage', checkLang)

		return () => {
			window.removeEventListener('storage', checkLang)
		}
  }, []);

  useEffect(()  => {		
		if(localStorage.getItem('lang')) {
			setLang(JSON.parse(localStorage.getItem('lang')));
		} else {
			setLang(0);
		}
    window.dispatchEvent(new Event("openModal"));	
    window.dispatchEvent(new Event("newRoute"));			
  }, []);

  const [nome, setNome] = useState('');
  const [telefone, setTelefone] = useState('');
  const [email, setEmail] = useState('');
  const [assunto] = useState('Coordenação de Concursos e Eventos de Café de Qualidade');
  const [mensagem, setMensagem] = useState('');

  const maskTel = (v) => {
    v = v.replace(/\D/g, "")

    v = v.replace(/(\d{2})(\d)/,"($1) $2")
    v = v.replace(/(\d)(\d{4})$/,"$1-$2")

    setTelefone(v)
  }

  const sendEmail = async (e) => {
    e.preventDefault();

    if(nome === '' || telefone === '' || email === '' || assunto === '' || mensagem === ''){
      const alertMessage = lang ? "Please fill in all fields" : "Por favor preencha todos os campos";
      alert(alertMessage);
      return;
    }

    var destinatario = 'comercial4@savassicertificadora.com.br,comercial@savassicertificadora.com.br,fonteboag@savassicertificadora.com.br,dantasr@savassicertificadora.com.br';

    const templateParams = {
      origemMensagem: "Novo pedido de orçamento para Coordenação de Concursos e Eventos de Café de Qualidade",
      nome: nome,
      telefone: telefone,
      email: email,
      assunto: assunto,
      mensagem: mensagem,
      destinatario: destinatario
    }

    window.dispatchEvent(new Event("closeModal"));
    setNome('');
    setTelefone('');
    setEmail('');
    setMensagem('');

    let response = await fetch("/contato", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8"
      },
      body: JSON.stringify(templateParams)
    })

    let result = await response.json();

    if(result.status==="200 OK") {
      console.log("EMAIL ENVIADO", response.status, response.text);
      alert(lang ? 'Budget request sent successfully!' : 'Solicitação de orçamento enviada com sucesso!')
    } else {
      console.log("ERRO: ", result.status);
    }

  }

  return(
    <div style={{backgroundColor:'#F7FBF5'}}>
      <div className="analisesubpages">
        <img className="analisesubpagesbanner" src={ lang ? require("../../../../assets/images/coord-qualidade-banner-ingles.png") : require("../../../../assets/images/coord-qualidade-banner.png") } alt="banner"/><br/><br/>
        <p>
          {
            lang ?  
            "Savassi Análise Técnica plays a fundamental role in coordinating and advising on competitions and events aimed at determining special quality coffees. Our commitment lies in ensuring the highest standards of participating coffees, strictly aligning them with international evaluation standards." : 
            "A Savassi Análise Técnica desempenha um papel fundamental na coordenação e assessoria de concursos e eventos voltados para a determinação de cafés de qualidade especial. Nosso compromisso reside em assegurar a máxima exigência dos cafés participantes, alinhando-os rigorosamente às normas internacionais de avaliação."
          }
        </p><br/>
        <p>
          {
            lang ?  
            "Each competition we coordinate adopts a specific methodology, adapted to the region, but they all share the common objective of evaluating the global and sensorial quality of the coffees. Parameters such as fragrance, aroma, flavor, finish, acidity, body, balance and general characteristics are meticulously analyzed individually on a scale that varies from 6 to 10 points." : 
            "Cada concurso que coordenamos adota uma metodologia específica, adaptada à regionalidade, mas todos compartilham o objetivo comum de avaliar a qualidade global e sensorial dos cafés. Parâmetros como fragrância, aroma, sabor, finalização, acidez, corpo, balanço e características gerais são minuciosamente analisados individualmente em uma escala que varia de 6 a 10 pontos."
          }
        </p><br/>
        <p>
          {
            lang ?  
            "Various versions of specialty coffee quality contests, both in Brazil and abroad, are promoted by cooperatives, associations and companies. These initiatives aim to recognize and reward the dedicated work of coffee farmers committed to producing high-quality coffee. It is common for these entities to count on the support of specialized companies, such as Savassi Análise Técnica, to coordinate these competitions in an impartial manner." : 
            "Diversas versões de concursos de qualidade de cafés especiais, tanto no Brasil quanto no exterior, são promovidas por cooperativas, associações e empresas. Essas iniciativas visam reconhecer e premiar o trabalho dedicado de cafeicultores comprometidos com a produção de cafés de alta qualidade. É comum que essas entidades contem com o suporte de empresas especializadas, como a Savassi Análise Técnica, para coordenar esses concursos de forma imparcial."
          }
        </p><br/>
        <p>
          {
            lang ?  
            "It is important to highlight the distinction between the evaluation and classification of a traditional coffee and the evaluation carried out in quality competitions. While the classification of traditional coffees seeks to identify conventional characteristics, such as harvest point, roasting point and defects, quality coffee competitions focus on more refined attributes, such as fragrance, aroma, flavor, acidity, body, texture, balance in the cup and uniformity." : 
            "É importante destacar a distinção entre a avaliação e classificação de um café tradicional e a avaliação realizada em concursos de qualidade. Enquanto a classificação de cafés tradicionais busca identificar características convencionais, como ponto de colheita, ponto de torra e defeitos, os concursos de cafés de qualidade concentram-se em atributos mais refinados, tais como fragrância, aroma, sabor, acidez, corpo, textura, equilíbrio na xícara e uniformidade."
          }
        </p><br/>
        <p>
          {
            lang ?  
            "As a competition coordinator company, committed exclusively to providing services and devoid of commercial interests, we conclude that these quality coffee competitions not only benefit the participating producers, but also enrich coffee culture in general. One of the most significant contributions of these competitions is to promote continuous improvement in the quality of Brazilian specialty coffee." : 
            "Sendo uma empresa coordenadora de concursos, comprometida exclusivamente com a prestação de serviços e desprovida de interesses comerciais, concluímos que esses concursos de cafés de qualidade não apenas beneficiam os produtores participantes, mas também enriquecem a cultura cafeeira em geral. Uma das contribuições mais significativas desses concursos é promover a melhoria contínua da qualidade do café especial brasileiro."
          }
        </p><br/><br/>

        <div className="capacitacaodetalhes">
          <button id="myBtn">{ lang ? "Request a Quote" : "Solicitar Orçamento" }</button><br/><br/>
        </div>

        <div id="myModal" className="modal">
          <div className="modal-content">
            <div className="modal-header">
              <span className="close">&times;</span>
              <h2>{ lang ? "Request a Quote" : "Solicite um orçamento" }</h2>
            </div>
            <div className="modal-body">
              <form onSubmit={sendEmail}>
                <input type="text" name="nome" onChange={(e) => setNome(e.target.value)} value={nome} placeholder={ lang ? "Enter your name" : "Informe seu nome" }></input> <br/>
                <input type="tel" name="telefone" onChange={(e) => maskTel(e.target.value)} value={telefone} placeholder={ lang ? "Enter your phone" : "Informe seu telefone" }></input> <br/>
                <input type="email" name="email" onChange={(e) => setEmail(e.target.value)} value={email} placeholder={ lang ? "Enter your e-mail" : "Informe seu e-mail" }></input> <br/>
                <textarea name="mensagem" rows="5" onChange={(e) => setMensagem(e.target.value)} value={mensagem} placeholder={ lang ? "Enter your message" : "Informe sua mensagem" }></textarea> <br/>
                <input id="modalBtn" type="submit" name="enviar" value={ lang ? "Send" : "Enviar" }></input> <br/>
              </form>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default CoordQualidade;